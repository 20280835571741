import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { useRoute } from 'vue-router'
import SysTournamentRow, { SysTournamentMatchSchedule, SysTournamentMatchSettlement, SysTournamentMatch180, SysTournamentMatch, SysTournamentMatchSet, SysTournamentMatchSetId, SysTournamentMatchFinalApproval, tournamentMatchScheduleTeamStatusFriendlyType, SysTournamentMatchSettlementExtId } from '@/types/SysTournament'
import { Tournaments } from '@/services/TournamentsDataService'
import BaseFunctions, { defaultClubManagerGroupId, defaultClubCaptainGroupId, defaultSuperAdminGroupId } from '@/components/Utility/Base'
import SysMember from '@/types/SysMember'
import MembersDataService from '@/services/MembersDataService'
import CommonFunctions from '@/components/Utility/Common'
import SysMatchHistory from '@/types/SysMatchHistory'
import MatchHistoryDataService from '@/services/MatchHistoryDataService'
import TeamsDataService from '@/services/TeamsDataService'
import SysTeam from '@/types/SysTeam'
import { History } from '@/services/HistoryDataService'
import { LiveMatch } from '@/services/LiveMatchDataService'
import SysHistory from '@/types/SysHistory'
import Vue3Html2pdf from 'vue3-html2pdf'
import TournamentAdvancedMatchSetRoundsMode from '@/components/Tournaments/TournamentAdvancedMatchSetRoundsMode/index.vue'
import ClubChangeDataService from '@/services/ClubChangeDataService'
// import ably from '@/services/AblyService'
import LoadingButton from '../LoadingButton/index.vue'

type matchPlayerInfoDataType = { memberId: number; name: string; license: number; playerSelected: boolean; playerVerdict: number; playerVerdictDirtyFlag: boolean; matchSettlementExtId: number; matchesWon: number; matchSetsWon: number; matchSetsLost: number; match180: number; match180WriteOnlyMode: boolean; match180extendedMode: number[]; matchSetHighestClosing: number; matchSetFastestDarting: number; hide: boolean; setWonScore: number; singleMatchesPlayed: number }
type dataReturnType = { finishedSettingHomeTeam: boolean; finishedSettingAwayTeam: boolean; homeTeamArray: any[]; awayTeamArray: any[]; combinedTeamsArray: any[]; spectatorMode: boolean; ignoreErrorsInMatchSetsFlag: boolean; }
type stateobjType = { showModal: boolean; status: number; matchIndexNumber: number; matchSet: number[]; winningPlayer: boolean | null; winningPlayerDartValue: number; winningPlayerCloseValue: number; remainderPointsOfLoser: number; match180ScoreAmount: number[]; playerWhoStartedFirstRound: boolean; }

@Options({
  components: {
    Vue3Html2pdf,
    TournamentAdvancedMatchSetRoundsMode,
    LoadingButton
  }
})
export default class tournamentMatch extends Vue {
  $Message: any
  logTeamData = false
  private todaysDate = new Date().toISOString().split('T')[0]
  private matchId = this.parseURL()
  private match = {} as SysTournamentMatchSchedule
  private matchExecution = {} as SysTournamentMatchSettlement
  private dummyMatchExecution = {} as SysTournamentMatchSettlement
  private matchRules = { minPlayers: 0, maxPlayers: 0, sets: 0 }
  private homeTeamClubId = 0
  private awayTeamClubId = 0
  private homeAwayMembers: SysMember[] = []
  private selectTeamModal = false
  private settingAwayTeam = false
  private finishedSettingHomeTeam = false
  private finishedSettingAwayTeam = false
  private homeTeamOptions: { name: string, id: number }[] = []
  private awayTeamOptions: { name: string, id: number }[] = []
  private playerValue = { name: 'Vælg spiller', id: 0 }
  private homeTeamArray: { name: string, id: number}[] = []
  private awayTeamArray: { name: string, id: number}[] = []
  private combinedTeamsArray: { id: number, name: string, legal: boolean, homeTeam: boolean }[] = []
  private matchSettlementTeamPlayersExtDataUpdateIds: number[] = []
  private matchSettlementTeamPlayersExtDataUpdateProgress = 0
  private matchSettlementTeamPlayersExtDataUpdateStarted = false
  private matchSettlementTeamPlayersExtDataUpdateProgressWatermark = 0
  private oldBoyFlag = false
  private numberOfMatches = 16
  private numberOfSets = 3
  private matchOrder: { playerIndexHome: number; playerIndexAway: number; playerIndex2Home: number; playerIndex2Away: number; isDouble: boolean; resultRecorderHome: number | null; resultRecorderGuest: number | null }[] = []
  private setResults: { matchId: number; matchIndexNumber: number; matchSetDart: number; matchSetClosed: number; matchSetRemainScore: number; matchSet: number; matchHomeOrAwayTeam: boolean; matchPlayerWhoStartedFirstRound: boolean | null; matchSetDirtyFlag: number }[] = []
  // private dummyMatchOrder: { playerIndexHome: number; playerIndexAway: number; playerIndex2Home: number; playerIndex2Away: number; isDouble: boolean; resultRecorderHome: number | null; resultRecorderGuest: number | null }[] = []
  private emptySetResults: { matchId: number; matchIndexNumber: number; matchSetDart: number; matchSetClosed: number; matchSetRemainScore: number; matchSet: number; matchHomeOrAwayTeam: boolean; matchPlayerWhoStartedFirstRound: boolean | null; matchSetDirtyFlag: number }[] = []
  private homePlayersInfoData: matchPlayerInfoDataType[] = []
  private awayPlayersInfoData: matchPlayerInfoDataType[] = []
  private homePlayersEmptyInfoData: matchPlayerInfoDataType[] = []
  private awayPlayersEmptyInfoData: matchPlayerInfoDataType[] = []
  private matchesCreated = 0
  private setsCreated = 0
  private emptyMatchesCreated = 0
  private emptySetsCreated = 0
  private matchTableInitializationProcessFlag = false
  private updatedMatchSetsFlagsArr: boolean[] = []
  private tournamentMatchResultsCalcProgress = 0
  private updateTournamentMatchProcessFlag: boolean | null = null
  private totalResultHome = 0
  private cachedTotalResultHome = 0
  private totalResultGuest = 0
  private cachedTotalResultGuest = 0
  private spectatorMode = true
  // private spectatorMode2 = false
  private updateTimer = (this.spectatorMode ? setInterval(() => { this.retrieveMatch() }, 120000) : 0)
  private finishMatchModal = false
  private matchCardOutputMode = 0 // 0 = empty (default), 1 = with players and results
  private matchCardPrintoutValue = '0'
  private matchCardPrintoutOptions = [{ text: 'Ikke udfyldt', value: '0' }, { text: 'Udfyldt', value: '1' }]
  private matchCardPrintoutOptionsModal = false
  private protestValue = 'Ingen'
  private protestOptions = ['Ingen', 'Protest fra hjemmeholdet', 'Protest fra udeholdet', 'Afbud fra hjemmeholdet', 'Afbud fra udeholdet', 'Udeblivelse af hjemmeholdet', 'Udeblivelse af udeholdet']
  private protestComment = ''
  private matchFinishUsername = ''
  private matchFinishPassword = ''
  private matchPlayed = false
  private playersLegalityArray: { legalString: string, legalBol: boolean }[] = []
  private playerLegalityModal = false
  private playerLegalityHeadline = ''
  private allPlayersLegal = false
  private advancedMatchSetModeModal = false
  private advancedMatchSetRoundsModeData = { readOnlyFlag: false, startingPlayer: false, matchIndexNumber: 0, matchSet: [0, 0], matchId: 0, submatchWinAtNumberOfWonSets: 3, matchHomeTeamPlayer: '' as string | null, matchHomeTeamPlayer2: '' as string | null, matchGuestTeamPlayer: '' as string | null, matchGuestTeamPlayer2: '' as string | null, matchIsDouble: false, matchTeamNames: [] as string[], matchTeamPlayersNames: [] as string[], matchTeamSecondPlayersNames: [] as string[], selectedMatchId: null as number | null, homeTeamResult: 0, awayTeamResult: 0, tournamentCategoryId: 0 }
  private forceShowMatchCardTable = false
  private submatchFlipFlopFlag = true
  private matchSetCheckupMsg = ['']
  private ignoreErrorsInMatchSetsFlag = false
  private showUpdateRefreshProgressModal = false
  private countdownTimeoutValue = 1000
  private countdownValue = 100
  private matchInitRefreshDelayFlag = false
  private match180WriteOnlyModeTriggerFlag = false
  private match180WriteOnlyModeValueChangeToTrueWarningModal = false
  private matchTeamResetWarningModal = false
  private setupMatchSettlementTeamPlayersExtDataCompletede = true
  private checkboxMatchHomePlayerIllnessValue: { matchId: number; value: boolean }[] = []
  private checkboxMatchAwayPlayerIllnessValue: { matchId: number; value: boolean }[] = []
  private showChangesTeamMatchStatusModal = false
  private checkboxMatchPlayerIllnessValue: { matchListIndex: number; matchIndexNumber: number; isHomeTeam: boolean; value: boolean } = { matchListIndex: 0, matchIndexNumber: 0, isHomeTeam: true, value: false }
  private errorMessages = {
    1: 'er muligvis en ulovlig spiller, da sidste kamp var i en højere række',
    2: 'er muligvis ulovlig, da vedkommende er under 18, og klubben ikke deltager i juniorrækken',
    3: 'er muligvis ulovlig, da vedkommende er under klubskifte karantæne',
    4: 'er muligvis ulovlig, da det er en af de sidste otte kampe, og vedkommende har spillet mere end 14 kampe på højere rangerende hold',
    5: 'er muligvis ulovlig, da det er første kamprunde, og vedkommende har allerede spillet en kamp i denne turnering'
  }

  private teamCaptainSelectionModel = false
  private homeTeamCaptain = null
  private awayTeamCaptain = null
  private liveMatches = []
  private matchSetArrayIndexForUpdateSetResults = -1
  private loadingStatus = false
  private loadingStatusTestTjekLovligeSpillere = false
  private loadingStatusBegyndKampen = false
  private loadingStatusBegyndkamp = false

  $Notification: any
  data (): dataReturnType {
    return {
      finishedSettingAwayTeam: this.finishedSettingAwayTeam,
      finishedSettingHomeTeam: this.finishedSettingHomeTeam,
      homeTeamArray: this.homeTeamArray,
      awayTeamArray: this.awayTeamArray,
      combinedTeamsArray: this.combinedTeamsArray,
      spectatorMode: this.spectatorMode,
      ignoreErrorsInMatchSetsFlag: this.ignoreErrorsInMatchSetsFlag
      // protestOptions: ['Ingen', 'Protest fra hjemmeholdet', 'Protest fra udeholdet', 'Afbud fra hjemmeholdet', 'Afbud fra udeholdet', 'Udeblivelse af hjemmeholdet', 'Udeblivelse af udeholdet']
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange (newVal: any) : void {
    // console.log('Clearing Timer. Route = ' + JSON.stringify(newVal))

    if (newVal !== null && newVal.name !== undefined && newVal.name !== 'TournamentMatch') {
      // console.log('Clearing Timer. FIXED! ' + newVal.name)
      clearInterval(this.updateTimer)
    }
  }

  @Watch('match180WriteOnlyModeTriggerFlag')
  onMatch180WriteOnlyModeValueChange (writeOnlyModeValue: boolean, oldWriteOnlyModeValue: boolean) : void {
    if (writeOnlyModeValue && !oldWriteOnlyModeValue) {
      this.match180WriteOnlyModeValueChangeToTrueWarningModal = true
      this.match180WriteOnlyModeTriggerFlag = false
      console.log('[onMatch180WriteOnlyModeValueChange()] ' + this.match180WriteOnlyModeValueChangeToTrueWarningModal.toString())
    }
  }

  @Watch('spectatorMode')
  enableAutoUpdate (toggleValue: boolean) : void {
    if (toggleValue && !this.matchPlayed) {
      this.updateTimer = setInterval(() => { this.retrieveMatch() }, 120000)
      // console.log('SetInterval() 1')
    } else {
      clearInterval(this.updateTimer)
      // console.log('ClearInterval() 1')
    }
  }

  @Watch('matchPlayed')
  onMatchPlayedChange (toggleValue: boolean) : void {
    if (toggleValue) {
      clearInterval(this.updateTimer)
    }
  }

  @Watch('finishMatchModal')
  onfinishMatchModalValueChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.matchFinishPassword = ''
      this.matchFinishUsername = ''
      this.protestComment = ''
      this.protestValue = 'Ingen'
    }
  }

  @Watch('setsCreated')
  onSetsCreatedChange (cntValue: number, oldCntValue: number) : void {
    // console.log('[onSetsCreatedChange()] cntValue = ' + cntValue.toString())
    // console.log('[onSetsCreatedChange()] oldCntValue = ' + oldCntValue.toString())
    // console.log('[onSetsCreatedChange()] setsCreated = ' + this.setsCreated.toString())
    // console.log('[onSetsCreatedChange()] matchTableInitializationProcessFlag = ' + this.matchTableInitializationProcessFlag.toString())
    // console.log('[onSetsCreatedChange()] matchInitRefreshDelayFlag = ' + this.matchInitRefreshDelayFlag.toString())

    // if (!this.spectatorMode && this.setsCreated === (this.numberOfSets * this.numberOfMatches)) {
    if (!this.spectatorMode && cntValue > 0 && oldCntValue < cntValue && this.matchTableInitializationProcessFlag && this.setsCreated === (this.numberOfSets * this.numberOfMatches)) {
      this.matchTableInitializationProcessFlag = false

      // Check if a special delay and refresh  should be used.
      if (this.matchInitRefreshDelayFlag) {
        this.matchInitRefreshDelayFlag = false
        // this.setsCreated = 0
        this.countdownValue = 100
        this.playerLegalityModal = false
        this.showUpdateRefreshProgressModal = true
        setTimeout(() => { this.countdownFunc() }, this.countdownTimeoutValue)
      } else {
        this.updateMatchResults()
      }
    } else if (this.matchTableInitializationProcessFlag && this.setsCreated === (this.numberOfSets * this.numberOfMatches)) {
      this.updateMatchResults()
    }
  }

  @Watch('homePlayersInfoData', { deep: true })
  onHomePlayersInfoDataChange () {
    this.onUpdatedMatchSetsFlagsArrChanged(this.updateTournamentMatchProcessFlag as boolean, this.updateTournamentMatchProcessFlag as boolean)
    this.onProtestValueChanged(this.protestValue, this.protestValue)
  }

  public countdownFunc () : void {
    if (this.countdownValue <= 0) {
      this.showUpdateRefreshProgressModal = false
      console.log('[countdownFunc()] Updating match results.')
      // this.updateMatchResults()
      window.location.reload()
    } else {
      this.countdownValue -= 10
      setTimeout(() => { this.countdownFunc() }, this.countdownTimeoutValue)
    }
  }

  public reloadPage () : void {
    window.location.reload()
  }

  // @Watch('tournamentMatchResultsCalcProgress')
  // onTournamentMatchResultsCalcProgressChanged (progressValue: number, oldProgressValue: number) : void {
  //   // console.log('[onTournamentMatchResultsCalcProgressChanged] Tournament match results calculations progress changed to: ' + progressValue)

  //   if (!this.updateTournamentMatchProcessFlag && progressValue >= this.numberOfMatches) {
  //     console.log('[onTournamentMatchResultsCalcProgressChanged] Update total score cache.')
  //     this.cachedTotalResultHome = this.totalResultHome
  //     this.cachedTotalResultGuest = this.totalResultGuest
  //   }
  // }

  @Watch('updateTournamentMatchProcessFlag')
  onUpdatedMatchSetsFlagsArrChanged (flagValue: boolean, oldFlagValue: boolean) : void {
    // console.log('[onUpdatedMatchSetsFlagsArrChanged] Tournament match results calculations progress changed to: ' + flagValue)

    if (!flagValue) {
      const moddedTotalResultScoreValues = this.handleTotalScoreResultModificationDueToProtestNonAppearanceCancellation([this.totalResultGuest, this.totalResultHome])
      this.totalResultHome = moddedTotalResultScoreValues[1]
      this.totalResultGuest = moddedTotalResultScoreValues[0]

      console.log('[onUpdatedMatchSetsFlagsArrChanged] Update total score cache.')
      this.cachedTotalResultHome = this.totalResultHome
      this.cachedTotalResultGuest = this.totalResultGuest
    }
  }

  @Watch('protestValue')
  onProtestValueChanged (value: string, oldValue: string) : void {
    if (oldValue !== value) {
      if (value === 'Ingen') {
        if (this.spectatorMode) {
          this.cachedTotalResultHome = this.totalResultHome
          this.cachedTotalResultGuest = this.totalResultGuest
        } else {
          this.cachedTotalResultHome = this.totalResultHome
          this.cachedTotalResultGuest = this.totalResultGuest

          if (oldValue === 'Afbud fra hjemmeholdet' || oldValue === 'Afbud fra udeholdet' || oldValue === 'Udeblivelse af hjemmeholdet' || oldValue === 'Udeblivelse af udeholdet') {
            this.updateMatchResults()
          }
        }
      } else if (value === 'Protest fra hjemmeholdet' || value === 'Protest fra udeholdet') {
        if (oldValue !== 'Ingen') {
          this.updateMatchResults()
        }
      } else {
        const moddedTotalResultScoreValues = this.handleTotalScoreResultModificationDueToProtestNonAppearanceCancellation([this.totalResultGuest, this.totalResultHome])
        this.cachedTotalResultHome = moddedTotalResultScoreValues[1]
        this.cachedTotalResultGuest = moddedTotalResultScoreValues[0]

        if (oldValue === 'Protest fra hjemmeholdet' || oldValue === 'Protest fra udeholdet') {
          this.updateMatchResults()
        }
      }
    }
  }

  public setTeamCaptains () : void {
    this.loadingStatus = true
    let awayMember: any | null = null
    let homeMember: any | null = null
    this.homeAwayMembers.map((member) => {
      if (member?.id === (this as any).awayTeamCaptain?.id) {
        awayMember = member
      }
      if (member?.id === (this as any).homeTeamCaptain?.id) {
        homeMember = member
      }
    })

    const selectedCaptains = {
      awayTeamCaptain: awayMember ? Number(awayMember?.user_id?.id || awayMember?.id) : null,
      homeTeamCaptain: homeMember ? Number(homeMember?.user_id?.id || homeMember?.id) : null
    }
    // Set the home and away team captain.
    Tournaments.TournamentMatchSchedulesDataService.update(Number(this.match.id).toString(), selectedCaptains)
      .then((response) => {
        this.$Message.success({ text: 'Vælg kaptajn' })
        this.match = response?.data
      })
      .catch((err) => {
        console.log('CAPTAIN_SET_ERROR', err)
        this.$Message.danger({ text: 'Fejl: Vælg kaptajn' })
      })
      .finally(() => {
        this.loadingStatus = false
        this.teamCaptainSelectionModel = false
      })
  }

  public handleTotalScoreResultModificationDueToProtestNonAppearanceCancellation (scoreResultValues: number[]) : number[] {
    let retValues: number[] = [0, 0] // [awayteamscore, hometeamscore]
    let maxScoreValue = this.numberOfMatches

    if (this.match !== undefined && this.match !== null && this.match.raekke_id !== undefined && this.match.raekke_id.raekke_navn !== undefined && this.match.raekke_id.raekke_navn !== null && this.match.raekke_id.raekke_navn.toLowerCase().includes('junior')) {
      maxScoreValue = (this.match.raekke_id.raekke_antalsaet === null ? 1 : this.match.raekke_id.raekke_antalsaet) // Usually the value is 5 for Junior match rows.
    }

    if (retValues.length >= 2) {
      retValues = [scoreResultValues[0], scoreResultValues[1]]

      if (this.protestValue !== 'Ingen' && Number(this.numberOfMatches) > 0) {
        // console.log('[handleTotalScoreResultModificationDueToProtestNonAppearanceCancellation()] protestValue = ' + this.protestValue)

        if (this.protestValue === 'Afbud fra hjemmeholdet') {
          // tournamentMatchScheduleTeamStatusFriendlyType.Afbudhjem
          retValues[1] = 0
          retValues[0] = maxScoreValue
        } else if (this.protestValue === 'Afbud fra udeholdet') {
          // tournamentMatchScheduleTeamStatusFriendlyType.Afbudud
          retValues[1] = maxScoreValue
          retValues[0] = 0
        } else if (this.protestValue === 'Udeblivelse af hjemmeholdet') {
          // tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem
          retValues[1] = 0
          retValues[0] = maxScoreValue
        } else if (this.protestValue === 'Udeblivelse af udeholdet') {
          // tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud
          retValues[1] = maxScoreValue
          retValues[0] = 0
        } else if (this.protestValue === 'Protest fra hjemmeholdet') {
          // tournamentMatchScheduleTeamStatusFriendlyType.Protesthjem
          // if (this.tournamentMatchScheduleData.matchHomeTeamVerdict < 1 && this.tournamentMatchScheduleData.matchGuestTeamVerdict === 1) {
          //   retValues[1] = maxScoreValue
          //   retValues[0] = 0
          // } else if (this.tournamentMatchScheduleData.matchGuestTeamVerdict === 1) {
          //   retValues[1] = 0
          //   retValues[0] = 0
          // }
        } else if (this.protestValue === 'Protest fra udeholdet') {
          // tournamentMatchScheduleTeamStatusFriendlyType.Protestud
          // if (this.tournamentMatchScheduleData.matchGuestTeamVerdict < 1 && this.tournamentMatchScheduleData.matchHomeTeamVerdict === 1) {
          //   retValues[1] = 0
          //   retValues[0] = maxScoreValue
          // } else if (this.tournamentMatchScheduleData.matchHomeTeamVerdict === 1) {
          //   retValues[1] = 0
          //   retValues[0] = 0
          // }
        }
      }
    }

    return retValues
  }

  public clickShowChangesTeamMatchStatusModal (matchListIndex: number, indexNumber: number, isHomeTeam = true) : void {
    console.log('[clickShowChangesTeamMatchStatusModal()] indexNumber = ' + indexNumber.toString())

    if (!(this.spectatorMode || this.matchPlayed)) {
      this.checkboxMatchPlayerIllnessValue = { matchListIndex: matchListIndex, matchIndexNumber: indexNumber, isHomeTeam: isHomeTeam, value: (isHomeTeam ? this.checkboxMatchHomePlayerIllnessValue[Number(indexNumber)].value : this.checkboxMatchAwayPlayerIllnessValue[Number(indexNumber)].value) }
      this.showChangesTeamMatchStatusModal = true
    }
  }

  public changeTeamMatchStatus () : void {
    //
    if (this.checkboxMatchPlayerIllnessValue.isHomeTeam) {
      this.checkboxMatchHomePlayerIllnessValue[this.checkboxMatchPlayerIllnessValue.matchIndexNumber].value = this.checkboxMatchPlayerIllnessValue.value
      this.checkboxMatchHomePlayerIllnessClick(this.checkboxMatchPlayerIllnessValue.matchIndexNumber)
    } else {
      this.checkboxMatchAwayPlayerIllnessValue[this.checkboxMatchPlayerIllnessValue.matchIndexNumber].value = this.checkboxMatchPlayerIllnessValue.value
      this.checkboxMatchAwayPlayerIllnessClick(this.checkboxMatchPlayerIllnessValue.matchIndexNumber)
    }

    this.showChangesTeamMatchStatusModal = false
  }

  public async checkboxMatchHomePlayerIllnessClick (matchIndex: number) : Promise<void> {
    const matchId = this.checkboxMatchHomePlayerIllnessValue[Number(matchIndex)].matchId
    const homeTeamPlayerIllnessValue = this.checkboxMatchHomePlayerIllnessValue[Number(matchIndex)].value
    console.log('Home team illness checkbox of match ' + matchIndex.toString() + ' value changed to: ' + this.checkboxMatchHomePlayerIllnessValue[Number(matchIndex)].value)
    console.log('checkboxMatchHomePlayerIllnessValue = ' + JSON.stringify(this.checkboxMatchHomePlayerIllnessValue))

    if (homeTeamPlayerIllnessValue) {
      // Obtain the array indexes of match set informations for the relevant match.
      const indexesOfMatchSetsOfMatch: number[] = []
      let arrIndex1 = 0

      for (const matchSettlementPlayerMatchSetResult of this.setResults) {
        if (matchSettlementPlayerMatchSetResult.matchId === matchId) {
          indexesOfMatchSetsOfMatch.push(arrIndex1)
        }

        arrIndex1++
      }

      // Reset the relevant fields of the match sets found and set their dirty flags.
      for (let arrIndex2 = 0; arrIndex2 < indexesOfMatchSetsOfMatch.length; arrIndex2++) {
        if (this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchHomeOrAwayTeam) {
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetDart = 0
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetClosed = 0
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetRemainScore = 0
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetDirtyFlag = 4
        }
      }
    }

    // Update the value of the illness flag of the match for the home team player.
    const updateTournamentMatchData = {
      kamp_hj_spiller_er_syg: homeTeamPlayerIllnessValue
    }

    try {
      const tournamentMatchDataUpdateResponse = await Tournaments.TournamentMatchDataService.update(matchId.toString(), updateTournamentMatchData)
      this.updateMatchResults()
    } catch (err) {
      // this.error = err
      console.log(err)
    }
  }

  public async checkboxMatchAwayPlayerIllnessClick (matchIndex: number) : Promise<void> {
    const matchId = this.checkboxMatchHomePlayerIllnessValue[Number(matchIndex)].matchId
    const awayTeamPlayerIllnessValue = this.checkboxMatchAwayPlayerIllnessValue[Number(matchIndex)].value
    console.log('Away team illness checkbox of match ' + matchIndex.toString() + ' value changed to: ' + this.checkboxMatchAwayPlayerIllnessValue[Number(matchIndex)].value)
    console.log('checkboxMatchAwayPlayerIllnessValue = ' + JSON.stringify(this.checkboxMatchAwayPlayerIllnessValue))

    if (awayTeamPlayerIllnessValue) {
      // Obtain the array indexes of match set informations for the relevant match.
      const indexesOfMatchSetsOfMatch: number[] = []
      let arrIndex1 = 0

      for (const matchSettlementPlayerMatchSetResult of this.setResults) {
        if (matchSettlementPlayerMatchSetResult.matchId === matchId) {
          indexesOfMatchSetsOfMatch.push(arrIndex1)
        }

        arrIndex1++
      }

      // Reset the relevant fields of the match sets found and set their dirty flags.
      for (let arrIndex2 = 0; arrIndex2 < indexesOfMatchSetsOfMatch.length; arrIndex2++) {
        if (!this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchHomeOrAwayTeam) {
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetDart = 0
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetClosed = 0
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetRemainScore = 0
          this.setResults[indexesOfMatchSetsOfMatch[arrIndex2]].matchSetDirtyFlag = 4
        }
      }
    }

    // Update the value of the illness flag of the match for the home team player.
    const updateTournamentMatchData = {
      kamp_ud_spiller_er_syg: awayTeamPlayerIllnessValue
    }

    try {
      const tournamentMatchDataUpdateResponse = await Tournaments.TournamentMatchDataService.update(matchId.toString(), updateTournamentMatchData)
      this.updateMatchResults()
    } catch (err) {
      // this.error = err
      console.log(err)
    }
  }

  public advMatchSetRoundsModeStateChanged (stateobj: stateobjType) {
    console.log('[advMatchSetRoundStateChanged()] stateobj = ' + JSON.stringify(stateobj))

    if (stateobj.status === 3 && stateobj.winningPlayer !== null) {
      const indexOfMatchSetData = this.setResults.findIndex(x => (Number(x.matchIndexNumber) === Number(stateobj.matchIndexNumber) && Number(x.matchSet) === Number(stateobj.matchSet[(stateobj.winningPlayer ? 1 : 0)]) && x.matchHomeOrAwayTeam === stateobj.winningPlayer))
      let tempArrayIndex = -1

      if (indexOfMatchSetData >= 0) {
        this.setResults[indexOfMatchSetData].matchSetDart = stateobj.winningPlayerDartValue
        this.setResults[indexOfMatchSetData].matchSetClosed = stateobj.winningPlayerCloseValue
        this.setResults[indexOfMatchSetData].matchSetRemainScore = stateobj.remainderPointsOfLoser
        this.setResults[indexOfMatchSetData].matchPlayerWhoStartedFirstRound = stateobj.playerWhoStartedFirstRound
        console.log('[advMatchSetRoundsModeStateChanged] matchPlayerWhoStartedFirstRound = ' + this.setResults[indexOfMatchSetData].matchPlayerWhoStartedFirstRound)

        // Update the relevant match180 score data for all players.
        tempArrayIndex = (this.advancedMatchSetRoundsModeData.matchHomeTeamPlayer !== null ? Number(this.advancedMatchSetRoundsModeData.matchHomeTeamPlayer) - 1 : -1)

        if (tempArrayIndex >= 0) {
          // this.homePlayersInfoData[tempArrayIndex].match180 += stateobj.match180ScoreAmount[1]
          this.homePlayersInfoData[tempArrayIndex].match180extendedMode[Number(stateobj.matchIndexNumber)] += stateobj.match180ScoreAmount[1]
        }

        tempArrayIndex = (this.advancedMatchSetRoundsModeData.matchGuestTeamPlayer !== null ? this.advancedMatchSetRoundsModeData.matchGuestTeamPlayer.charCodeAt(0) - 65 : -1)

        if (tempArrayIndex >= 0) {
          // this.awayPlayersInfoData[tempArrayIndex].match180 += stateobj.match180ScoreAmount[0]
          this.awayPlayersInfoData[tempArrayIndex].match180extendedMode[Number(stateobj.matchIndexNumber)] += stateobj.match180ScoreAmount[0]
        }

        // Handle also match180 for the double situation.
        if (this.advancedMatchSetRoundsModeData.matchIsDouble) {
          tempArrayIndex = (this.advancedMatchSetRoundsModeData.matchHomeTeamPlayer2 !== null ? Number(this.advancedMatchSetRoundsModeData.matchHomeTeamPlayer2) - 1 : -1)

          if (tempArrayIndex >= 0) {
            // this.homePlayersInfoData[tempArrayIndex].match180 += stateobj.match180ScoreAmount[1]
            this.homePlayersInfoData[tempArrayIndex].match180extendedMode[Number(stateobj.matchIndexNumber)] += stateobj.match180ScoreAmount[1]
          }

          tempArrayIndex = (this.advancedMatchSetRoundsModeData.matchGuestTeamPlayer2 !== null ? this.advancedMatchSetRoundsModeData.matchGuestTeamPlayer2.charCodeAt(0) - 65 : -1)

          if (tempArrayIndex >= 0) {
            // this.awayPlayersInfoData[tempArrayIndex].match180 += stateobj.match180ScoreAmount[0]
            this.awayPlayersInfoData[tempArrayIndex].match180extendedMode[Number(stateobj.matchIndexNumber)] += stateobj.match180ScoreAmount[0]
          }
        }

        if (!this.spectatorMode && !this.matchPlayed) {
          // console.log('[advMatchSetRoundsModeStateChanged()] Updating results.')
          this.updateDirtyFieldSetResults(Number(stateobj.matchIndexNumber), Number(stateobj.matchSet[(stateobj.winningPlayer ? 1 : 0)]), stateobj.winningPlayer, true, stateobj.winningPlayerDartValue, stateobj.winningPlayerCloseValue, stateobj.remainderPointsOfLoser)
        }
      }
    }

    if (stateobj.showModal === false) {
      this.advancedMatchSetModeModal = false
    }
  }

  public bothTeamSet () : boolean {
    if ((this.matchRules.minPlayers > this.homeTeamArray.length || this.matchRules.minPlayers > this.awayTeamArray.length) && this.allPlayersLegal) {
      return false
    }

    return true
  }

  public havePermission () : boolean {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.role !== undefined && userDataObject.role !== null && (userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 3 || userDataObject.usrgroup.id === 4)) {
        return true
      }
    }
    return false
  }

  public sortRowLevel () : void {
    let tournamentRows: SysTournamentRow[] = []
    Tournaments.TournamentRowsDataService.getAll('', null, `turneringskategori_id.id=${this.match.raekke_id.turneringskategori_id.toString()}&saeson_id.id=${this.match.raekke_id.saeson_id.toString()}`)
      .then((response) => {
        tournamentRows = response.data
        console.log(tournamentRows)
        const rowsArray: any[] = []
        for (const item of tournamentRows) {
          if (item.parent === null) {
            rowsArray.push({ id: item.id, name: item.raekke_navn })
          } else {
            rowsArray.push({ id: item.id, name: item.raekke_navn, parent_id: item.parent.id })
          }
        }
        console.log(rowsArray)

        const recursiveArraySort = (list: any[], parent = { id: undefined, level: 0 }) => {
          let result: any[] = []
          const children = list.filter(item => item.parent_id === parent.id)
          children.forEach(child => {
            child.level = parent.level + 1
            result = [...result, child, ...recursiveArraySort(list, child)]
          })
          return result
        }

        const sortedRows = recursiveArraySort(rowsArray)
        console.log(sortedRows)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public startMatch () : void {
    this.numberOfSets = (this.match.raekke_id.raekke_antalsaet === null ? 1 : this.match.raekke_id.raekke_antalsaet)

    if (this.matchRules.minPlayers === 1) {
      this.numberOfMatches = 1
      this.matchOrder = [
        { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: 0 }
      ]
    } else if (this.matchRules.minPlayers === 2) {
      this.numberOfMatches = 5
      this.matchOrder = [
        { playerIndexHome: 1, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
        { playerIndexHome: 2, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
        { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 2, playerIndex2Away: 2, isDouble: true, resultRecorderHome: null, resultRecorderGuest: 0 },
        { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
        { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 }
      ]
    } else if (this.oldBoyFlag) {
      if (this.matchRules.minPlayers >= 3 && this.matchRules.maxPlayers <= 8) {
        console.log('Old Boys')
        this.numberOfMatches = 4 + 2 // Four singles matches plus 2 double matches
        this.matchOrder = [
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 4, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 5, playerIndexAway: 5, playerIndex2Home: 6, playerIndex2Away: 6, isDouble: true, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 7, playerIndexAway: 7, playerIndex2Home: 8, playerIndex2Away: 8, isDouble: true, resultRecorderHome: 0, resultRecorderGuest: null }
        ]
      }
    } else {
      if (this.matchRules.minPlayers === 3) {
        this.numberOfMatches = 9
        this.matchOrder = [
          { playerIndexHome: 2, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 3 },
          { playerIndexHome: 3, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 2, resultRecorderGuest: null },
          { playerIndexHome: 1, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 1 },
          { playerIndexHome: 2, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 1, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null }
        ]
      }
      if (this.matchRules.minPlayers === 4) {
        this.numberOfMatches = 16
        this.matchOrder = [
          { playerIndexHome: 1, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 3 },
          { playerIndexHome: 2, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 4, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 4, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 2, resultRecorderGuest: null },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 1 },
          { playerIndexHome: 1, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null },
          { playerIndexHome: 4, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 4 },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 4, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 3 },
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null },
          { playerIndexHome: 2, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 1 },
          { playerIndexHome: 3, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 1, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 2, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 4, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 4 },
          { playerIndexHome: 4, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 2, resultRecorderGuest: null }
        ]
      } else if (this.matchRules.minPlayers >= 5 && this.matchRules.maxPlayers <= 8) {
        this.numberOfMatches = 4 + 2 // Four single matches plus 2 double matches.
        this.matchOrder = [
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 4, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 5, playerIndexAway: 5, playerIndex2Home: 6, playerIndex2Away: 6, isDouble: true, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 7, playerIndexAway: 7, playerIndex2Home: 8, playerIndex2Away: 8, isDouble: true, resultRecorderHome: 0, resultRecorderGuest: null }
        ]
      }
    }
    // console.log(this.matchOrder)
    this.matchExecution.kampafvikling_ekstras_id = this.matchExecution.kampafvikling_ekstras_id.sort(function compare (a, b) { return a.kampafvikling_spiller_indeks - b.kampafvikling_spiller_indeks })
    let sanityCheckFlag = false
    this.homePlayersInfoData = []
    this.awayPlayersInfoData = []
    let matchExtIdsHaveNull = false
    let match180Param = ''
    let arrIndex1 = 0
    const matchDataExtIds: number[] = []

    for (const item of this.matchExecution.kampafvikling_ekstras_id) {
      if (item.id === null) {
        matchExtIdsHaveNull = true
      } else {
        match180Param += ((arrIndex1 < 1 ? 'medlem_id.id=' : '&medlem_id.id=') + item.kampafvikling_spiller_hj.toString() + '&medlem_id.id=' + item.kampafvikling_spiller_ud.toString())
        matchDataExtIds.push(Number(item.id))
        const homePlayerIndex = this.homePlayersInfoData.findIndex(el => Number(el.memberId) === Number(item.kampafvikling_spiller_hj))
        this.homePlayersInfoData.push({
          memberId: item.kampafvikling_spiller_hj,
          name: '-',
          license: 0,
          playerSelected: (item.kampafvikling_spiller_hj_valgt === null ? true : item.kampafvikling_spiller_hj_valgt),
          playerVerdict: (item.kampafvikling_dom_mod_hjspiller === null ? 0.5 : (item.kampafvikling_dom_mod_hjspiller ? 1 : 0)),
          playerVerdictDirtyFlag: false,
          matchSettlementExtId: Number(item.id),
          matchesWon: 0,
          matchSetsWon: 0,
          matchSetsLost: 0,
          match180: 0,
          match180WriteOnlyMode: false,
          match180extendedMode: Array(this.numberOfMatches).fill(0),
          matchSetHighestClosing: 0,
          matchSetFastestDarting: 0,
          hide: homePlayerIndex !== -1,
          setWonScore: 0,
          singleMatchesPlayed: 0
        })
        const awayPlayerIndex = this.awayPlayersInfoData.findIndex(el => Number(el.memberId) === Number(item.kampafvikling_spiller_ud))
        this.awayPlayersInfoData.push({
          memberId: item.kampafvikling_spiller_ud,
          name: '-',
          license: 0,
          playerSelected: (item.kampafvikling_spiller_ud_valgt === null ? true : item.kampafvikling_spiller_ud_valgt),
          playerVerdict: (item.kampafvikling_dom_mod_udspiller === null ? 0.5 : (item.kampafvikling_dom_mod_udspiller ? 1 : 0)),
          playerVerdictDirtyFlag: false,
          matchSettlementExtId: Number(item.id),
          matchesWon: 0,
          matchSetsWon: 0,
          matchSetsLost: 0,
          match180: 0,
          match180WriteOnlyMode: false,
          match180extendedMode: Array(this.numberOfMatches).fill(0),
          matchSetHighestClosing: 0,
          matchSetFastestDarting: 0,
          hide: awayPlayerIndex !== -1,
          setWonScore: 0,
          singleMatchesPlayed: 0
        })

        if (this.oldBoyFlag && this.matchRules.minPlayers === 3 && item.kampafvikling_spiller_indeks >= 7 && item.kampafvikling_spiller_hj_valgt === false && item.kampafvikling_spiller_ud_valgt === false && this.matchOrder[5].isDouble) {
          this.matchOrder[5].isDouble = false
          this.matchOrder[5].playerIndex2Home = 0
          this.matchOrder[5].playerIndex2Away = 0
        }
        arrIndex1++
      }
    }
    match180Param += '&kampprogram_id.id=' + Number(this.match.id).toString()
    console.log('Number of matches: ' + this.numberOfMatches)
    console.log('Number of sets: ' + this.numberOfSets)
    console.log(Math.pow(matchDataExtIds.length, 2))
    console.log(this.matchRules)
    // Sanity checks are done here
    if (this.matchRules.minPlayers === 1 && matchDataExtIds.length === 1) {
      sanityCheckFlag = true
    }
    if (this.matchRules.minPlayers === 2 && (matchDataExtIds.length * 2 + 1) === this.numberOfMatches) {
      sanityCheckFlag = true
    }
    if (this.matchRules.minPlayers === 3 && (Math.pow(matchDataExtIds.length, 2)) === this.numberOfMatches) {
      sanityCheckFlag = true
    }
    if (this.matchRules.minPlayers === 4 && (Math.pow(matchDataExtIds.length, 2)) === this.numberOfMatches) {
      sanityCheckFlag = true
    }
    if (this.oldBoyFlag && this.matchRules.minPlayers >= 5 && this.matchRules.minPlayers <= 8 && (matchDataExtIds.length) >= 5) {
      sanityCheckFlag = true
    }
    if (this.oldBoyFlag && this.matchRules.minPlayers >= 3 && this.matchRules.minPlayers <= 8 && (matchDataExtIds.length) >= 3) {
      sanityCheckFlag = true
    }

    // console.log(this.homePlayersInfoData)
    // console.log(this.awayPlayersInfoData)
    console.log(sanityCheckFlag)
    if (!matchExtIdsHaveNull && sanityCheckFlag) {
      Tournaments.TournamentMatch180DataService.getAll('', null, match180Param)
        .then((response) => {
          const tempMatch180 = response.data as SysTournamentMatch180[]
          // console.log('TempMatch180: ' + JSON.stringify(tempMatch180))
          // Add some additional members information to the team player information section of the match schedule.
          for (let arrIndex = 0, teamIndex = 0; arrIndex < Math.max(this.homePlayersInfoData.length, this.awayPlayersInfoData.length); arrIndex++) {
            teamIndex = (this.homePlayersInfoData.length > arrIndex) ? this.homeAwayMembers.findIndex(el => Number(el.id) === Number(this.homePlayersInfoData[arrIndex].memberId)) : -1
            if (teamIndex !== -1) {
              this.homePlayersInfoData[arrIndex].name = this.homeAwayMembers[teamIndex].user_id.firstname + ' ' + this.homeAwayMembers[teamIndex].user_id.lastname
              this.homePlayersInfoData[arrIndex].license = (this.homeAwayMembers[teamIndex].medlem_licens === null ? 0 : this.homeAwayMembers[teamIndex].medlem_licens)
            }
            teamIndex = (this.awayPlayersInfoData.length > arrIndex) ? this.homeAwayMembers.findIndex(el => Number(el.id) === Number(this.awayPlayersInfoData[arrIndex].memberId)) : -1
            if (teamIndex !== -1) {
              this.awayPlayersInfoData[arrIndex].name = this.homeAwayMembers[teamIndex].user_id.firstname + ' ' + this.homeAwayMembers[teamIndex].user_id.lastname
              this.awayPlayersInfoData[arrIndex].license = (this.homeAwayMembers[teamIndex].medlem_licens === null ? 0 : this.homeAwayMembers[teamIndex].medlem_licens)
            }
            teamIndex = (this.homePlayersInfoData.length > arrIndex) ? tempMatch180.findIndex(el => Number(el.medlem_id.id) === Number(this.homePlayersInfoData[arrIndex].memberId)) : -1
            if (teamIndex !== -1) {
              this.homePlayersInfoData[arrIndex].match180 = tempMatch180[teamIndex].kamp_180_antal
            } else {
              if (this.homePlayersInfoData.length > arrIndex) {
                this.homePlayersInfoData[arrIndex].match180 = 0
              }
            }
            teamIndex = (this.awayPlayersInfoData.length > arrIndex) ? tempMatch180.findIndex(el => Number(el.medlem_id.id) === Number(this.awayPlayersInfoData[arrIndex].memberId)) : -1
            if (teamIndex !== -1) {
              this.awayPlayersInfoData[arrIndex].match180 = tempMatch180[teamIndex].kamp_180_antal
            } else {
              if (this.awayPlayersInfoData.length > arrIndex) {
                this.awayPlayersInfoData[arrIndex].match180 = 0
              }
            }
          }
          // console.log('[startMatch] homePlayersInfoData = ' + JSON.stringify(this.homePlayersInfoData))
          // console.log('[startMatch] awayPlayersInfoData = ' + JSON.stringify(this.awayPlayersInfoData))

          // Initialize the match settlement player match set result array.
          this.setResults = []
          this.checkboxMatchHomePlayerIllnessValue = []
          this.checkboxMatchAwayPlayerIllnessValue = []
          console.log(this.numberOfMatches)

          for (let arrIndex1 = 0; arrIndex1 < this.numberOfMatches; arrIndex1++) {
            for (let arrIndex2 = 0; arrIndex2 < this.numberOfSets * 2; arrIndex2++) {
              this.setResults.push({
                matchId: 0,
                matchIndexNumber: (arrIndex1 + 1),
                matchSetDart: 0,
                matchSetClosed: 0,
                matchSetRemainScore: 0,
                matchSet: Math.floor(arrIndex2 / 2),
                matchHomeOrAwayTeam: ((arrIndex2 & 1) === 0),
                matchPlayerWhoStartedFirstRound: null,
                matchSetDirtyFlag: 0
              })
            }

            // Initialize also the player illness value array for each match.
            this.checkboxMatchHomePlayerIllnessValue[arrIndex1] = { matchId: 0, value: false }
            this.checkboxMatchAwayPlayerIllnessValue[arrIndex1] = { matchId: 0, value: false }
          }
          console.log(this.setResults)
          // Initialize match settlement related database tables:
          // Create the various match and match set items in the database if they do not exist already.
          this.matchesCreated = 0 // Reset progress value.
          this.setsCreated = 0 // Reset progress value.
          this.matchTableInitializationProcessFlag = true
          this.initializeMatchTables()
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  public generateDummyMatchExecution (numberOfDummyPlayersPerTeam: number) : void {
    // this.homePlayersEmptyInfoData = [{ memberId: 10, name: 'SP1', license: 0, playerSelected: true, playerVerdict: 0, playerVerdictDirtyFlag: false, matchSettlementExtId: 1, matchesWon: 0, matchSetsWon: 0, matchSetsLost: 0, match180: 0, match180WriteOnlyMode: false, match180extendedMode: [], matchSetHighestClosing: 0, matchSetFastestDarting: 0, hide: false, setWonScore: 0, singleMatchesPlayed: 0 }]

    this.dummyMatchExecution = {
      id: this.matchExecution.id,
      kampprogram_id: this.matchExecution.kampprogram_id,
      kampafvikling_resultat_hjem: this.matchExecution.kampafvikling_resultat_hjem,
      kampafvikling_resultat_ude: this.matchExecution.kampafvikling_resultat_ude,
      kampafvikling_dom_mod_hjhold: this.matchExecution.kampafvikling_dom_mod_hjhold,
      kampafvikling_dom_mod_udhold: this.matchExecution.kampafvikling_dom_mod_udhold,
      kampafvikling_ekstras_id: <SysTournamentMatchSettlementExtId[]> [],
      created_at: this.matchExecution.created_at,
      updated_at: this.matchExecution.updated_at
    }

    for (let arrIndex = 1; arrIndex <= numberOfDummyPlayersPerTeam; arrIndex++) {
      this.dummyMatchExecution.kampafvikling_ekstras_id.push({ id: arrIndex, kampafvikling_spiller_indeks: arrIndex, kampafvikling_spiller_hj: (20 + arrIndex), kampafvikling_spiller_ud: (40 + arrIndex), kampafvikling_spiller_hj_valgt: true, kampafvikling_spiller_ud_valgt: true, kampafvikling_dom_mod_hjspiller: false, kampafvikling_dom_mod_udspiller: false, created_at: '', updated_at: '' })
    }
  }

  public startEmptyMatch () : void {
    this.numberOfSets = (this.match.raekke_id.raekke_antalsaet === null ? 1 : this.match.raekke_id.raekke_antalsaet)
    this.emptyMatchesCreated = 0 // Reset progress value.
    this.emptySetsCreated = 0 // Reset progress value.
    let numberOfDummyPlayersPerTeam = 1

    if (this.matchRules.minPlayers === 1) {
      this.numberOfMatches = 1
      this.matchOrder = [
        { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: 0 }
      ]
    } else if (this.matchRules.minPlayers === 2) {
      numberOfDummyPlayersPerTeam = 2
      this.numberOfMatches = 5
      this.matchOrder = [
        { playerIndexHome: 1, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
        { playerIndexHome: 2, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
        { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 2, playerIndex2Away: 2, isDouble: true, resultRecorderHome: null, resultRecorderGuest: 0 },
        { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
        { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 }
      ]
    } else if (this.oldBoyFlag) {
      if (this.matchRules.minPlayers >= 3 && this.matchRules.maxPlayers <= 8) {
        console.log('[startEmptyMatch()] Old Boys')
        numberOfDummyPlayersPerTeam = 8
        this.numberOfMatches = 4 + 2 // Four singles matches plus 2 double matches
        this.matchOrder = [
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 4, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 5, playerIndexAway: 5, playerIndex2Home: 6, playerIndex2Away: 6, isDouble: true, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 7, playerIndexAway: 7, playerIndex2Home: 8, playerIndex2Away: 8, isDouble: true, resultRecorderHome: 0, resultRecorderGuest: null }
        ]
      }
    } else {
      if (this.matchRules.minPlayers === 3) {
        numberOfDummyPlayersPerTeam = 3
        this.numberOfMatches = 9
        this.matchOrder = [
          { playerIndexHome: 2, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 3 },
          { playerIndexHome: 3, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 2, resultRecorderGuest: null },
          { playerIndexHome: 1, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 1 },
          { playerIndexHome: 2, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 1, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null }
        ]
      }
      if (this.matchRules.minPlayers === 4) {
        numberOfDummyPlayersPerTeam = 4
        this.numberOfMatches = 16
        this.matchOrder = [
          { playerIndexHome: 1, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 3 },
          { playerIndexHome: 2, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 4, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 4, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 2, resultRecorderGuest: null },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 1 },
          { playerIndexHome: 1, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null },
          { playerIndexHome: 4, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 4 },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 4, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 3 },
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 3, resultRecorderGuest: null },
          { playerIndexHome: 2, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 1 },
          { playerIndexHome: 3, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 1, resultRecorderGuest: null },
          { playerIndexHome: 1, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 2 },
          { playerIndexHome: 2, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 4, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 4 },
          { playerIndexHome: 4, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 2, resultRecorderGuest: null }
        ]
      } else if (this.matchRules.minPlayers >= 5 && this.matchRules.maxPlayers <= 8) {
        numberOfDummyPlayersPerTeam = 8
        this.numberOfMatches = 4 + 2 // Four single matches plus 2 double matches.
        this.matchOrder = [
          { playerIndexHome: 1, playerIndexAway: 1, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 2, playerIndexAway: 2, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 3, playerIndexAway: 3, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 4, playerIndexAway: 4, playerIndex2Home: 0, playerIndex2Away: 0, isDouble: false, resultRecorderHome: 0, resultRecorderGuest: null },
          { playerIndexHome: 5, playerIndexAway: 5, playerIndex2Home: 6, playerIndex2Away: 6, isDouble: true, resultRecorderHome: null, resultRecorderGuest: 0 },
          { playerIndexHome: 7, playerIndexAway: 7, playerIndex2Home: 8, playerIndex2Away: 8, isDouble: true, resultRecorderHome: 0, resultRecorderGuest: null }
        ]
      }
    }
    // console.log(this.matchOrder)

    this.generateDummyMatchExecution(numberOfDummyPlayersPerTeam)
    // console.log('[startEmptyMatch()] this.dummyMatchExecution = ' + JSON.stringify(this.dummyMatchExecution))

    this.dummyMatchExecution.kampafvikling_ekstras_id = this.dummyMatchExecution.kampafvikling_ekstras_id.sort(function compare (a, b) { return a.kampafvikling_spiller_indeks - b.kampafvikling_spiller_indeks })
    let sanityCheckFlag = false
    this.homePlayersEmptyInfoData = []
    this.awayPlayersEmptyInfoData = []
    let matchExtIdsHaveNull = false
    let arrIndex1 = 0
    const matchDataExtIds: number[] = []

    for (const item of this.dummyMatchExecution.kampafvikling_ekstras_id) {
      if (item.id === null) {
        matchExtIdsHaveNull = true
      } else {
        matchDataExtIds.push(Number(item.id))
        const homePlayerIndex = this.homePlayersEmptyInfoData.findIndex(el => Number(el.memberId) === Number(item.kampafvikling_spiller_hj))
        this.homePlayersEmptyInfoData.push({
          memberId: item.kampafvikling_spiller_hj,
          name: '-',
          license: 0,
          playerSelected: (item.kampafvikling_spiller_hj_valgt === null ? true : item.kampafvikling_spiller_hj_valgt),
          playerVerdict: 0.5,
          playerVerdictDirtyFlag: false,
          matchSettlementExtId: Number(item.id),
          matchesWon: 0,
          matchSetsWon: 0,
          matchSetsLost: 0,
          match180: 0,
          match180WriteOnlyMode: false,
          match180extendedMode: Array(this.numberOfMatches).fill(0),
          matchSetHighestClosing: 0,
          matchSetFastestDarting: 0,
          hide: homePlayerIndex !== -1,
          setWonScore: 0,
          singleMatchesPlayed: 0
        })
        const awayPlayerIndex = this.awayPlayersEmptyInfoData.findIndex(el => Number(el.memberId) === Number(item.kampafvikling_spiller_ud))
        this.awayPlayersEmptyInfoData.push({
          memberId: item.kampafvikling_spiller_ud,
          name: '-',
          license: 0,
          playerSelected: (item.kampafvikling_spiller_ud_valgt === null ? true : item.kampafvikling_spiller_ud_valgt),
          playerVerdict: 0.5,
          playerVerdictDirtyFlag: false,
          matchSettlementExtId: Number(item.id),
          matchesWon: 0,
          matchSetsWon: 0,
          matchSetsLost: 0,
          match180: 0,
          match180WriteOnlyMode: false,
          match180extendedMode: Array(this.numberOfMatches).fill(0),
          matchSetHighestClosing: 0,
          matchSetFastestDarting: 0,
          hide: awayPlayerIndex !== -1,
          setWonScore: 0,
          singleMatchesPlayed: 0
        })

        if (this.oldBoyFlag && this.matchRules.minPlayers === 3 && item.kampafvikling_spiller_indeks >= 7 && item.kampafvikling_spiller_hj_valgt === false && item.kampafvikling_spiller_ud_valgt === false && this.matchOrder[5].isDouble) {
          this.matchOrder[5].isDouble = false
          this.matchOrder[5].playerIndex2Home = 0
          this.matchOrder[5].playerIndex2Away = 0
        }

        arrIndex1++
      }
    }
    // console.log('[startEmptyMatch()] Number of matches: ' + this.numberOfMatches)
    // console.log('[startEmptyMatch()] Number of sets: ' + this.numberOfSets)
    // console.log(Math.pow(matchDataExtIds.length, 2))
    // console.log('[startEmptyMatch()]' + JSON.stringify(this.matchRules))

    // Sanity checks are done here
    if (this.matchRules.minPlayers === 1 && matchDataExtIds.length === 1) {
      sanityCheckFlag = true
    }
    if (this.matchRules.minPlayers === 2 && (matchDataExtIds.length * 2 + 1) === this.numberOfMatches) {
      sanityCheckFlag = true
    }
    if (this.matchRules.minPlayers === 3 && (Math.pow(matchDataExtIds.length, 2)) === this.numberOfMatches) {
      sanityCheckFlag = true
    }
    if (this.matchRules.minPlayers === 4 && (Math.pow(matchDataExtIds.length, 2)) === this.numberOfMatches) {
      sanityCheckFlag = true
    }
    if (this.oldBoyFlag && this.matchRules.minPlayers >= 5 && this.matchRules.minPlayers <= 8 && (matchDataExtIds.length) >= 5) {
      sanityCheckFlag = true
    }
    if (this.oldBoyFlag && this.matchRules.minPlayers >= 3 && this.matchRules.minPlayers <= 8 && (matchDataExtIds.length) >= 3) {
      sanityCheckFlag = true
    }

    // console.log(this.homePlayersEmptyInfoData)
    // console.log(this.awayPlayersEmptyInfoData)
    // console.log(sanityCheckFlag)

    if (!matchExtIdsHaveNull && sanityCheckFlag) {
      // Add some additional members information to the team player information section of the match schedule.
      for (let arrIndex = 0; arrIndex < Math.max(this.homePlayersEmptyInfoData.length, this.awayPlayersEmptyInfoData.length); arrIndex++) {
        this.homePlayersEmptyInfoData[arrIndex].name = ' '
        this.homePlayersEmptyInfoData[arrIndex].license = 0
        this.awayPlayersEmptyInfoData[arrIndex].name = ' '
        this.awayPlayersEmptyInfoData[arrIndex].license = 0
        this.homePlayersEmptyInfoData[arrIndex].match180 = 0
        this.awayPlayersEmptyInfoData[arrIndex].match180 = 0
      }
      // console.log('[startEmptyMatch()] homePlayersEmptyInfoData = ' + JSON.stringify(this.homePlayersEmptyInfoData))
      // console.log('[startEmptyMatch()] awayPlayersEmptyInfoData = ' + JSON.stringify(this.awayPlayersEmptyInfoData))

      // Initialize the match settlement player match set result array.
      this.emptySetResults = []
      // console.log('[startEmptyMatch()] numberOfMatches = ' + this.numberOfMatches)

      for (let arrIndex1 = 0; arrIndex1 < this.numberOfMatches; arrIndex1++) {
        for (let arrIndex2 = 0; arrIndex2 < this.numberOfSets * 2; arrIndex2++) {
          this.emptySetResults.push({
            matchId: 0,
            matchIndexNumber: (arrIndex1 + 1),
            matchSetDart: 0,
            matchSetClosed: 0,
            matchSetRemainScore: 0,
            matchSet: Math.floor(arrIndex2 / 2),
            matchHomeOrAwayTeam: ((arrIndex2 & 1) === 0),
            matchPlayerWhoStartedFirstRound: null,
            matchSetDirtyFlag: 0
          })
        }
      }

      // console.log(this.emptySetResults)
      // Initialize match settlement related database tables:
      // Create the various match and match set items in the database if they do not exist already.
      this.emptyMatchesCreated = this.numberOfMatches // Change progress value to final value.
      this.emptySetsCreated = this.numberOfSets * this.numberOfMatches // Change progress value to final value.
    }
  }

  public getSetResultsData (indexNumber: number) : { matchId: number; matchIndexNumber: number; matchSetDart: number; matchSetClosed: number; matchSet: number; matchHomeOrAwayTeam: boolean; matchSetRemainScore: number; matchPlayerWhoStartedFirstRound: boolean | null; matchSetDirtyFlag: number }[] {
    const retVal: { matchId: number; matchIndexNumber: number; matchSetDart: number; matchSetClosed: number; matchSet: number; matchHomeOrAwayTeam: boolean; matchSetRemainScore: number; matchPlayerWhoStartedFirstRound: boolean | null; matchSetDirtyFlag: number }[] = []

    for (const item of this.setResults) {
      if (item.matchIndexNumber === indexNumber) {
        retVal.push(item)
      }
    }
    return retVal
  }

  public checkTeamsData () : void {
    console.log(this.homePlayersInfoData)
    console.log(this.awayPlayersInfoData)
  }

  public updateMatchResults (onlyDirtyRowData = true) : boolean {
    this.totalResultHome = 0
    this.totalResultGuest = 0
    this.tournamentMatchResultsCalcProgress = 0
    // console.log(this.homePlayersInfoData)
    // console.log(this.awayPlayersInfoData)
    // console.log(this.matchOrder)

    // Do not process any updates if the finish match modal i open.
    if (this.finishMatchModal) {
      return true
    }

    Tournaments.TournamentMatchDataService.getAll('kamp_nummer:asc', null, `kampafvikling_id.id=${this.matchExecution.id}`)
      .then((response) => {
        let tempTournamentMatchData: SysTournamentMatch[] = []
        tempTournamentMatchData = response.data

        // console.log('[updateTournamentSettlementMatchSetTables] Tournament matches data: ' + JSON.stringify(response.data))

        // Reset certain statistical match data for each player of each team registered to the match settlement being processed.
        this.homePlayersInfoData.forEach(function (item) { item.matchesWon = 0; item.matchSetsWon = 0; item.matchSetsLost = 0; item.matchSetHighestClosing = 0; item.matchSetFastestDarting = 0; item.setWonScore = 0; item.singleMatchesPlayed = 0 })
        this.awayPlayersInfoData.forEach(function (item) { item.matchesWon = 0; item.matchSetsWon = 0; item.matchSetsLost = 0; item.matchSetHighestClosing = 0; item.matchSetFastestDarting = 0; item.setWonScore = 0; item.singleMatchesPlayed = 0 })
        // this.updatedMatchSetsFlagsArr = new Array(this.numberOfSets * 2).fill(false)
        this.updatedMatchSetsFlagsArr = []
        this.updateTournamentMatchProcessFlag = true

        for (let arrIndex1 = 0; arrIndex1 < this.numberOfMatches;) {
          const tempArrIndex1 = arrIndex1
          const matchNumberIndex = tempTournamentMatchData.findIndex(el => el.kamp_nummer === (arrIndex1 + 1))

          if (matchNumberIndex !== -1) {
            console.log('[updateTournamentSettlementMatchSetTables] Match data item exists.')

            // Handle adjustments of the total score result for situations, where a team loses a match, because the number of players in that team is less than the number of players of the opposing team.
            const indexHomePlayer = this.matchExecution.kampafvikling_ekstras_id.findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[tempArrIndex1].playerIndexHome)
            const indexAwayPlayer = this.matchExecution.kampafvikling_ekstras_id.findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[tempArrIndex1].playerIndexAway)
            const indexHomePlayer2 = (this.matchOrder[tempArrIndex1].isDouble ? this.matchExecution.kampafvikling_ekstras_id.findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[tempArrIndex1].playerIndex2Home) : -1)
            const indexAwayPlayer2 = (this.matchOrder[tempArrIndex1].isDouble ? this.matchExecution.kampafvikling_ekstras_id.findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[tempArrIndex1].playerIndex2Away) : -1)

            if (indexHomePlayer !== -1 && indexAwayPlayer !== -1) {
              this.homePlayersInfoData[indexHomePlayer].singleMatchesPlayed += indexHomePlayer2 === -1 ? 1 : 0
              this.awayPlayersInfoData[indexAwayPlayer].singleMatchesPlayed += indexAwayPlayer2 === -1 ? 1 : 0
              if (((
                this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null ||
                this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) &&
                this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === false) ||
                (this.matchOrder[tempArrIndex1].isDouble && indexAwayPlayer2 !== -1 && indexAwayPlayer2 !== -1 &&
                  (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === null ||
                    this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt) &&
                    this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_hj_valgt === false
                )) {
                console.log('[updateTournamentSettlementMatchSetTables] Adjust total results because of skipped match. (Home team wins that match.) [' + (tempArrIndex1 + 1).toString() + ']')

                this.totalResultHome++
              }
              if (((
                this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null ||
                this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt) &&
                this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === false) ||
                (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer2 !== -1 && indexAwayPlayer2 !== -1 &&
                  (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === null ||
                    this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt) &&
                    this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === false
                )) {
                console.log('[updateTournamentSettlementMatchSetTables] Adjust total results because of skipped match. (Guest team wins that match.)' + (tempArrIndex1 + 1).toString() + ']')

                this.totalResultGuest++
              }
            }

            // Tournaments.TournamentMatchSetDataService.getAll('kamp_saet_saet:asc', null, `kamp_id.id=${tempTournamentMatchData[matchNumberIndex].id}`)
            // Tournaments.TournamentMatchSetDataService.getAll('kamp_saet_saet:asc', null, 'kamp_id.id=' + Number(tempTournamentMatchData[matchNumberIndex].id).toString())
            //   .then((response) => {
            let tempSetsData: SysTournamentMatchSetId[] = []
            tempSetsData = tempTournamentMatchData[matchNumberIndex]?.kamp_saets_id || []
            let countMatchSetResultHome = 0
            let countMatchSetResultGuest = 0
            let tmpCountMatchSetResultHome = 0
            // let tmpCountMatchSetResultGuest = 0
            let indexOfHomeTeamPlayerMemberData = -1
            let indexOfHomeTeamPlayer2MemberData = -1
            let indexOfGuestTeamPlayerMemberData = -1
            let indexOfGuestTeamPlayer2MemberData = -1
            // let indexOfTeamPlayerMemberData = -1 // Should be removed
            // let indexOfTeamPlayer2MemberData = -1 // Should be removed
            let matchesWonAdjustmentsExecuteOnlyOncePerMatchFlag = true

            for (let arrIndex2 = 0; arrIndex2 < this.numberOfSets * 2;) {
              const matchSetArrayIndex = tempArrIndex1 * this.numberOfSets * 2 + arrIndex2

              console.log('[updateTournamentSettlementMatchSetTables] Checking for match Id : ' + this.setResults[matchSetArrayIndex].matchId)
              const matchSetIndex2 = tempSetsData.findIndex(el => (el.kamp_saet_saet === this.setResults[matchSetArrayIndex].matchSet && el.kamp_saet_hjem_ud === this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam))

              console.log('[updateTournamentSettlementMatchSetTables] matchSetIndex2 = ' + matchSetIndex2)
              // console.log('[updateTournamentSettlementMatchSetTables] matchOrder[].playerIndexAway = ' + this.matchOrder[tempArrIndex1].playerIndexAway)
              indexOfHomeTeamPlayerMemberData = this.homePlayersInfoData.findIndex(x => Number(x.memberId) === Number(this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].memberId))
              indexOfHomeTeamPlayer2MemberData = (this.matchOrder[tempArrIndex1].isDouble ? this.homePlayersInfoData.findIndex(x => Number(x.memberId) === Number(this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Home - 1)].memberId)) : -1)
              indexOfGuestTeamPlayerMemberData = this.awayPlayersInfoData.findIndex(x => Number(x.memberId) === Number(this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].memberId))
              indexOfGuestTeamPlayer2MemberData = (this.matchOrder[tempArrIndex1].isDouble ? this.awayPlayersInfoData.findIndex(x => Number(x.memberId) === Number(this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Away - 1)].memberId)) : -1)

              // Perform some statistics calculations.
              if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam) {
                // indexOfTeamPlayerMemberData = this.homePlayersInfoData.findIndex(el => Number(el.memberId) === Number(this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].memberId))
                // indexOfTeamPlayer2MemberData = (this.matchOrder[tempArrIndex1].isDouble ? this.homePlayersInfoData.findIndex(el => Number(el.memberId) === Number(this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Home - 1)].memberId)) : -1)
                if (arrIndex2 !== null) {
                  // if (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1) && (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict === 1) || (this.setResults[matchSetArrayIndex].matchSetDart > 8 && this.setResults[matchSetArrayIndex].matchSetClosed > 1))) {
                  if (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1) && (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict === 1) || (this.setResults[matchSetArrayIndex].matchSetDart > 8 && this.setResults[matchSetArrayIndex].matchSetClosed > 1)) && !tempTournamentMatchData[matchNumberIndex].kamp_hj_spiller_er_syg) {
                    // Handle statistic calculation for the number of match set won by player(s).
                    this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetsWon += 1
                    this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].setWonScore += this.setResults[matchSetArrayIndex].matchSetDart
                    if (this.matchOrder[tempArrIndex1].isDouble) {
                      // Handle also double match situations.
                      this.homePlayersInfoData[indexHomePlayer2].matchSetsWon += 1
                    }
                  }
                  // Check if there is set a match verdict against the player(s) from the home team or not. Also handle the case of player(s) from home team are sick.
                  // if (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1)) {
                  if (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1) || tempTournamentMatchData[matchNumberIndex].kamp_hj_spiller_er_syg) {
                    // There exists a verdict against at least one player.
                    // Handle statistic calculation for the number of match set lost by player(s).
                    if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt)) {
                      this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].playerSelected ? 1 : 0)
                    } else if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === false && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt)) {
                      // Handle also situations, where a player is not selected for the match.
                      this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].playerSelected ? 1 : 0)
                    } else if (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && indexHomePlayer2 !== -1 && indexAwayPlayer2 !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2] === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2]) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt)) {
                      // Handle also double match situations.
                      this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].playerSelected ? 1 : 0)
                      this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Home - 1)].playerSelected ? 1 : 0)
                    }
                  } else {
                    if (this.setResults[matchSetArrayIndex + 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex + 1].matchSetClosed > 1) {
                    // if ((this.setResults[matchSetArrayIndex].matchSetDart === 0 && this.setResults[matchSetArrayIndex].matchSetClosed === 0) || (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 0 && this.setResults[matchSetArrayIndex].matchSetDart < this.setResults[matchSetArrayIndex + 1].matchSetDart)) {
                      // Handle statistic calculation for the number of match set lost by player(s).
                      if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt)) {
                        this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].playerSelected && (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1)) && (this.setResults[matchSetArrayIndex + 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex + 1].matchSetClosed > 1) ? 1 : 0)
                      } else if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === false && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt)) {
                        // Handle also situations, where a player is not selected for the match.
                        this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].playerSelected && (this.awayPlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1)) ? 1 : 0)
                      } else if (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && indexHomePlayer2 !== -1 && indexAwayPlayer2 !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt)) {
                        // Handle also double match situations.
                        this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].playerSelected && (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1)) && (this.setResults[matchSetArrayIndex + 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex + 1].matchSetClosed > 1) ? 1 : 0)
                        this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetsLost += (this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Home - 1)].playerSelected && (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1)) && (this.setResults[matchSetArrayIndex + 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex + 1].matchSetClosed > 1) ? 1 : 0)
                      }
                    }
                    if (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 0) {
                      // Handle statistic calculation for the highest closing value in the match by player(s).
                      if (this.setResults[matchSetArrayIndex].matchSetClosed > this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetHighestClosing) {
                        this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetHighestClosing = this.setResults[matchSetArrayIndex].matchSetClosed
                      }
                      if (this.matchOrder[tempArrIndex1].isDouble && this.setResults[matchSetArrayIndex].matchSetClosed > this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetHighestClosing) {
                        // Handle also double match situations.
                        this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetHighestClosing = this.setResults[matchSetArrayIndex].matchSetClosed
                      }
                    }
                    if (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 0) {
                      // Handle statistic calculation for the fastest darting value in the match by player(s).
                      if (this.setResults[matchSetArrayIndex].matchSetDart < this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetFastestDarting || this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetFastestDarting <= 0) {
                        this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchSetFastestDarting = this.setResults[matchSetArrayIndex].matchSetDart
                      }
                      if (this.matchOrder[tempArrIndex1].isDouble && (this.setResults[matchSetArrayIndex].matchSetDart < this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetFastestDarting || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetFastestDarting <= 0)) {
                        // Handle also double match situations.
                        this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchSetFastestDarting = this.setResults[matchSetArrayIndex].matchSetDart
                      }
                    }
                  }
                }
              } else {
                if (arrIndex2 !== null) {
                  // if (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1) && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1) || (this.setResults[matchSetArrayIndex].matchSetDart > 8 && this.setResults[matchSetArrayIndex].matchSetClosed > 1))) {
                  if (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1) && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1) || (this.setResults[matchSetArrayIndex].matchSetDart > 8 && this.setResults[matchSetArrayIndex].matchSetClosed > 1)) && !tempTournamentMatchData[matchNumberIndex].kamp_ud_spiller_er_syg) {
                    // Handle statistic calculation for the number of match set won by player(s).
                    this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetsWon += 1
                    this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].setWonScore += this.setResults[matchSetArrayIndex].matchSetDart
                    if (this.matchOrder[tempArrIndex1].isDouble) {
                      // Handle also double match situations.
                      this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetsWon += 1
                    }
                  }
                  // Check if there is set a match verdict against the player(s) from the guest team or not. Also handle the case of player(s) from guest team are sick.
                  // if (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict === 1)) {
                  if (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict === 1) || tempTournamentMatchData[matchNumberIndex].kamp_ud_spiller_er_syg) {
                    // There exists a verdict against at least one player.
                    // Handle statistic calculation for the number of match set lost by player(s).
                    if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt)) {
                      this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].playerSelected ? 1 : 0)
                    } else if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === false) {
                      // Handle also situations, where a player is not selected for the match.
                      this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].playerSelected ? 1 : 0)
                    } else if (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && indexHomePlayer2 > -1 && indexAwayPlayer2 > -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt)) {
                      // Handle also double match situations.
                      this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].playerSelected ? 1 : 0)
                      this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Away - 1)].playerSelected ? 1 : 0)
                    }
                  } else {
                    if (this.setResults[matchSetArrayIndex - 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex - 1].matchSetClosed > 1) {
                    // if ((this.setResults[matchSetArrayIndex].matchSetDart === 0 && this.setResults[matchSetArrayIndex].matchSetClosed === 0) || (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 0 && this.setResults[matchSetArrayIndex].matchSetDart < this.setResults[matchSetArrayIndex - 1].matchSetDart)) {
                      // Handle statistic calculation for the number of match set lost by player(s).
                      if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt)) {
                        this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].playerSelected && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1)) && (this.setResults[matchSetArrayIndex - 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex - 1].matchSetClosed > 1) ? 1 : 0)
                      } else if (this.matchOrder[tempArrIndex1].isDouble === false && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === false) {
                        // Handle also situations, where a player is not selected for the match.
                        this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].playerSelected && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1)) ? 1 : 0)
                      } else if (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer !== -1 && indexAwayPlayer !== -1 && indexHomePlayer2 !== -1 && indexAwayPlayer2 !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt) && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt)) {
                        // Handle also double match situations.
                        this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].playerSelected && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1)) && (this.setResults[matchSetArrayIndex - 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex - 1].matchSetClosed > 1) ? 1 : 0)
                        this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetsLost += (this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Away - 1)].playerSelected && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1)) && (this.setResults[matchSetArrayIndex - 1].matchSetDart > 8 || this.setResults[matchSetArrayIndex - 1].matchSetClosed > 1) ? 1 : 0)
                      }
                    }
                    if (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 0) {
                      // Handle statistic calculation for the highest closing value in the match by player(s).
                      if (this.setResults[matchSetArrayIndex].matchSetClosed > this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetHighestClosing) {
                        this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetHighestClosing = this.setResults[matchSetArrayIndex].matchSetClosed
                      }
                      if (this.matchOrder[tempArrIndex1].isDouble && this.setResults[matchSetArrayIndex].matchSetClosed > this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetHighestClosing) {
                        // Handle also double match situations.
                        this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetHighestClosing = this.setResults[matchSetArrayIndex].matchSetClosed
                      }
                    }
                    if (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 0) {
                      // Handle statistic calculation for the fastest darting value in the match by player(s).
                      if (this.setResults[matchSetArrayIndex].matchSetDart < this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetFastestDarting || this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetFastestDarting <= 0) {
                        this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchSetFastestDarting = this.setResults[matchSetArrayIndex].matchSetDart
                      }
                      if (this.matchOrder[tempArrIndex1].isDouble && (this.setResults[matchSetArrayIndex].matchSetDart < this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetFastestDarting || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetFastestDarting <= 0)) {
                        // Handle also double match situations.
                        this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchSetFastestDarting = this.setResults[matchSetArrayIndex].matchSetDart
                      }
                    }
                  }
                }
              }

              // Code for calculating matchset subresults win for either home team or guest team in each match. Also make sure to handle the consequences of player verdicts and illness correctly.
              // if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam === true && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1))) {
              if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam === true && (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1) || tempTournamentMatchData[matchNumberIndex].kamp_hj_spiller_er_syg)) {
                // Skip doing anything, if the player(s) of the opponent team also has a verdict against them.
                // if (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1)) {
                if (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict < 1) && !tempTournamentMatchData[matchNumberIndex].kamp_ud_spiller_er_syg) {
                  countMatchSetResultGuest++
                  // console.log('[updateTournamentSettlementMatchSetTables:home] Verdic against / illness of home team player (increasing away team player set result)! Current match set now at [Home - Away] = ' + countMatchSetResultHome.toString() + ' - ' + countMatchSetResultGuest.toString())
                }
              } else if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam === false && (this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict === 1) || tempTournamentMatchData[matchNumberIndex].kamp_ud_spiller_er_syg)) {
                // Skip doing anything, if the player(s) of the opponent team also has a verdict against them.
                // if (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1)) {
                if (this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict < 1 && (this.matchOrder[tempArrIndex1].isDouble === false || this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict < 1) && !tempTournamentMatchData[matchNumberIndex].kamp_hj_spiller_er_syg) {
                  countMatchSetResultHome++
                  console.log('[updateTournamentSettlementMatchSetTables:away] Verdic against / illness of away team player (increasing home team player set result)! Current match set now at [Home - Away] = ' + countMatchSetResultHome.toString() + ' - ' + countMatchSetResultGuest.toString())
                }
              } else if (this.setResults[matchSetArrayIndex].matchSetDart > 0 && this.setResults[matchSetArrayIndex].matchSetClosed > 1) {
                if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam) {
                  // Skip doing anything, if the away team player(s) has a verdict against themself (or has illness), as the result in that case should already be handled in the previous if-else statements just before this if-else statement.
                  if (!(this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].playerVerdict === 1) || tempTournamentMatchData[matchNumberIndex].kamp_ud_spiller_er_syg)) {
                    countMatchSetResultHome++
                  }
                } else {
                  // Skip increasing the result if the home team player(s) has a verdict against themself (or has illness), as the result in that case should already be handled in the previous if-else statements just before this if-else statement.
                  if (!(this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].playerVerdict === 1 || (this.matchOrder[tempArrIndex1].isDouble === true && this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].playerVerdict === 1) || tempTournamentMatchData[matchNumberIndex].kamp_hj_spiller_er_syg)) {
                    countMatchSetResultGuest++
                  }
                }
              }

              // console.log('[updateTournamentSettlementMatchSetTables] [' + arrIndex2 + '] Match ' + (tempArrIndex1 + 1) + ' result of countMatchSetResultHome / countMatchSetResultGuest = ' + countMatchSetResultHome + ' / ' + countMatchSetResultGuest)

              // Handle total score calculation in the situation where there is only two player (one per team).
              if (this.numberOfMatches === 1 && this.matchRules.minPlayers === 1) {
                if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam === false) {
                  // If the sum of match sets of each player is equal to the maximum number of match set possible in the match, then set a score for the entire match according to who won most match set. In any other case set the score to be 0 - 0 .
                  if ((countMatchSetResultGuest + tmpCountMatchSetResultHome) === this.numberOfSets && !(tempTournamentMatchData[matchNumberIndex].kamp_hj_spiller_er_syg && tempTournamentMatchData[matchNumberIndex].kamp_ud_spiller_er_syg)) {
                    if (countMatchSetResultGuest > tmpCountMatchSetResultHome) {
                      this.totalResultHome = tmpCountMatchSetResultHome
                      this.totalResultGuest = countMatchSetResultGuest
                      this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon = 0
                      this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon = 1
                    } else {
                      this.totalResultHome = tmpCountMatchSetResultHome
                      this.totalResultGuest = countMatchSetResultGuest
                      this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon = 1
                      this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon = 0
                    }
                  } else {
                    this.totalResultHome = 0
                    this.totalResultGuest = 0
                    this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon = 0
                    this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon = 0
                  }
                } else {
                  tmpCountMatchSetResultHome = countMatchSetResultHome
                }
              } else {
                // console.log('[updateTournamentSettlementMatchSetTables] ' + this.matchSettlementPlayerMatchSetResults[matchSetArrayIndex].matchHomeOrGuestTeam.toString() + ' / ' + arrIndex2 + ' >= ' + (this.matchSettlementNumberOfSetsPerMatch * 2 - 2).toString() + ' / ' + countMatchSetResultHome)
                // Code for calculating the total match settlement result for the home and guest teams based upon all the calculated matchset subresults wins.
                // if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam && arrIndex2 >= (this.numberOfSets * 2 - 2) && countMatchSetResultHome === this.numberOfSets) {
                if (arrIndex2 >= (this.numberOfSets * 2 - 2) && countMatchSetResultHome === this.numberOfSets) {
                  // if (this.numberOfMatches === 1 && this.matchRules.minPlayers === 1) {
                  //   // this.totalResultHome = countMatchSetResultHome
                  // } else {
                  //   this.totalResultHome++
                  // }
                  this.totalResultHome++
                  countMatchSetResultHome = 0

                  // Handle statistic calculation for the number of match set won by player(s).
                  this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon += 1
                  this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].matchesWon = this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon
                  if (this.matchOrder[tempArrIndex1].isDouble) {
                    // Handle also double match situations.
                    this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchesWon += 1
                    this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Home - 1)].matchesWon = this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchesWon
                  }
                }

                // if (this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam === false && arrIndex2 >= (this.numberOfSets * 2 - 1) && countMatchSetResultGuest === this.numberOfSets) {
                if (arrIndex2 >= (this.numberOfSets * 2 - 1) && countMatchSetResultGuest === this.numberOfSets) {
                  // if (this.numberOfMatches === 1 && this.matchRules.minPlayers === 1) {
                  //   // this.totalResultGuest = countMatchSetResultGuest
                  // } else {
                  //   this.totalResultGuest++
                  // }
                  this.totalResultGuest++
                  countMatchSetResultGuest = 0

                  // Handle statistic calculation for the number of match set won by player(s).
                  this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon += 1
                  // this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].matchesWon = this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon
                  if (this.matchOrder[tempArrIndex1].isDouble) {
                    // Handle also double match situations.
                    this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchesWon += 1
                    // this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Away - 1)].matchesWon = this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchesWon
                  }
                }

                // Handle the match score result adjustments of a player for situations, where a team loses a match, because the number of players in that team is less than the number of players of the opposing team.
                if (matchesWonAdjustmentsExecuteOnlyOncePerMatchFlag && indexHomePlayer !== -1 && indexAwayPlayer !== -1) {
                  if (((this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt) && this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === false) || (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer2 !== -1 && indexAwayPlayer2 !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt) && this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === false)) {
                    // Handle statistic calculation for the number of match set won by player(s).
                    this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon += 1
                    this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexHome - 1)].matchesWon = this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon
                    if (this.matchOrder[tempArrIndex1].isDouble) {
                      // Handle also double match situations.
                      this.homePlayersInfoData[indexOfHomeTeamPlayer2MemberData].matchesWon += 1
                      this.homePlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Home - 1)].matchesWon = this.homePlayersInfoData[indexOfHomeTeamPlayerMemberData].matchesWon
                    }
                  }
                  if (((this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer].kampafvikling_spiller_ud_valgt) && this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer].kampafvikling_spiller_hj_valgt === false) || (this.matchOrder[tempArrIndex1].isDouble && indexHomePlayer2 !== -1 && indexAwayPlayer2 !== -1 && (this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexAwayPlayer2].kampafvikling_spiller_ud_valgt) && this.matchExecution.kampafvikling_ekstras_id[indexHomePlayer2].kampafvikling_spiller_hj_valgt === false)) {
                    // Handle statistic calculation for the number of match set won by player(s).
                    this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon += 1
                    this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndexAway - 1)].matchesWon = this.awayPlayersInfoData[indexOfGuestTeamPlayerMemberData].matchesWon
                    if (this.matchOrder[tempArrIndex1].isDouble) {
                      // Handle also double match situations.
                      this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchesWon += 1
                      this.awayPlayersInfoData[(this.matchOrder[tempArrIndex1].playerIndex2Away - 1)].matchesWon = this.awayPlayersInfoData[indexOfGuestTeamPlayer2MemberData].matchesWon
                    }
                  }
                  matchesWonAdjustmentsExecuteOnlyOncePerMatchFlag = false
                }
              }

              console.log('[updateTournamentSettlementMatchSetTables] matchSetArrayIndex = ' + matchSetArrayIndex.toString())
              this.updatedMatchSetsFlagsArr.push(true)
              // console.log('[updateTournamentSettlementMatchSetTables] updatedMatchSetsFlagsArr.length = ' + this.updatedMatchSetsFlagsArr.length.toString())
              if (this.updatedMatchSetsFlagsArr.length >= (this.numberOfMatches * this.numberOfSets * 2)) {
                this.updateTournamentMatchProcessFlag = false
              }

              if (matchSetIndex2 > -1 && (onlyDirtyRowData === false || (this.setResults[matchSetArrayIndex].matchSetDirtyFlag & (1 + 2 + 4 + 8)) === 4)) {
                const updateTournamentMatchSetData = {
                  kamp_id: { id: this.setResults[matchSetArrayIndex].matchId.toString() },
                  kamp_saet_dart: Number(this.setResults[matchSetArrayIndex].matchSetDart).toString(),
                  kamp_saet_lukket: Number(this.setResults[matchSetArrayIndex].matchSetClosed).toString(),
                  kamp_saet_restscore: Number(this.setResults[matchSetArrayIndex].matchSetRemainScore).toString(),
                  kamp_saet_udlaeg: this.setResults[matchSetArrayIndex].matchPlayerWhoStartedFirstRound,
                  kamp_saet_saet: Number(this.setResults[matchSetArrayIndex].matchSet).toString(),
                  kamp_saet_hjem_ud: this.setResults[matchSetArrayIndex].matchHomeOrAwayTeam
                }

                Tournaments.TournamentMatchSetDataService.update(Number(tempSetsData[matchSetIndex2].id).toString(), updateTournamentMatchSetData)
                  .then((response) => {
                    this.setResults[matchSetArrayIndex].matchSetDirtyFlag = 0
                    this.matchSetArrayIndexForUpdateSetResults = matchSetArrayIndex
                    this.emitTheWebSocketEvent()
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                  .finally(() => {
                    return true
                  })
              }
              arrIndex2++
            }
            this.tournamentMatchResultsCalcProgress++
          }
          arrIndex1++
        }
        this.updateMatch180()
      })
      .catch((err) => {
        console.error(err)

        return false
      })
    return false
  }

  public updateMatch180 () : boolean {
    if (!this.spectatorMode && (this.match.kampgodkendt_id === null || !this.match.kampgodkendt_id.kampgodkendt_godkendt)) {
      let isRegularMatch = true
      const playersInfoDataArrLength = this.homePlayersInfoData.length + this.awayPlayersInfoData.length

      // Check if advanced extended match mode is being used. If any value in the arrays is greater than zero, then it is not a regular match 180 update.
      for (let arrIndex = 0; arrIndex < playersInfoDataArrLength; arrIndex++) {
        if ((arrIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[arrIndex].match180extendedMode.some(value => { return value > 0 }) : this.awayPlayersInfoData[arrIndex - this.homePlayersInfoData.length].match180extendedMode.some(value => { return value > 0 }))) {
          // if (isRegularMatch) {
          isRegularMatch = false
          // console.log('[updateMatch180()] isRegularMatch = false')
          break
        }
      }
      console.log('[updateMatch180()] Next loop.')

      for (let arrIndex = 0; arrIndex < playersInfoDataArrLength;) {
        const tempArrayIndex = arrIndex
        Tournaments.TournamentMatch180DataService.getAll('', null, `kampprogram_id=${this.match.id}&medlem_id=${(tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].memberId : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].memberId)}`)
          .then((response) => {
            let tempTournamentMatchOneEightyData: SysTournamentMatch180[] = []
            tempTournamentMatchOneEightyData = response.data
            const match180extSumValue = (tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180extendedMode.reduce((partialSum, elemVal) => partialSum + elemVal, 0) : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180extendedMode.reduce((partialSum, elemVal) => partialSum + elemVal, 0))
            const match180WriteOnlyMode = (tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180WriteOnlyMode : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180WriteOnlyMode)

            if (tempTournamentMatchOneEightyData.length > 0 && tempTournamentMatchOneEightyData[0].id !== undefined && tempTournamentMatchOneEightyData[0].id !== null) {
              let inputMatch180 = 0

              // Use value obtained from the API access as input value instead of the value of input field of the match page, when the advanced extended match mode is being used, unless the value is null.
              if ((tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180extendedMode.some(value => { return value > 0 }) : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180extendedMode.some(value => { return value > 0 })) && tempTournamentMatchOneEightyData[0].kamp_180_antal !== null) {
                inputMatch180 = tempTournamentMatchOneEightyData[0].kamp_180_antal + match180extSumValue
              } else {
                inputMatch180 = Number(tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180 : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180) + match180extSumValue
              }

              const updateMatch180 = {
                kampprogram_id: Number(this.match.id).toString(),
                medlem_id: (tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].memberId : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].memberId).toString(),
                kamp_180_antal: inputMatch180.toString()
                // kamp_180_antal: (tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180 : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180).toString()
              }

              if ((isRegularMatch && match180WriteOnlyMode) || match180extSumValue > 0) {
                Tournaments.TournamentMatch180DataService.update(Number(tempTournamentMatchOneEightyData[0].id).toString(), updateMatch180)
                  .then((response) => {
                    // Reset values of the array tracking 180 score results from the extended match to zero.
                    if (tempArrayIndex < this.homePlayersInfoData.length) {
                      this.homePlayersInfoData[tempArrayIndex].match180extendedMode = Array(this.numberOfMatches).fill(0)
                      this.homePlayersInfoData[tempArrayIndex].match180 = response.data.kamp_180_antal
                    } else {
                      this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180extendedMode = Array(this.numberOfMatches).fill(0)
                      this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180 = response.data.kamp_180_antal
                    }

                    // console.log('[updateMatch180()] Update Match 180 data = ' + JSON.stringify(response.data))
                    console.log('[updateMatch180()] Update Match 180 status: ' + response.statusText)
                    return true
                  })
                  .catch((err) => {
                    console.error(err)
                    return true
                  })
              } else {
                if (tempArrayIndex < this.homePlayersInfoData.length) {
                  if (tempTournamentMatchOneEightyData[0].kamp_180_antal !== null) {
                    this.homePlayersInfoData[tempArrayIndex].match180 = tempTournamentMatchOneEightyData[0].kamp_180_antal
                  }
                } else {
                  if (tempTournamentMatchOneEightyData[0].kamp_180_antal !== null) {
                    this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180 = tempTournamentMatchOneEightyData[0].kamp_180_antal
                  }
                }
              }
            } else {
              const createMatch180 = {
                kampprogram_id: Number(this.match.id).toString(),
                medlem_id: (tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].memberId : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].memberId).toString(),
                kamp_180_antal: (Number(tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180 : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180) + match180extSumValue).toString()
                // kamp_180_antal: (tempArrayIndex < this.homePlayersInfoData.length ? this.homePlayersInfoData[tempArrayIndex].match180 : this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180).toString()
              }

              Tournaments.TournamentMatch180DataService.create(createMatch180)
                .then((response) => {
                  // Reset values of the array tracking 180 score results from the extended match to zero.
                  if (tempArrayIndex < this.homePlayersInfoData.length) {
                    this.homePlayersInfoData[tempArrayIndex].match180extendedMode = Array(this.numberOfMatches).fill(0)
                  } else {
                    this.awayPlayersInfoData[tempArrayIndex - this.homePlayersInfoData.length].match180extendedMode = Array(this.numberOfMatches).fill(0)
                  }

                  // console.log('[updateMatch180()] Update Match 180 data = ' + JSON.stringify(response.data))
                  console.log('[updateMatch180()] Create Match 180 status: ' + response.statusText)
                  return true
                })
                .catch((err) => {
                  console.error(err)
                  return true
                })
            }
          })
          .catch((err) => {
            console.error(err)
            return true
          })
        arrIndex++
      }
    }
    return false
  }

  public conditionalUpdateDirtyFieldSetResults (indexNumber: number, mSet: number, homeOrAwayTeam: boolean, checkSetClosedDartValues = false, setDart = 0, setClosed = 0, setRemainScore = 0) {
    if (!this.spectatorMode && !this.matchPlayed) {
      this.updateDirtyFieldSetResults(indexNumber, mSet, homeOrAwayTeam, checkSetClosedDartValues, setDart, setClosed, setRemainScore)
    }
  }

  public updateDirtyFieldSetResults (indexNumber: number, mSet: number, homeOrAwayTeam: boolean, checkSetClosedDartValues = false, setDart = 0, setClosed = 0, setRemainScore = 0) : void {
    for (const item of this.setResults) {
      if (item.matchIndexNumber === indexNumber && item.matchSet === mSet && item.matchHomeOrAwayTeam === homeOrAwayTeam) {
        item.matchSetDart = Math.round(Number(item.matchSetDart.toString().trim()))
        item.matchSetClosed = Math.round(Number(item.matchSetClosed.toString().trim()))
        item.matchSetRemainScore = Math.round(Number(item.matchSetRemainScore.toString().trim()))

        // Check for some values that are invalid for the match set dart field.
        if (checkSetClosedDartValues && (setDart < 9 || setDart > 2505) && setDart !== 0) {
          item.matchSetDirtyFlag |= 1 // Set bit 0
        } else {
          item.matchSetDirtyFlag = item.matchSetDirtyFlag & (255 - 1) // Reset bit 0
        }

        // Checks for some values that are invalid for the match set closed field
        if (checkSetClosedDartValues && (setClosed < 0 || setClosed === 1 || setClosed > 170 || setClosed === 169 || setClosed === 168 || setClosed === 166 || setClosed === 165 || setClosed === 163 || setClosed === 162 || setClosed === 159)) {
          item.matchSetDirtyFlag |= 2 // Set bit 1
        } else {
          item.matchSetDirtyFlag = item.matchSetDirtyFlag & (255 - 2) // Reset bit 1
        }

        // Check for some values that are invalid for the match set remaider score field.
        if (checkSetClosedDartValues && (setRemainScore < 0 || setRemainScore > 501) && setRemainScore !== 0) {
          item.matchSetDirtyFlag |= 8 // Set bit 5
        } else {
          item.matchSetDirtyFlag = item.matchSetDirtyFlag & (255 - 8) // Reset bit 0
        }

        item.matchSetDirtyFlag |= 4 // Set bit 2
        // console.log('[updateDirtyFieldSetResults] Dirty flag = ' + item.matchSetDirtyFlag)
      }
    }

    this.updateMatchResults()
  }

  public emitTheWebSocketEvent () : void {
    // const userId = JSON.parse((localStorage.getItem('user') as any))
    // const channel = ably.channels.get('ScoreBoardPage')
    // channel.publish('refreshMatchPage', { matchId: this.matchId, userId: userId.id, setIndex: this.matchSetArrayIndexForUpdateSetResults, setResultsData: this.setResults[this.matchSetArrayIndexForUpdateSetResults] })
  }

  public isNumber (evt: KeyboardEvent) : void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const keyPressed: string = evt.key

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault()
    }
  }

  public getAUXMatchData (realDummyFlag: false) {
    return (realDummyFlag ? this.getMatchData() : this.getDummyMatchData())
  }

  public getMatchData () : { matchRealIndexNumber: number, matchHomeTeamPlayer: string; matchHomeTeamPlayer2: string; matchHomeTeamPlayerName: string; matchHomeTeamPlayer2Name: string; matchGuestTeamPlayer: string; matchGuestTeamPlayer2: string; matchGuestTeamPlayerName: string; matchGuestTeamPlayer2Name: string; matchHomeScoreResultRecorder: string | null; matchGuestScoreResultRecorder: string | null; matchIsDouble: boolean }[] | null {
    const retVal: any = []

    if (this.matchExecution === undefined || this.matchExecution.kampafvikling_ekstras_id === undefined) {
      return retVal
    }

    for (let arrIndex = 0; arrIndex < this.numberOfMatches; arrIndex++) {
      const indexHomeTeamPlayerInMatchSettlementExt = (this.matchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndexHome)
      const indexGuestTeamPlayerInMatchSettlementExt = (this.matchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndexAway)
      const indexHomeTeamPlayerResultRecorderInMatchSettlementExt = (this.matchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].resultRecorderHome)
      const indexGuestTeamPlayerResultRecorderInMatchSettlementExt = (this.matchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].resultRecorderGuest)
      let indexHomeTeamPlayer2InMatchSettlementExt = -1
      let indexGuestTeamPlayer2InMatchSettlementExt = -1

      if (this.matchOrder[arrIndex].isDouble === true) {
        indexHomeTeamPlayer2InMatchSettlementExt = (this.matchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndex2Home)
        indexGuestTeamPlayer2InMatchSettlementExt = (this.matchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndex2Away)
      }

      if (indexHomeTeamPlayerInMatchSettlementExt > -1 && indexGuestTeamPlayerInMatchSettlementExt > -1 && (this.matchOrder[arrIndex].isDouble === false || (indexHomeTeamPlayer2InMatchSettlementExt > -1 && indexGuestTeamPlayer2InMatchSettlementExt > -1)) && (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt)) {
        const indexHomeTeamPlayerInMembers = this.homeAwayMembers.findIndex(el => el.id === this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj)
        const indexGuestTeamPlayerInMembers = this.homeAwayMembers.findIndex(el => el.id === this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud)
        const indexHomePlayerResultRecorderInMembers = (indexHomeTeamPlayerResultRecorderInMatchSettlementExt >= 0 ? this.homeAwayMembers.findIndex(el => el.id === this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerResultRecorderInMatchSettlementExt].kampafvikling_spiller_hj) : -1)
        const indexGuestPlayerResultRecorderInMembers = (indexGuestTeamPlayerResultRecorderInMatchSettlementExt >= 0 ? this.homeAwayMembers.findIndex(el => el.id === this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerResultRecorderInMatchSettlementExt].kampafvikling_spiller_ud) : -1)
        let indexHomeTeamPlayer2InMembers = -1
        let indexGuestTeamPlayer2InMembers = -1

        if (this.matchOrder[arrIndex].isDouble === true) {
          indexHomeTeamPlayer2InMembers = this.homeAwayMembers.findIndex(el => el.id === this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj)
          indexGuestTeamPlayer2InMembers = this.homeAwayMembers.findIndex(el => el.id === this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud)
        }

        if (indexHomeTeamPlayerInMembers > -1 && indexGuestTeamPlayerInMembers > -1 && (this.matchOrder[arrIndex].isDouble === false || (indexHomeTeamPlayer2InMembers > -1 && indexGuestTeamPlayer2InMembers > -1))) {
          if (((this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt !== null && this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === false) || (this.matchOrder[arrIndex].isDouble && this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt !== null && this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === false)) && (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt)) {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt ? this.matchOrder[arrIndex].playerIndexHome.toString() : '9'),
              matchHomeTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt) ? (this.matchOrder[arrIndex].isDouble ? this.matchOrder[arrIndex].playerIndex2Home.toString() : '9') : '9'),
              matchHomeTeamPlayerName: (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt ? this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.lastname : 'TestHomePlayerName'),
              matchHomeTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt) ? (this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.lastname : 'TestHomePlayer2Name') : 'TestHomePlayer2Name'),
              matchGuestTeamPlayer: String.fromCharCode(this.matchOrder[arrIndex].playerIndexAway + 64),
              matchGuestTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt !== false) ? String.fromCharCode(this.matchOrder[arrIndex].playerIndex2Away + 64) : 'Z'),
              matchGuestTeamPlayerName: this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.lastname,
              matchGuestTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt !== false) ? this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.lastname : 'TestGuestPlayer2Name'),
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })
          } else if ((this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt) && ((this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt !== null && this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === false) || (this.matchOrder[arrIndex].isDouble && this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt !== null && this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === false))) {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: this.matchOrder[arrIndex].playerIndexHome.toString(),
              matchHomeTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt !== false) ? this.matchOrder[arrIndex].playerIndex2Home.toString() : '9'),
              matchHomeTeamPlayerName: this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.lastname,
              matchHomeTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt !== false) ? this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.lastname : 'TestHomePlayer2Name'),
              matchGuestTeamPlayer: (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt ? String.fromCharCode(this.matchOrder[arrIndex].playerIndexAway + 64) : 'Z'),
              matchGuestTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt) ? (this.matchOrder[arrIndex].isDouble ? String.fromCharCode(this.matchOrder[arrIndex].playerIndex2Away + 64) : 'Z') : 'Z'),
              matchGuestTeamPlayerName: (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt ? this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.lastname : 'TestGuestPlayerName'),
              matchGuestTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble && (this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt) ? (this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.lastname : 'TestGuestPlayer2Name') : 'TestGuestPlayer2Name'),
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })
          } else if (this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt !== null && this.matchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === false && this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt !== null && this.matchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === false) {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: '9',
              matchHomeTeamPlayer2: '9',
              matchHomeTeamPlayerName: 'TestHomePlayerName',
              matchHomeTeamPlayer2Name: 'TestHomePlayer2Name',
              matchGuestTeamPlayer: 'Z',
              matchGuestTeamPlayer2: 'Z',
              matchGuestTeamPlayerName: 'TestGuestPlayerName',
              matchGuestTeamPlayer2Name: 'TestGuestPlayer2Name',
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })
          } else {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: this.matchOrder[arrIndex].playerIndexHome.toString(),
              matchHomeTeamPlayer2: (this.matchOrder[arrIndex].isDouble ? this.matchOrder[arrIndex].playerIndex2Home.toString() : '9'),
              matchHomeTeamPlayerName: this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.lastname,
              matchHomeTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.lastname : 'TestHomePlayer2Name'),
              matchGuestTeamPlayer: String.fromCharCode(this.matchOrder[arrIndex].playerIndexAway + 64),
              matchGuestTeamPlayer2: (this.matchOrder[arrIndex].isDouble ? String.fromCharCode(this.matchOrder[arrIndex].playerIndex2Away + 64) : 'Z'),
              matchGuestTeamPlayerName: this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.lastname,
              matchGuestTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.lastname : 'TestGuestPlayer2Name'),
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })

            this.forceShowMatchCardTable = true
          }
        } else {
          console.log('Error encountered: Team player member data not found!')
        }
      } else {
        console.log('Error encountered: Team player match execution extra data not found!')
      }
    }

    return retVal
  }

  public getDummyMatchData (dummyMode = true) : { matchRealIndexNumber: number, matchHomeTeamPlayer: string; matchHomeTeamPlayer2: string; matchHomeTeamPlayerName: string; matchHomeTeamPlayer2Name: string; matchGuestTeamPlayer: string; matchGuestTeamPlayer2: string; matchGuestTeamPlayerName: string; matchGuestTeamPlayer2Name: string; matchHomeScoreResultRecorder: string | null; matchGuestScoreResultRecorder: string | null; matchIsDouble: boolean }[] | null {
    const retVal: any = []

    if (this.dummyMatchExecution === undefined || this.dummyMatchExecution.kampafvikling_ekstras_id === undefined) {
      return retVal
    }

    for (let arrIndex = 0; arrIndex < this.numberOfMatches; arrIndex++) {
      const indexHomeTeamPlayerInMatchSettlementExt = (this.dummyMatchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndexHome)
      const indexGuestTeamPlayerInMatchSettlementExt = (this.dummyMatchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndexAway)
      const indexHomeTeamPlayerResultRecorderInMatchSettlementExt = (this.dummyMatchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].resultRecorderHome)
      const indexGuestTeamPlayerResultRecorderInMatchSettlementExt = (this.dummyMatchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].resultRecorderGuest)
      let indexHomeTeamPlayer2InMatchSettlementExt = -1
      let indexGuestTeamPlayer2InMatchSettlementExt = -1

      if (this.matchOrder[arrIndex].isDouble === true) {
        indexHomeTeamPlayer2InMatchSettlementExt = (this.dummyMatchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndex2Home)
        indexGuestTeamPlayer2InMatchSettlementExt = (this.dummyMatchExecution.kampafvikling_ekstras_id).findIndex(el => Number(el.kampafvikling_spiller_indeks) === this.matchOrder[arrIndex].playerIndex2Away)
      }

      if (indexHomeTeamPlayerInMatchSettlementExt > -1 && indexGuestTeamPlayerInMatchSettlementExt > -1 && (this.matchOrder[arrIndex].isDouble === false || (indexHomeTeamPlayer2InMatchSettlementExt > -1 && indexGuestTeamPlayer2InMatchSettlementExt > -1)) && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt)) {
        const indexHomeTeamPlayerInMembers = this.homeAwayMembers.findIndex(el => el.id === this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj)
        const indexGuestTeamPlayerInMembers = this.homeAwayMembers.findIndex(el => el.id === this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud)
        const indexHomePlayerResultRecorderInMembers = (indexHomeTeamPlayerResultRecorderInMatchSettlementExt >= 0 ? this.homeAwayMembers.findIndex(el => el.id === this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerResultRecorderInMatchSettlementExt].kampafvikling_spiller_hj) : -1)
        const indexGuestPlayerResultRecorderInMembers = (indexGuestTeamPlayerResultRecorderInMatchSettlementExt >= 0 ? this.homeAwayMembers.findIndex(el => el.id === this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerResultRecorderInMatchSettlementExt].kampafvikling_spiller_ud) : -1)
        let indexHomeTeamPlayer2InMembers = -1
        let indexGuestTeamPlayer2InMembers = -1

        if (this.matchOrder[arrIndex].isDouble === true) {
          indexHomeTeamPlayer2InMembers = this.homeAwayMembers.findIndex(el => el.id === this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj)
          indexGuestTeamPlayer2InMembers = this.homeAwayMembers.findIndex(el => el.id === this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud)
        }

        if ((dummyMode || (indexHomeTeamPlayerInMembers > -1 && indexGuestTeamPlayerInMembers > -1)) && (dummyMode || this.matchOrder[arrIndex].isDouble === false || (indexHomeTeamPlayer2InMembers > -1 && indexGuestTeamPlayer2InMembers > -1))) {
          if (((this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt !== null && this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === false) || (this.matchOrder[arrIndex].isDouble && this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt !== null && this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === false)) && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt)) {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt ? this.matchOrder[arrIndex].playerIndexHome.toString() : '9'),
              matchHomeTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt) ? (this.matchOrder[arrIndex].isDouble ? this.matchOrder[arrIndex].playerIndex2Home.toString() : '9') : '9'),
              matchHomeTeamPlayerName: (!dummyMode && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt) ? this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.lastname : '' /* 'TestHomePlayerName' */),
              matchHomeTeamPlayer2Name: (this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt) ? (!dummyMode && this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.lastname : '' /* 'TestHomePlayer2Name' */) : '' /* 'TestHomePlayer2Name' */),
              matchGuestTeamPlayer: String.fromCharCode(this.matchOrder[arrIndex].playerIndexAway + 64),
              matchGuestTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt !== false) ? String.fromCharCode(this.matchOrder[arrIndex].playerIndex2Away + 64) : 'Z'),
              matchGuestTeamPlayerName: (!dummyMode ? this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.lastname : '' /* 'TestGuestPlayerName' */),
              matchGuestTeamPlayer2Name: (!dummyMode && this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_hj_valgt !== false) ? this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.lastname : '' /* 'TestGuestPlayer2Name' */),
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })
          } else if ((this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt) && ((this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt !== null && this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === false) || (this.matchOrder[arrIndex].isDouble && this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt !== null && this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === false))) {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: this.matchOrder[arrIndex].playerIndexHome.toString(),
              matchHomeTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt !== false) ? this.matchOrder[arrIndex].playerIndex2Home.toString() : '9'),
              matchHomeTeamPlayerName: (!dummyMode ? this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.lastname : '' /* 'TestHomePlayerName' */),
              matchHomeTeamPlayer2Name: (!dummyMode && this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt !== false) ? this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.lastname : '' /* 'TestHomePlayer2Name' */),
              matchGuestTeamPlayer: (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt ? String.fromCharCode(this.matchOrder[arrIndex].playerIndexAway + 64) : 'Z'),
              matchGuestTeamPlayer2: (this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt) ? (this.matchOrder[arrIndex].isDouble ? String.fromCharCode(this.matchOrder[arrIndex].playerIndex2Away + 64) : 'Z') : 'Z'),
              matchGuestTeamPlayerName: (!dummyMode && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt) ? this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.lastname : '' /* 'TestGuestPlayerName' */),
              matchGuestTeamPlayer2Name: (!dummyMode && this.matchOrder[arrIndex].isDouble && (this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt === null || this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayer2InMatchSettlementExt].kampafvikling_spiller_ud_valgt) ? (this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.lastname : '' /* 'TestGuestPlayer2Name' */) : '' /* 'TestGuestPlayer2Name' */),
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })
          } else if (this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt !== null && this.dummyMatchExecution.kampafvikling_ekstras_id[indexHomeTeamPlayerInMatchSettlementExt].kampafvikling_spiller_hj_valgt === false && this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt !== null && this.dummyMatchExecution.kampafvikling_ekstras_id[indexGuestTeamPlayerInMatchSettlementExt].kampafvikling_spiller_ud_valgt === false) {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: '9',
              matchHomeTeamPlayer2: '9',
              matchHomeTeamPlayerName: '' /* 'TestHomePlayerName' */,
              matchHomeTeamPlayer2Name: '' /* 'TestHomePlayer2Name' */,
              matchGuestTeamPlayer: 'Z',
              matchGuestTeamPlayer2: 'Z',
              matchGuestTeamPlayerName: '' /* 'TestGuestPlayerName' */,
              matchGuestTeamPlayer2Name: '' /* 'TestGuestPlayer2Name' */,
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })
          } else {
            retVal.push({
              matchRealIndexNumber: arrIndex,
              matchHomeTeamPlayer: this.matchOrder[arrIndex].playerIndexHome.toString(),
              matchHomeTeamPlayer2: (this.matchOrder[arrIndex].isDouble ? this.matchOrder[arrIndex].playerIndex2Home.toString() : '9'),
              matchHomeTeamPlayerName: (!dummyMode ? this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayerInMembers].user_id.lastname : '' /* 'TestHomePlayerName' */),
              matchHomeTeamPlayer2Name: (!dummyMode && this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomeTeamPlayer2InMembers].user_id.lastname : '' /* 'TestHomePlayer2Name' */),
              matchGuestTeamPlayer: String.fromCharCode(this.matchOrder[arrIndex].playerIndexAway + 64),
              matchGuestTeamPlayer2: (this.matchOrder[arrIndex].isDouble ? String.fromCharCode(this.matchOrder[arrIndex].playerIndex2Away + 64) : 'Z'),
              matchGuestTeamPlayerName: (!dummyMode ? this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayerInMembers].user_id.lastname : '' /* 'TestGuestPlayerName' */),
              matchGuestTeamPlayer2Name: (!dummyMode && this.matchOrder[arrIndex].isDouble ? this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestTeamPlayer2InMembers].user_id.lastname : '' /* 'TestGuestPlayer2Name' */),
              matchHomeScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderHome === null || (indexHomePlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderHome) >= 1 ? this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexHomePlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderHome).toString())),
              matchGuestScoreResultRecorder: (this.matchOrder[arrIndex].resultRecorderGuest === null || (indexGuestPlayerResultRecorderInMembers < 0 && Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1) ? null : (Number(this.matchOrder[arrIndex].resultRecorderGuest) >= 1 ? this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.firstname + ' ' + this.homeAwayMembers[indexGuestPlayerResultRecorderInMembers].user_id.lastname : Number(this.matchOrder[arrIndex].resultRecorderGuest).toString())),
              matchIsDouble: this.matchOrder[arrIndex].isDouble
            })

            this.forceShowMatchCardTable = true
          }
        } else {
          console.log('Error encountered: Team player member data not found!')
        }
      } else {
        console.log('Error encountered: Team player match execution extra data not found!')
      }
    }

    return retVal
  }

  public getTournamentMatchSettlementPlayerMatchSetResultThroughIndex (index: number) : { matchId: number; matchIndexNumber: number; matchSetDart: number; matchSetClosed: number; matchSet: number; matchHomeOrAwayTeam: boolean; matchPlayerWhoStartedFirstRound: boolean | null; matchSetDirtyFlag: number }[] {
    const retVal: { matchId: number; matchIndexNumber: number; matchSetDart: number; matchSetClosed: number; matchSet: number; matchHomeOrAwayTeam: boolean; matchPlayerWhoStartedFirstRound: boolean | null;matchSetDirtyFlag: number }[] = []

    for (const item of this.setResults) {
      if (item.matchIndexNumber === index) {
        retVal.push(item)
      }
    }
    return retVal
  }

  public initializeMatchTables () : void {
    Tournaments.TournamentMatchDataService.getAll('kamp_nummer:asc', null, `kampafvikling_id.id=${this.matchExecution.id}`)
      .then((response) => {
        let matchData: SysTournamentMatch[] = []
        matchData = response.data

        for (let arrIndex1 = 0; arrIndex1 < this.numberOfMatches;) {
          const tempArrIndex1 = arrIndex1
          const matchNumberIndex = matchData.findIndex(el => el.kamp_nummer === (arrIndex1 + 1))

          if (matchNumberIndex === -1) {
            const createMatchData = {
              kampafvikling_id: Number(this.matchExecution.id),
              kamp_nummer: (arrIndex1 + 1),
              kamp_hjemmebane_spiller: this.matchOrder[arrIndex1].playerIndexHome.toString(),
              kamp_udebane_spiller: String.fromCharCode(this.matchOrder[arrIndex1].playerIndexAway > 0 && this.matchOrder[arrIndex1].playerIndexAway < 9 ? (this.matchOrder[arrIndex1].playerIndexAway + 64) : 65)
            }
            Tournaments.TournamentMatchDataService.create(createMatchData)
              .then((response) => {
                const createdMatchId = response.data.id
                this.matchesCreated++

                Tournaments.TournamentMatchSetDataService.getAll('kamp_saet_saet:asc', null, `kamp_id.id=${createdMatchId}`)
                  .then((response) => {
                    let setData: SysTournamentMatchSet[] = []
                    setData = response.data
                    console.log(setData)

                    for (let arrIndex2 = 0; arrIndex2 < this.numberOfSets * 2;) {
                      const setArrayIndex = tempArrIndex1 * this.numberOfSets * 2 + arrIndex2
                      this.setResults[setArrayIndex].matchId = createdMatchId
                      this.setResults[setArrayIndex].matchIndexNumber = (tempArrIndex1 + 1)

                      // Also initialize the match Id field in the player illness value array for each match.
                      if (this.setResults[setArrayIndex].matchHomeOrAwayTeam) {
                        this.checkboxMatchHomePlayerIllnessValue[tempArrIndex1].matchId = createdMatchId
                      } else {
                        this.checkboxMatchAwayPlayerIllnessValue[tempArrIndex1].matchId = createdMatchId
                      }

                      const setIndex1 = setData.findIndex(el => (el.kamp_saet_saet === this.setResults[setArrayIndex].matchSet && el.kamp_saet_hjem_ud === this.setResults[setArrayIndex].matchHomeOrAwayTeam))

                      if (setIndex1 === -1) {
                        const createSetData = {
                          kamp_id: createdMatchId,
                          kamp_saet_dart: this.setResults[setArrayIndex].matchSetDart,
                          kamp_saet_lukket: this.setResults[setArrayIndex].matchSetClosed,
                          kamp_saet_restscore: this.setResults[setArrayIndex].matchSetRemainScore,
                          kamp_saet_saet: this.setResults[setArrayIndex].matchSet,
                          kamp_saet_hjem_ud: this.setResults[setArrayIndex].matchHomeOrAwayTeam
                        }

                        Tournaments.TournamentMatchSetDataService.create(createMatchData)
                          .then((response) => {
                            if (this.setResults[setArrayIndex].matchHomeOrAwayTeam === false) {
                              this.setsCreated++
                            }
                          })
                          .catch((err) => {
                            console.error(err)
                          })
                      } else {
                        this.setResults[setArrayIndex].matchSetDart = setData[setIndex1].kamp_saet_dart
                        this.setResults[setArrayIndex].matchSetClosed = setData[setIndex1].kamp_saet_lukket
                        this.setResults[setArrayIndex].matchSetRemainScore = setData[setIndex1].kamp_saet_restscore
                        this.setResults[setArrayIndex].matchPlayerWhoStartedFirstRound = setData[setIndex1].kamp_saet_udlaeg

                        if (this.setResults[setArrayIndex].matchHomeOrAwayTeam === false) {
                          this.setsCreated++
                        }
                      }
                      arrIndex2++
                    }
                  })
                  .catch((err) => {
                    console.error(err)
                  })
              })
              .catch((err) => {
                console.error(err)
              })
          } else {
            this.matchesCreated++

            // The match set was already created previously.
            // Tournaments.TournamentMatchSetDataService.getAll('kamp_saet_saet:asc', null, `kamp_id.id=${matchData[matchNumberIndex].id}`)
            //   .then((response) => {
            let setData: SysTournamentMatchSetId[] = []
            setData = matchData[matchNumberIndex]?.kamp_saets_id || []
            console.log(setData)

            for (let arrIndex2 = 0; arrIndex2 < this.numberOfSets * 2;) {
              const setArrayIndex = tempArrIndex1 * this.numberOfSets * 2 + arrIndex2
              this.setResults[setArrayIndex].matchId = (matchData[matchNumberIndex].id === null ? 0 : Number(matchData[matchNumberIndex].id))
              this.setResults[setArrayIndex].matchIndexNumber = (tempArrIndex1 + 1)

              // Also initialize the match Id field in the player illness value array for each match.
              if (this.setResults[setArrayIndex].matchHomeOrAwayTeam) {
                this.checkboxMatchHomePlayerIllnessValue[tempArrIndex1].matchId = this.setResults[setArrayIndex].matchId
                this.checkboxMatchHomePlayerIllnessValue[tempArrIndex1].value = (matchData[matchNumberIndex].id === null || matchData[matchNumberIndex].kamp_hj_spiller_er_syg === null ? false : Boolean(matchData[matchNumberIndex].kamp_hj_spiller_er_syg))
              } else {
                this.checkboxMatchAwayPlayerIllnessValue[tempArrIndex1].matchId = this.setResults[setArrayIndex].matchId
                this.checkboxMatchAwayPlayerIllnessValue[tempArrIndex1].value = (matchData[matchNumberIndex].id === null || matchData[matchNumberIndex].kamp_ud_spiller_er_syg === null ? false : Boolean(matchData[matchNumberIndex].kamp_ud_spiller_er_syg))
              }

              const setIndex2 = setData.findIndex(el => (el.kamp_saet_saet === this.setResults[setArrayIndex].matchSet && el.kamp_saet_hjem_ud === this.setResults[setArrayIndex].matchHomeOrAwayTeam))

              if (setIndex2 === -1) {
                const createSetData = {
                  kamp_id: this.setResults[setArrayIndex].matchId.toString(),
                  kamp_saet_dart: this.setResults[setArrayIndex].matchSetDart,
                  kamp_saet_lukket: this.setResults[setArrayIndex].matchSetClosed,
                  kamp_saet_restscore: this.setResults[setArrayIndex].matchSetRemainScore,
                  kamp_saet_saet: this.setResults[setArrayIndex].matchSet,
                  kamp_saet_hjem_ud: this.setResults[setArrayIndex].matchHomeOrAwayTeam
                }

                Tournaments.TournamentMatchSetDataService.create(createSetData)
                  .then((response) => {
                    if (this.setResults[setArrayIndex].matchHomeOrAwayTeam === false) {
                      this.setsCreated++
                    }
                  })
                  .catch((err) => {
                    console.error(err)
                  })
              } else {
                this.setResults[setArrayIndex].matchSetDart = setData[setIndex2].kamp_saet_dart
                this.setResults[setArrayIndex].matchSetClosed = setData[setIndex2].kamp_saet_lukket
                this.setResults[setArrayIndex].matchSetRemainScore = setData[setIndex2].kamp_saet_restscore
                this.setResults[setArrayIndex].matchPlayerWhoStartedFirstRound = setData[setIndex2].kamp_saet_udlaeg

                if (this.setResults[setArrayIndex].matchHomeOrAwayTeam === false) {
                  this.setsCreated++
                }
              }
              arrIndex2++
            }
            // })
            // .catch((err) => {
            //   console.error(err)
            // })
          }

          arrIndex1++
        }

        // this.updateMatchResults()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async retrievePrevioslyMatchs (extraParameter = '') {
    await Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('', null, extraParameter)
  }

  public toggleSubmatchFlipFlopFlag () : boolean {
    const retVal = this.submatchFlipFlopFlag
    this.submatchFlipFlopFlag = !this.submatchFlipFlopFlag

    return retVal
  }

  public activateAdvancedMatchSetRoundsMode (readOnlyFlag: boolean, startingPlayer: boolean, matchIndexNumber: number, matchSet: number, matchId: number, submatchWinAtNumberOfWonSets = 3, matchHomeTeamPlayer: string | null = null, matchHomeTeamPlayer2 = null, matchGuestTeamPlayer: string | null = null, matchGuestTeamPlayer2: string | null = null, matchIsDouble = false, matchTeamNames: string[] = [], matchTeamPlayersNames: string[] = [], matchTeamSecondPlayersNames: string[] = [], selectedMatchId: number, tournamentCategoryId: number) : void {
    console.log('[activateAdvancedMatchSetRoundsEditMode()] DONE.')
    const matchSetArr = [0, 0]
    const playerWhoStartedFirstRoundInLastMatchSet = [false, false]
    let tempStartingPlayer = startingPlayer

    if (matchTeamNames[0].length < 1 || matchTeamNames[1].length < 1) {
      matchTeamNames = []
    }

    for (let matchSetNumber = matchSet; matchSetNumber < this.numberOfSets; matchSetNumber++) {
      const indexOfAwayMatchSetData = this.setResults.findIndex(x => (Number(x.matchIndexNumber) === Number(matchIndexNumber) && Number(x.matchSet) === Number(matchSetNumber) && x.matchHomeOrAwayTeam === false))
      const indexOfHomeMatchSetData = this.setResults.findIndex(x => (Number(x.matchIndexNumber) === Number(matchIndexNumber) && Number(x.matchSet) === Number(matchSetNumber) && x.matchHomeOrAwayTeam === true))

      if (indexOfAwayMatchSetData >= 0) {
        if (this.setResults[indexOfAwayMatchSetData].matchSetDart > 0 && this.setResults[indexOfAwayMatchSetData].matchSetClosed > 0 && this.setResults[indexOfAwayMatchSetData].matchSetRemainScore > 0) {
          matchSetArr[0]++
          playerWhoStartedFirstRoundInLastMatchSet[0] = (this.setResults[indexOfAwayMatchSetData].matchPlayerWhoStartedFirstRound === null ? false : Boolean(!this.setResults[indexOfAwayMatchSetData].matchPlayerWhoStartedFirstRound))
        }
      }
      if (indexOfHomeMatchSetData >= 0) {
        if (this.setResults[indexOfHomeMatchSetData].matchSetDart > 0 && this.setResults[indexOfHomeMatchSetData].matchSetClosed > 0 && this.setResults[indexOfHomeMatchSetData].matchSetRemainScore > 0) {
          matchSetArr[1]++
          playerWhoStartedFirstRoundInLastMatchSet[1] = (this.setResults[indexOfHomeMatchSetData].matchPlayerWhoStartedFirstRound === null ? false : Boolean(this.setResults[indexOfHomeMatchSetData].matchPlayerWhoStartedFirstRound))
        }
      }
    }
    console.log('[activateAdvancedMatchSetRoundsEditMode()] playerWhoStartedFirstRoundInLastMatchSet = ' + JSON.stringify(playerWhoStartedFirstRoundInLastMatchSet))

    // Try guess which player team shall start the the first round in the subsequent match set, when resuming a match, that was previously stoped.
    if (matchSetArr[0] > 0 || matchSetArr[1] > 0) {
      if (startingPlayer) {
        // Home player was the one who started the first match set of the match.
        if (matchSetArr[0] === matchSetArr[1]) {
          if (playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = true
          }
          if (playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = false
          }
          if (!playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = false
          }
          if (!playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = true
          }
        } else {
          if (matchSetArr[1] > matchSetArr[0]) {
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
          } else {
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
          }
        }
      } else {
        // Away player was the one who started the first match set of the match.
        if (matchSetArr[0] === matchSetArr[1]) {
          if (playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = false
          }
          if (!playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = true
          }
          if (playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = true
          }
          if (!playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
            tempStartingPlayer = false
          }
        } else {
          if (matchSetArr[0] > matchSetArr[1]) {
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
          } else {
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
            if (playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = false
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
            if (!playerWhoStartedFirstRoundInLastMatchSet[1] && !playerWhoStartedFirstRoundInLastMatchSet[0]) {
              tempStartingPlayer = true
            }
          }
        }
      }
    }
    // console.log('[activateAdvancedMatchSetRoundsEditMode()] tempStartingPlayer = ' + tempStartingPlayer.toString())
    const homeTeamResult = this.matchPlayed ? (this.matchExecution.kampafvikling_resultat_hjem === null ? 0 : this.matchExecution.kampafvikling_resultat_hjem) : this.cachedTotalResultHome
    const awayTeamResult = this.matchPlayed ? (this.matchExecution.kampafvikling_resultat_ude === null ? 0 : this.matchExecution.kampafvikling_resultat_ude) : this.cachedTotalResultGuest
    this.advancedMatchSetRoundsModeData = { readOnlyFlag: readOnlyFlag, startingPlayer: tempStartingPlayer, matchIndexNumber: matchIndexNumber, matchSet: matchSetArr, matchId: matchId, submatchWinAtNumberOfWonSets: submatchWinAtNumberOfWonSets, matchHomeTeamPlayer: matchHomeTeamPlayer, matchHomeTeamPlayer2: matchHomeTeamPlayer2, matchGuestTeamPlayer: matchGuestTeamPlayer, matchGuestTeamPlayer2: matchGuestTeamPlayer2, matchIsDouble: matchIsDouble, matchTeamNames: matchTeamNames, matchTeamPlayersNames: matchTeamPlayersNames, matchTeamSecondPlayersNames: matchTeamSecondPlayersNames, selectedMatchId: selectedMatchId, homeTeamResult: homeTeamResult, awayTeamResult: awayTeamResult, tournamentCategoryId: tournamentCategoryId }
    this.advancedMatchSetModeModal = true
  }

  // public advancedMatchSetModeModalBeforeClose (e: any) {
  //   console.log('[advancedMatchSetModeModalBeforeClose()] e = ' + JSON.stringify(e))

  //   if (this.advancedMatchSetModeModal === true && window.confirm('Are you sure you want to close the modal?')) {
  //     this.advancedMatchSetModeModal = false
  //     // hide()
  //   }
  // }

  public checkMatchDate (matchSchedule: any, a: any, b: any) {
    // console.log('[checkMatchDate()] a = ' + JSON.stringify(a))
    // console.log('[checkMatchDate()] b = ' + JSON.stringify(b))
    const indexA = matchSchedule.findIndex((el: { id: any }) => Number(el.id) === Number(a.kampafvikling_id.kampprogram_id))
    const indexB = matchSchedule.findIndex((el: { id: any }) => Number(el.id) === Number(b.kampafvikling_id.kampprogram_id))
    // console.log('[checkMatchDate()] indexA = ' + indexA)
    // console.log('[checkMatchDate()] indexB = ' + indexB)

    const qdt = indexA >= 0 ? new Date(matchSchedule[indexA].kampprogram_dato) : new Date('1970-01-01')
    const pdt = indexB >= 0 ? new Date(matchSchedule[indexB].kampprogram_dato) : new Date('1970-01-01')
    // console.log('[checkMatchDate()] qdt = ' + qdt.toISOString())
    // console.log('[checkMatchDate()] pdt = ' + pdt.toISOString())

    return qdt > pdt
  }

  public async checkTeamsLegality (testing = false) {
    if (testing) {
      this.loadingStatusTestTjekLovligeSpillere = true
    } else {
      this.loadingStatusBegyndKampen = true
    }
    // If this is a real mach, do some basic checks to ensure that the user is either a club captain, club manager, admin of the one of the clubs
    if (!testing) {
      let members: SysMember[] = []
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject === null || userDataObject.id === 1) {
        this.$Message.danger({ text: 'Fejl: Noget gik galt' })
        this.loadingStatusTestTjekLovligeSpillere = false
        this.loadingStatusBegyndKampen = false
        return
      }

      try {
        members = (await MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)).data
      } catch (err) {
        console.log(err)
      }

      if (members.length !== 1) {
        this.$Message.danger({ text: 'Fejl: Noget gik galt' })
        this.loadingStatusTestTjekLovligeSpillere = false
        this.loadingStatusBegyndKampen = false
        return
      }

      if (Number(members[0].klubber_id.id) !== this.homeTeamClubId && Number(members[0].klubber_id.id) !== this.awayTeamClubId && !this.havePermission()) {
        this.$Message.danger({ text: 'Fejl Du har ikke tilstrækkelige rettigheder til at gøre det her' })
        this.loadingStatusTestTjekLovligeSpillere = false
        this.loadingStatusBegyndKampen = false
        return
      }
    }

    // This method only contains the checks to detemine if a player is legal
    // First check to see if there is any overlap between home and away team
    const homSet = new Set(this.homeTeamArray.map(({ id }) => id))
    const awaySet = new Set(this.awayTeamArray.map(({ id }) => id))
    const both = new Set([...homSet, ...awaySet])
    if (this.homeTeamArray.length < 1 || this.awayTeamArray.length < 1) {
      this.loadingStatusTestTjekLovligeSpillere = false
      this.loadingStatusBegyndKampen = false
      return this.$Message.warning({ text: 'Create the team first' })
    }
    const overlap = both.size < homSet.size + awaySet.size
    if (overlap) {
      this.loadingStatusTestTjekLovligeSpillere = false
      this.loadingStatusBegyndKampen = false
      return this.$Message.warning({ text: 'Samme medlem på begge hold' })
    }
    this.combinedTeamsArray = []
    this.playersLegalityArray = []

    for (const item of this.homeTeamArray) {
      const member = this.homeAwayMembers.find(el => Number(el.id) === item.id)
      this.combinedTeamsArray.push({
        id: item.id,
        // name: item.name
        name: (member === undefined ? item.name : member.user_id.firstname + ' ' + member.user_id.lastname + ' [' + (member.medlem_licens === null ? '????' : member.medlem_licens) + ']'),
        legal: false,
        homeTeam: true
      })
    }
    for (const item of this.awayTeamArray) {
      const member = this.homeAwayMembers.find(el => Number(el.id) === item.id)
      this.combinedTeamsArray.push({
        id: item.id,
        // name: item.name
        name: (member === undefined ? item.name : member.user_id.firstname + ' ' + member.user_id.lastname + ' [' + (member.medlem_licens === null ? '????' : member.medlem_licens) + ']'),
        legal: false,
        homeTeam: false
      })
    }

    let matchHistoryParam = ''
    const qurantineCutOfDate = new Date(this.match.kampprogram_dato).setMonth(new Date(this.match.kampprogram_dato).getMonth() - 2)
    console.log('[checkTeamsLegality()] qurantineCutOfDate: ' + new Date(qurantineCutOfDate))
    const tempDate = new Date(this.todaysDate).setMonth(new Date(this.todaysDate).getMonth() - 2)
    const historyDateParam = new Date(tempDate).toISOString().split('T')[0]
    console.log('[checkTeamsLegality()] historyDateParam = ' + historyDateParam)
    for (const member of this.combinedTeamsArray) {
      matchHistoryParam += '&medlem_id.id=' + member.id.toString()
    }

    const matchHistoryPromise = MatchHistoryDataService.asyncGetAll('', null, `raekke_id.turneringskategori_id=${this.match.raekke_id.turneringskategori_id}&raekke_id.saeson_id=${this.match.raekke_id.saeson_id}` + matchHistoryParam)
    const teamsInfoPromise = TeamsDataService.asyncGetAll('', null, `klubber_id.id=${this.homeTeamClubId}&klubber_id.id=${this.awayTeamClubId}&saeson_id.id=${this.match.saeson_id.id}`)
    const matchSchedulePromise = Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('kampprogram_dato:asc', null, `raekke_id.id=${this.match.raekke_id.id}&saeson_id.id=${this.match.saeson_id.id}`)
    const matchScheduleAllRowsPromise = Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('kampprogram_dato:asc', null, `saeson_id.id=${this.match.saeson_id.id}`)
    const rowsPromise = Tournaments.TournamentRowsDataService.asyncGetAll('', null, `turneringskategori_id.id=${this.match.raekke_id.turneringskategori_id.toString()}&saeson_id.id=${Number(this.match.saeson_id.id).toString()}`)
    const playerHistoryPromise = History.HistoryDataService.asyncGetAll('', null, matchHistoryParam + `&created_at_gte=${historyDateParam}&medlemshistorik_handling_contains=klubskifte`)
    console.log(this.match)

    await Promise.all([matchHistoryPromise, teamsInfoPromise, matchSchedulePromise, matchScheduleAllRowsPromise, rowsPromise, playerHistoryPromise])
      .then(async (response) => {
        const playerMatchHistory = response[0].data as SysMatchHistory[]
        const teamsInfo = response[1].data as SysTeam[]
        const matchSchedule = response[2].data as SysTournamentMatchSchedule[]
        const matchScheduleAllRows = response[3].data as SysTournamentMatchSchedule[]
        const tournamentRows = response[4].data as SysTournamentRow[]
        const memberHistory = response[5].data as SysHistory[]
        const multipleHomeTeamsInRow = (teamsInfo.filter(el => Number(el?.klubber_id?.id) === this.homeTeamClubId && Number(el?.raekke_id?.id) === Number(this.match?.raekke_id?.id)).length > 1)
        const multipleAwayTeamsInRow = (teamsInfo.filter(el => Number(el?.klubber_id?.id) === this.awayTeamClubId && Number(el?.raekke_id?.id) === Number(this.match?.raekke_id?.id)).length > 1)
        const rowsArray: any[] = []
        let summerStart = ''
        let summerEnd = ''
        const lastEightMatchesHome = matchSchedule.filter(el => Number(el.kampprogram_hjemmehold.id) === Number(this.match.kampprogram_hjemmehold.id) || Number(el.kampprogram_udehold.id) === Number(this.match.kampprogram_hjemmehold.id)).slice(-8)
        const lastEightMatchesAway = matchSchedule.filter(el => Number(el.kampprogram_hjemmehold.id) === Number(this.match.kampprogram_udehold.id) || Number(el.kampprogram_udehold.id) === Number(this.match.kampprogram_udehold.id)).slice(-8)
        const isLastEightHome = lastEightMatchesHome.filter(el => el.kampprogram_kampnr === this.match.kampprogram_kampnr).length > 0
        const isLastEightAway = lastEightMatchesAway.filter(el => el.kampprogram_kampnr === this.match.kampprogram_kampnr).length > 0
        const playersOfHomeTeam: any = {
          teamID: this.match.kampprogram_hjemmehold.id,
          players: []
        }
        const playersOfAwayTeam: any = {
          teamID: this.match.kampprogram_udehold.id,
          players: []
        }
        // Create array of rows, and use recursiceArraySort to add  a level based on the parent child relation of rows
        for (const item of tournamentRows) {
          if (item.parent === null) {
            rowsArray.push({ id: item.id, name: item.raekke_navn })
          } else {
            rowsArray.push({ id: item.id, name: item.raekke_navn, parent_id: item.parent.id })
          }
        }

        const recursiveArraySort = (list: any[], parent = { id: undefined, level: 0 }) => {
          let result: any[] = []
          const children = list.filter(el => el.parent_id === parent.id)
          children.forEach(child => {
            child.level = parent.level + 1
            result = [...result, child, ...recursiveArraySort(list, child)]
          })
          return result
        }
        const rowsArrayWLevels = recursiveArraySort(rowsArray)
        console.log(rowsArray)
        console.log(rowsArrayWLevels)
        // Finding the level of the this match
        const todaysMatchLevel = rowsArrayWLevels[rowsArrayWLevels.findIndex(el => el.id === this.match.raekke_id.id)].level
        console.log(todaysMatchLevel)

        // Loop through matchSchedule to find the break for the summer periode. This is done by finding the largest gap between two matches
        for (let i = 0; i < matchSchedule.length; i++) {
          let highest = 0
          if (i > 0) {
            const start = Date.parse(matchSchedule[i - 1].kampprogram_dato)
            const end = Date.parse(matchSchedule[i].kampprogram_dato)
            const difference = end - start

            if (difference > highest) {
              highest = difference
              summerStart = new Date(matchSchedule[i - 1].kampprogram_dato).toISOString().split('T')[0]
              summerEnd = new Date(matchSchedule[i].kampprogram_dato).toISOString().split('T')[0]
            }
          }
        }

        console.log('[checkTeamsLegality()] summerStart: ' + summerStart)
        console.log('[checkTeamsLegality()] summerEnd; ' + summerEnd)

        for (const item of this.combinedTeamsArray) {
          const memberData = this.homeAwayMembers.find(el => Number(el.id) === item.id)
          let lastMatchLegal = false
          let juniorPlayerLegality = true
          let noClubChangeQuarantine = false
          let lastEightLegality = true
          let seasonFirstMatchLegal = true
          const playerAge = (memberData === undefined ? 0 : BaseFunctions.calculateAge(memberData.medlem_foedselsdag, this.todaysDate))
          console.log('[checkTeamsLegality()] PlayerName: ' + item.name + '\nMemberId: ' + item.id + '\nAge: ' + playerAge)
          const historyArray = playerMatchHistory.filter(el => (Number(el.medlem_id.id) === item.id))
          const historyIndex = playerMatchHistory.findIndex(el => (Number(el.medlem_id.id) === item.id && Number(el.raekke_id.turneringskategori_id) === this.match.raekke_id.turneringskategori_id && Number(el.raekke_id.saeson_id) === Number(this.match.saeson_id.id)))
          let errorMsg = 0
          let isPlayerLegal = false
          let lastPlayedMatch = null

          if (historyArray.length > 0) {
            // Checks if a player have any matches in this season, that is not this match
            // First sort, the array by date, the finde the last element, the find the last match level
            historyArray.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1))
            // console.log('[checkTeamsLegality()] TJEK HISTORY ARRAY: ' + JSON.stringify(historyArray))
            const lastPlayed = historyArray[0]
            lastPlayedMatch = lastPlayed
            const lastPlayedRowId = lastPlayed.raekke_id.id
            const lastPlayedLevel = rowsArrayWLevels[rowsArrayWLevels.findIndex(el => el.id === lastPlayedRowId)].level
            // const lastMatchIndex = matchSchedule.findIndex(el => Number(el.id) === lastPlayed.kampafvikling_id.kampprogram_id)
            const lastMatchIndex = matchScheduleAllRows.findIndex(el => Number(el.id) === lastPlayed.kampafvikling_id.kampprogram_id)
            // const lastPlayedMatchDate = lastMatchIndex !== -1 ? matchSchedule[lastMatchIndex].kampprogram_dato : '1970-01-01'
            const lastPlayedMatchDate = lastMatchIndex !== -1 ? matchScheduleAllRows[lastMatchIndex].kampprogram_dato : '1970-01-01'
            console.log('[checkTeamsLegality()] Last played: ' + lastPlayedMatchDate + ' : ' + item.name)
            // console.log('[checkTeamsLegality()] Last played NEW: ' + JSON.stringify(matchSchedule))
            console.log('[checkTeamsLegality()] Last played NEW: ' + JSON.stringify(matchScheduleAllRows))
            console.log('[checkTeamsLegality()] Last played NEW 2: ' + lastPlayed.kampafvikling_id.kampprogram_id)

            if (todaysMatchLevel < lastPlayedLevel) {
              // if the last match played were in lower series, remember that the higher level, the lower the row. Therefore "1. division" = 1, "Kval H" = 5
              lastMatchLegal = true
              console.log('[checkTeamsLegality()] TJEK 1 :')
            } else if (todaysMatchLevel > lastPlayedLevel) {
              console.log('[checkTeamsLegality()] TJEK 2: ' + JSON.stringify(lastPlayedLevel))
              // if the last match played is of higher series, check that there is a least one match the player haven't played.
              try {
                console.log('[checkTeamsLegality()] TJEK FORRIGE KAMPE 3 match.kampprogram_dato: ' + JSON.stringify(this.match.kampprogram_dato))
                // Make a API call to check if the club have played any matches, in the period from the last played match to todays match. If the response length is greater than 0, means that they have played a match without the player, making him legitimate.
                const prevMatchesResponse = await Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('', null, `_where[_or][0][kampprogram_hjemmehold.klubber_id]=${(item.homeTeam ? this.homeTeamClubId : this.awayTeamClubId)}&_where[_or][1][kampprogram_udehold.klubber_id]=${(item.homeTeam ? this.homeTeamClubId : this.awayTeamClubId)}&_where[_and][2][raekke_id.id]=${lastPlayed.raekke_id.id}&_where[_and][3][kampprogram_dato_gt]=${lastPlayed.created_at}&_where[_and][4][kampprogram_dato_lte]=${this.match.kampprogram_dato}`)
                const previoslyMatches = prevMatchesResponse.data as SysTournamentMatchSchedule[]
                console.log('[checkTeamsLegality()] TJEK FORRIGE KAMPE: ' + JSON.stringify(previoslyMatches))

                if (previoslyMatches.length < 1) {
                  lastMatchLegal = true
                } else {
                  lastMatchLegal = false
                  this.playersLegalityArray.push({ legalString: item.name + ' er muligvis en ulovlig spiller, da sidste kamp var i en højere række', legalBol: false })
                  isPlayerLegal = false
                  errorMsg = 1
                }
              } catch (err) {
                console.log(err)
              }
            } else if (todaysMatchLevel === lastPlayedLevel && (item.homeTeam ? multipleHomeTeamsInRow : multipleAwayTeamsInRow) && ((item.homeTeam ? Number(this.match.kampprogram_hjemmehold.id) : Number(this.match.kampprogram_udehold.id)) !== Number(lastPlayed.hold_id.id))) {
              // If the last played match was in a series at the same level as the current match, and the club have multiple teams in the current series, and the last played team id does not match the players current team id,
              // check that the team of the last match have played a match without the player.
              try {
                console.log(lastPlayed)
                console.log('[checkTeamsLegality()] TJEK FORRIGE KAMPE 3 match.kampprogram_dato: ' + JSON.stringify(this.match.kampprogram_dato))
                const prevMatchesResponse = await Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('', null, `_where[_or][0][kampprogram_hjemmehold.id]=${lastPlayed.hold_id.id}&_where[_or][1][kampprogram_udehold.id]=${lastPlayed.hold_id.id}&_where[_and][2][raekke_id.id]=${lastPlayed.raekke_id.id}&_where[_and][3][kampprogram_dato_gt]=${lastPlayed.created_at}&_where[_and][4][kampprogram_dato_lte]=${this.match.kampprogram_dato}`)
                const previoslyMatches = prevMatchesResponse.data as SysTournamentMatchSchedule[]
                console.log('[checkTeamsLegality()] TJEK 3 :')
                console.log('[checkTeamsLegality()] TJEK FORRIGE KAMPE 3: ' + JSON.stringify(previoslyMatches))

                if (previoslyMatches.length >= 1) {
                  // Length greater than 0, means that the team have played at least 1 macht, without the player, thus making him legitimate.
                  lastMatchLegal = true
                } else {
                  // If this is not the case the players have to manually confirm, that the player i legal before proceeding
                  lastMatchLegal = false
                  this.playersLegalityArray.push({ legalString: item.name + ' er muligvis en ulovlig spiller, da sidste kamp var i en højere række', legalBol: false })
                  isPlayerLegal = false
                  errorMsg = 1
                  // if (window.confirm('Det ser ud til ud til at klubben har flere hold i ' + this.match.raekke_id.raekke_navn + '\n Derfor er det nødvendigt manuelt at bekræfte at ' + item.name + ' må spille i denne kamp\n Klik Ok, hvis spilleren må deltage, ellers klik annuller')) {
                  //   lastMatchLegal = true
                  //   // TODO: Create a note in the kampprogram_kommentar
                  // } else {
                  //   lastMatchLegal = false
                  //   this.playersLegalityArray.push({ legalString: item.name + ' er ikke en lovlig spiller, da sidste kamp var i en højere række', legalBol: false })
                  // }
                }
              } catch (err) {
                console.log(err)
              }
            } else {
              lastMatchLegal = true
            }
          } else {
            lastMatchLegal = true
          }
          if (playerAge < 18 && this.match.raekke_id.turneringskategori_id === 3) {
            // Cheks if the player is under 18, and it is "Hovedturneringen"
            juniorPlayerLegality = false
            const participateInJuniorRow = teamsInfo.filter(el => Number(el.klubber_id.id) === (item.homeTeam ? this.homeTeamClubId : this.awayTeamClubId) && el.raekke_id.turneringskategori_id === 7).length > 0

            // Checks if the players club have a team in the junior tournament
            // const participateInJuniorRow = playerMatchHistory.some(el => Number(el.medlem_id.id) === item.id && Number(el.raekke_id.turneringskategori_id) === 7 && Number(el.raekke_id.saeson_id) === Number(this.match.saeson_id.id))
            if (participateInJuniorRow) {
              juniorPlayerLegality = true
            } else {
              this.playersLegalityArray.push({ legalString: item.name + ' er muligvis ulovlig, da vedkommende er under 18, og klubben ikke deltager i juniorrækken', legalBol: false })
              isPlayerLegal = false
              errorMsg = 2
              // this.$Notification.danger({ title: item.name + ' er ikke lovlig, da vedkommende er juniorspiller, og ikke har spillet i juniorrækken' })
            }
          }
          const clubChangeQuarantineIndex = memberHistory.findIndex(el => Number(el.medlem_id.id) === item.id)
          if (clubChangeQuarantineIndex !== -1 && historyArray.length > 0) {
            console.log(memberHistory[clubChangeQuarantineIndex].created_at)
            historyArray.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1))
            const lastPlayed = historyArray[historyArray.length - 1]
            console.log(lastPlayed)
            const clubChangeDate = new Date(memberHistory[clubChangeQuarantineIndex].created_at).toISOString().split('T')[0]
            const tempDate = new Date(lastPlayed.created_at).setMonth(new Date(lastPlayed.created_at).getMonth() - 2)
            console.log(tempDate)
            const dateCanPlayAgain = new Date(tempDate).toISOString().split('T')[0]
            const seasonStart = new Date(this.match.saeson_id.saeson_start).toISOString().split('T')[0]
            if ((seasonStart >= clubChangeDate) || (clubChangeDate >= summerStart && clubChangeDate <= summerEnd) || (dateCanPlayAgain >= (new Date(this.match.kampprogram_dato)).toISOString().split('T')[0]) || Number(lastPlayed.hold_id.klubber_id) === (item.homeTeam ? Number(this.match.kampprogram_hjemmehold.klubber_id) : Number(this.match.kampprogram_udehold.klubber_id))) {
              noClubChangeQuarantine = true
            } else {
              this.playersLegalityArray.push({ legalString: item.name + ' er muligvis ulovlig, da vedkommende er under klubskifte karantæne', legalBol: false })
              isPlayerLegal = false
              errorMsg = 3
              noClubChangeQuarantine = false
            }
          } else {
            noClubChangeQuarantine = true
          }
          // Checks if it is one of the last eight matches the team
          if ((item.homeTeam ? isLastEightHome : isLastEightAway) && todaysMatchLevel > 1) {
            const multipleTeams = (item.homeTeam ? multipleHomeTeamsInRow : multipleAwayTeamsInRow)
            lastEightLegality = false
            const higherLevelRows: number[] = []

            for (const row of rowsArrayWLevels) {
              if (multipleTeams ? (Number(row.level) <= todaysMatchLevel) : (Number(row.level) < todaysMatchLevel)) {
                higherLevelRows.push(Number(row.id))
              }
            }

            const arrayHigherLevelRowsTeamsData = []
            console.log('Series of higher level: ' + JSON.stringify(higherLevelRows))
            let accumulator = 0
            for (const item of historyArray) {
              if (higherLevelRows.includes(Number(item.raekke_id.id))) {
                arrayHigherLevelRowsTeamsData.push(item.hold_id)
                accumulator++
              }
            }

            // console.log('[checkTeamsLegality()] accumulator = ' + accumulator)
            // console.log('[checkTeamsLegality()] arrayHigherLevelRowsTeamIds = ' + JSON.stringify(arrayHigherLevelRowsTeamIds))

            const checkTeamName = (item.homeTeam ? this.match.kampprogram_hjemmehold.hold_holdnavn : this.match.kampprogram_udehold.hold_holdnavn)

            if (accumulator >= 14 && !multipleTeams) {
              // If the player played the last 14 matches on the same team then it is legitimate, otherwise it most likely is not legitimate.
              if (!arrayHigherLevelRowsTeamsData.slice(0, 14).every(el => el.hold_holdnavn === checkTeamName)) {
                lastEightLegality = false
                this.playersLegalityArray.push({ legalString: item.name + ' er muligvis ulovlig, da det er en af de sidste otte kampe, og vedkommende har spillet mere end 14 kampe på højere rangerende hold', legalBol: false })
                isPlayerLegal = false
                errorMsg = 4
              } else {
                lastEightLegality = true
              }
            } else if (accumulator >= 14 && multipleTeams) {
              // If the player played the last 14 matches on the same team then it is legitimate, otherwise it most likely is not legitimate.
              if (!arrayHigherLevelRowsTeamsData.slice(0, 14).every(el => el.hold_holdnavn === checkTeamName)) {
                lastEightLegality = false
                this.playersLegalityArray.push({ legalString: item.name + ' er muligvis ulovlig, da det er en af de sidste otte kampe, og vedkommende har spillet mere end 14 kampe på højere rangerende hold', legalBol: false })
                isPlayerLegal = false
                errorMsg = 4
                // if (window.confirm('Da klubben har flere hold i ' + this.match.raekke_id.raekke_navn + ', og det er en af de otte sidste kampe.\nDerfor er det nødvendigt manuelt at bekræfte at ' + item.name + 'må spille i denne kamp\n Klik Ok, hvis spilleren må deltage, eller klik annuller')) {
                //   lastEightLegality = true
                //   // TODO: Create a note in the kampprogram_kommentar
                // } else {
                //   lastEightLegality = false
                //   this.playersLegalityArray.push({ legalString: item.name + ' er ikke lovlig, da det er en af de sidste otte kampe, og vedkommende har spillet mere end 14 kampe på højere rangerende hold', legalBol: false })
                // }
              } else {
                lastEightLegality = true
              }
            } else {
              lastEightLegality = true
            }
            console.log('[checkTeamsLegality()] Match is one of the last 8 matches for the the home team')
          }

          // console.log('[checkTeamsLegality()] matchSchedule = ' + JSON.stringify(matchSchedule))
          // console.log('[checkTeamsLegality()] historyArray = ' + JSON.stringify(historyArray))

          // If it is the first match of the season, checks that the player have not played in any other matches
          if (historyArray.length > 0) {
            historyArray.sort((a, b) => (this.checkMatchDate(matchScheduleAllRows, a, b) ? -1 : 1))
            // historyArray.sort((a, b) => (new Date(matchSchedule[matchSchedule.findIndex(el => Number(el.id) === Number(a.kampafvikling_id.kampprogram_id))].kampprogram_dato) > new Date(matchSchedule[matchSchedule.findIndex(el => Number(el.id) === Number(b.kampafvikling_id.kampprogram_id))].kampprogram_dato) ? -1 : 1))
            const lastPlayed = historyArray[historyArray.length - 1]
            const lastMatchIndex = matchScheduleAllRows.findIndex(el => Number(el.id) === lastPlayed.kampafvikling_id.kampprogram_id)
            console.log(historyArray)
            const schedule = matchScheduleAllRows.filter(el => Number(el.kampprogram_hjemmehold.id) === (item.homeTeam ? Number(this.match.kampprogram_hjemmehold.id) : Number(this.match.kampprogram_udehold.id)) || Number(el.kampprogram_udehold.id) === (item.homeTeam ? Number(this.match.kampprogram_hjemmehold.id) : Number(this.match.kampprogram_udehold.id))).sort((a, b) => a.kampprogram_dato > b.kampprogram_dato ? 1 : -1)
            // const schedule = matchSchedule.filter(el => (item.homeTeam ? Number(el.kampprogram_hjemmehold.id) : Number(el.kampprogram_udehold.id)) === (item.homeTeam ? Number(this.match.kampprogram_hjemmehold.id) : Number(this.match.kampprogram_udehold.id))).sort((a, b) => a.kampprogram_dato > b.kampprogram_dato ? 1 : -1)
            const scheduleIndex = schedule.findIndex(el => el.id === this.match.id)

            if (scheduleIndex === 0 && historyArray.length > 0 && Number(this.match.id) !== Number(matchScheduleAllRows[lastMatchIndex].id)) {
              seasonFirstMatchLegal = false
              this.playersLegalityArray.push({ legalString: item.name + ' er muligvis ulovlig, da det er første kamprunde, og vedkommende har allerede spillet en kamp i denne turnering', legalBol: false })
              isPlayerLegal = false
              errorMsg = 5
            } else {
              seasonFirstMatchLegal = true
            }
          } else {
            seasonFirstMatchLegal = true
          }

          if (lastMatchLegal && juniorPlayerLegality && noClubChangeQuarantine && lastEightLegality && seasonFirstMatchLegal) {
            item.legal = true
            this.playersLegalityArray.push({ legalString: item.name + ' er lovlig', legalBol: true })
            isPlayerLegal = true
            errorMsg = 0
          }
          if (!this.playerLegalityModal) {
            this.loadingStatusTestTjekLovligeSpillere = false
            this.loadingStatusBegyndKampen = false
            this.playerLegalityModal = true
          }
          const checker = this.combinedTeamsArray.every(el => el.legal)
          if (checker) {
            this.playerLegalityHeadline = 'Alle spillere er lovlige'
            if (!testing) {
              this.allPlayersLegal = true
              // this.reMapPlayers()
            }
          } else {
            this.playerLegalityHeadline = 'Alle spillere er muligvis ikke lovlige'
            this.allPlayersLegal = true // Temp fix

            if (!testing) {
              this.finishedSettingHomeTeam = false
              this.finishedSettingAwayTeam = false
            }
          }
          if (!isPlayerLegal && errorMsg !== 0) {
            const previousMatchId = lastPlayedMatch ? lastPlayedMatch?.kampafvikling_id?.kampprogram_id : lastPlayedMatch
            const previousMatchTeam = lastPlayedMatch ? lastPlayedMatch?.hold_id?.hold_holdnavn : lastPlayedMatch
            if (item.homeTeam) {
              playersOfHomeTeam.players.push({ playerName: item.name, info: errorMsg, previousMatchId: previousMatchId, previousMatchTeam: previousMatchTeam })
            } else {
              playersOfAwayTeam.players.push({ playerName: item.name, info: errorMsg, previousMatchId: previousMatchId, previousMatchTeam: previousMatchTeam })
            }
          }
        }
        const validatedPlayersHistorieParams = {
          matchID: this.match.id,
          home: playersOfHomeTeam,
          away: playersOfAwayTeam
        }
        if (this.combinedTeamsArray.length > 0 && (validatedPlayersHistorieParams.away.players.length > 0 || validatedPlayersHistorieParams.home.players.length > 0)) {
          History.ValidatedPlayersHistoriesDataService.create(validatedPlayersHistorieParams)
            .then((response) => {
              console.log('RESPONSE', response)
            })
            .catch((error) => {
              console.log('ERROR', error)
            })
        }
      })
      .catch((err) => {
        console.error(err)
        console.log(err)
      })
  }

  public async reMapPlayers () : Promise<void> {
    this.loadingStatusBegyndkamp = true
    this.setupMatchSettlementTeamPlayersExtDataCompletede = false
    this.finishedSettingAwayTeam = true
    this.finishedSettingHomeTeam = true
    this.matchSettlementTeamPlayersExtDataUpdateIds = []
    this.matchSettlementTeamPlayersExtDataUpdateProgress = 0
    this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark = Math.min(this.homeTeamArray.length, this.awayTeamArray.length)
    this.matchSettlementTeamPlayersExtDataUpdateStarted = true
    let usePlayerRemapping = false
    let arrOffset = 0
    let isPlayerSelected = false
    // const oldBoyFlag = (this.match.raekke_id.raekke_navn.toLowerCase().includes('old') && this.match.raekke_id.raekke_navn.toLowerCase().includes('boy'))
    const homeTeamArrayRemapped: { name: string, id: number; selectFlag: boolean }[] = []
    const awayTeamArrayRemapped: { name: string, id: number; selectFlag: boolean }[] = []
    console.log('Remapprogress = ' + this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark)

    // Check remapping is needed, handles the case where one team has more players than other.
    if (this.matchRules.maxPlayers === 8 && (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark >= 5 || (this.oldBoyFlag && this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark >= 3)) && this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark <= 8) {
      usePlayerRemapping = true

      // Generate remapped match player selection data
      for (let arrIndex = 0; arrIndex < this.matchRules.maxPlayers; arrIndex++) {
        homeTeamArrayRemapped[arrIndex] = { name: '', id: 0, selectFlag: false }
        awayTeamArrayRemapped[arrIndex] = { name: '', id: 0, selectFlag: false }

        if (arrIndex < 4) {
          // The first four items are identical to non-remapped situation.
          console.log('arrIndex: ' + arrIndex.toString())
          if (this.oldBoyFlag && arrIndex === 3 && this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark === 3) {
            // Handle special case with Old Boys tournament rows, where it is possible that a team has only 3 players selected. This is for the remapping the single match.
            if (this.homeTeamArray.length >= 4) {
              homeTeamArrayRemapped[arrIndex] = { name: this.homeTeamArray[arrIndex].name, id: this.homeTeamArray[arrIndex].id, selectFlag: true }
            } else {
              // Map player index to zero for unselected player, that should have been playing that single match.
              homeTeamArrayRemapped[arrIndex] = { name: this.homeTeamArray[0].name, id: this.homeTeamArray[0].id, selectFlag: false }
            }
            if (this.awayTeamArray.length >= 4) {
              awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[arrIndex].name, id: this.awayTeamArray[arrIndex].id, selectFlag: true }
            } else {
              // Map player index to zero for unselected player, that should have been playing that single match.
              awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[0].name, id: this.awayTeamArray[0].id, selectFlag: false }
            }
          } else {
            homeTeamArrayRemapped[arrIndex] = { name: this.homeTeamArray[arrIndex].name, id: this.homeTeamArray[arrIndex].id, selectFlag: true }
            awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[arrIndex].name, id: this.awayTeamArray[arrIndex].id, selectFlag: true }
          }
        } else {
          isPlayerSelected = false

          // Do some real remapping of players for both teams
          if (this.homeTeamArray[arrIndex] === undefined) {
            // Map array index data only if not part of the array bottom (either index 7, 6 - 7 or 5 - 7) for the home team.
            if (this.oldBoyFlag && arrIndex <= this.matchRules.maxPlayers - 1) {
              if (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark === 3) {
                switch (arrIndex) {
                  case 4:
                    arrOffset = 0
                    isPlayerSelected = true
                    break
                  case 5:
                    arrOffset = 2
                    isPlayerSelected = true
                    break
                  case 6:
                    arrOffset = 1
                    isPlayerSelected = true
                    break
                  case 7:
                    arrIndex = (this.homeTeamArray.length <= this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark ? 0 : 3)
                    isPlayerSelected = !(this.homeTeamArray.length <= this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark)
                    break
                  default:
                    arrOffset = arrIndex
                }
              } else if (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark === 4) {
                switch (arrIndex) {
                  case 4:
                    arrOffset = 0
                    isPlayerSelected = true
                    break
                  case 5:
                    arrOffset = 3
                    isPlayerSelected = true
                    break
                  case 6:
                    arrOffset = 1
                    isPlayerSelected = true
                    break
                  case 7:
                    arrOffset = 2
                    isPlayerSelected = true
                    break
                  default:
                    arrOffset = arrIndex
                }
              } else if (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark >= 5) {
                isPlayerSelected = true
                arrOffset = this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark + 2 - arrIndex
              }
              console.log('OB Home team player arrIndex = ' + arrIndex.toString() + ' / Remapped index for home team players to = ' + arrOffset.toString())
              homeTeamArrayRemapped[arrIndex] = { name: this.homeTeamArray[arrOffset].name, id: this.homeTeamArray[arrOffset].id, selectFlag: isPlayerSelected }
            } else {
              console.log('Home team player arrIndex = ' + arrIndex.toString() + ' / Remapped index for home team players to = ' + (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark + 2 - arrIndex).toString())
              homeTeamArrayRemapped[arrIndex] = { name: this.homeTeamArray[this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark + 2 - arrIndex].name, id: this.homeTeamArray[this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark + 2 - arrIndex].id, selectFlag: true }
            }
          } else {
            // Map array index data 4 - 6 to array bottom (either index 7, 6 - 7 or 5 - 7) for the guest team.
            console.log('GENERAL Home team player arrIndex = ' + arrIndex.toString())
            homeTeamArrayRemapped[arrIndex] = { name: this.homeTeamArray[arrIndex].name, id: this.homeTeamArray[arrIndex].id, selectFlag: true }
          }
          isPlayerSelected = false

          if (this.awayTeamArray[arrIndex] === undefined) {
            // Map array index data only if not part of the array bottom (either index 7, 6 - 7 or 5 - 7) for the guest team.
            if (this.oldBoyFlag && arrIndex <= this.matchRules.maxPlayers - 1) {
              if (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark === 3) {
                switch (arrIndex) {
                  case 4:
                    arrOffset = 2
                    isPlayerSelected = true
                    break
                  case 5:
                    arrOffset = 1
                    isPlayerSelected = true
                    break
                  case 6:
                    arrOffset = 0
                    isPlayerSelected = true
                    break
                  case 7:
                    arrOffset = (this.awayTeamArray.length <= this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark ? 0 : 3)
                    isPlayerSelected = !(this.awayTeamArray.length <= this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark)
                    break
                  default:
                    arrOffset = arrIndex
                }
              } else if (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark === 4) {
                switch (arrIndex) {
                  case 4:
                    arrOffset = 3
                    isPlayerSelected = true
                    break
                  case 5:
                    arrOffset = 1
                    isPlayerSelected = true
                    break
                  case 6:
                    arrOffset = 0
                    isPlayerSelected = true
                    break
                  case 7:
                    arrOffset = 2
                    isPlayerSelected = true
                    break
                  default:
                    arrOffset = arrIndex
                }
              } else if (this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark >= 5) {
                isPlayerSelected = true
                arrOffset = (arrIndex === this.matchRules.maxPlayers - 1) ? this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark - 1 : 3 - ((arrIndex + 1) & 3)
              }
              console.log('OB Guest team player arrIndex = ' + arrIndex.toString() + ' / Remapped index for guest team players to = ' + arrOffset.toString())
              awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[arrOffset].name, id: this.awayTeamArray[arrOffset].id, selectFlag: isPlayerSelected }
            } else {
              if (arrIndex < this.matchRules.maxPlayers - 1) {
                console.log('Guest team player arrIndex = ' + arrIndex.toString() + ' / Remapped index for guest team players to = ' + (3 - ((arrIndex + 1) & 3)).toString())
                awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[3 - ((arrIndex + 1) & 3)].name, id: this.awayTeamArray[3 - ((arrIndex + 1) & 3)].id, selectFlag: true }
              }
            }
          } else {
            // Map array index 4 - 6 data to the array bottom (with index 7 being mapped to data of the highest selected player index in the array) for the guest team.
            if (arrIndex === this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark - 1) {
              console.log('GENERAL Guest team player arrIndex = ' + arrIndex.toString() + ' / Remapped index for guest team players to = ' + (3 - ((arrIndex + 1) & 3)).toString())
              awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[3 - ((arrIndex + 1) & 3)].name, id: this.awayTeamArray[3 - ((arrIndex + 1) & 3)].id, selectFlag: true }
            } else {
              console.log('GENERAL Guest team player arrIndex = ' + arrIndex.toString() + ' / Remapped index for guest team players to = ' + arrIndex.toString())
              awayTeamArrayRemapped[arrIndex] = { name: this.awayTeamArray[arrIndex].name, id: this.awayTeamArray[arrIndex].id, selectFlag: true }
            }
          }
          console.log('HomeTeamPlayersNameValueRemapped[' + arrIndex + '] = ' + homeTeamArrayRemapped[arrIndex].name + ' / ' + homeTeamArrayRemapped[arrIndex].id)
          console.log('GuestTeamPlayersNameValueRemapped[' + arrIndex + '] = ' + awayTeamArrayRemapped[arrIndex].name + ' / ' + awayTeamArrayRemapped[arrIndex].id)
        }
      }
      // Adjust progress watermark to be equal to the maximum number of possible players.
      this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark = this.matchRules.maxPlayers
      console.log('TeamPlayersExtDataUpdateProgressWatermark after remap = ' + this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark)
    }

    const promises = []
    for (let arrIndex = 0; arrIndex < this.matchSettlementTeamPlayersExtDataUpdateProgressWatermark; arrIndex++) {
      console.log('Players : ' + (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].id : this.homeTeamArray[arrIndex].id) + ' / ' + (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].id : this.awayTeamArray[arrIndex].id))

      // Search match settlement extra data with the kampafvikling_spiller_indeks equal to the arrIndex. If it already exists then update its member Ids for the home and guest players. Otherwise create a new item with the relevant data.
      const indexOfExtData = this.matchExecution.kampafvikling_ekstras_id.findIndex(el => Number(el.kampafvikling_spiller_indeks) === (arrIndex + 1))
      console.log(this.matchExecution)
      console.log(indexOfExtData)

      if (indexOfExtData === -1) {
        try {
          // const response = await Tournaments.TournamentMatchSettlementExtDataService.asyncGetAll('', null, `kampafvikling_spiller_indeks=${(arrIndex + 1).toString()}&kampafvikling_spiller_hj=${(usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].id : this.homeTeamArray[arrIndex].id)}&kampafvikling_spiller_ud=${(usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].id : this.awayTeamArray[arrIndex].id)}`)
          // const tempMatchSettlementExtData = response.data as SysTournamentMatchSettlementExtId[]
          // console.log(response.data)

          // if (tempMatchSettlementExtData !== null && tempMatchSettlementExtData.length >= 1 && tempMatchSettlementExtData[0].id !== null) {
          //   // Reuse already existing match settlement extra item instead of creating a new item.
          //   const updateTournamentMatchSettlementExtData = {
          //     kampafvikling_spiller_hj: (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].id : this.homeTeamArray[arrIndex].id),
          //     kampafvikling_spiller_ud: (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].id : this.awayTeamArray[arrIndex].id),
          //     kampafvikling_spiller_hj_valgt: (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].selectFlag : true),
          //     kampafvikling_spiller_ud_valgt: (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].selectFlag : true)
          //   }

          //   console.log('Updating an existing match settlement extra data item: ' + JSON.stringify(updateTournamentMatchSettlementExtData))
          //   const updateExtDataPromise = Tournaments.TournamentMatchSettlementExtDataService.update(Number(tempMatchSettlementExtData[0].id).toString(), updateTournamentMatchSettlementExtData)
          //   promises.push(updateExtDataPromise)
          // } else {
          // Create match settlement extra data with the kampafvikling_spiller_indeks equal to the arrIndex.
          const createTournamentMatchSettlementExtData = {
            kampafvikling_spiller_indeks: (arrIndex + 1),
            kampafvikling_spiller_hj: (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].id : this.homeTeamArray[arrIndex].id),
            kampafvikling_spiller_ud: (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].id : this.awayTeamArray[arrIndex].id),
            kampafvikling_spiller_hj_valgt: (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].selectFlag : true),
            kampafvikling_spiller_ud_valgt: (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].selectFlag : true)
          }

          console.log('Creating a new match settlement extra data item: ' + JSON.stringify(createTournamentMatchSettlementExtData))
          const createExtDataPromise = Tournaments.TournamentMatchSettlementExtDataService.create(createTournamentMatchSettlementExtData)
          promises.push(createExtDataPromise)
          // }
        } catch (err) {
          console.log(err)
        }
      } else {
        // Add the id of the already existing match settlement extra data to the Id array.
        const tempExtId = Number(this.matchExecution.kampafvikling_ekstras_id[indexOfExtData].id)
        // this.matchSettlementTeamPlayersExtDataUpdateIds.push(tempExtId)
        const updateExtData = {
          kampafvikling_spiller_hj: (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].id.toString() : this.homeTeamArray[arrIndex].id.toString()),
          kampafvikling_spiller_ud: (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].id.toString() : this.awayTeamArray[arrIndex].id.toString()),
          kampafvikling_spiller_hj_valgt: (usePlayerRemapping ? homeTeamArrayRemapped[arrIndex].selectFlag : true),
          kampafvikling_spiller_ud_valgt: (usePlayerRemapping ? awayTeamArrayRemapped[arrIndex].selectFlag : true)
        }

        console.log('Updating an existing match settlement extra data item: ' + JSON.stringify(updateExtData))
        const updateExtDataPromise = Tournaments.TournamentMatchSettlementExtDataService.update(tempExtId.toString(), updateExtData)
        promises.push(updateExtDataPromise)
      }
    }

    // Either update an existing match settlement extra data via API access for the matching Id or create a new one.
    await Promise.all(promises)
      .then((response) => {
        for (const item of response) {
          this.matchSettlementTeamPlayersExtDataUpdateIds.push(Number(item.data.id))
          this.matchSettlementTeamPlayersExtDataUpdateProgress++
        }
        console.log(this.matchSettlementTeamPlayersExtDataUpdateIds)
        const matchId = Number(this.matchExecution.id).toString()
        const updateMatchData = {
          kampafvikling_ekstras_id: this.matchSettlementTeamPlayersExtDataUpdateIds
        }
        Tournaments.TournamentMatchSettlementDataService.update(matchId, updateMatchData)
          .then((response) => {
            this.matchExecution = response.data
            this.finishedSettingAwayTeam = true
            this.finishedSettingHomeTeam = true
            this.matchInitRefreshDelayFlag = true // Indicate that a special delay and refresh should be used.
            this.startMatch()
          })
          .catch((err) => {
            console.log(err)
          })
          // .finally(() => {
          //   this.setupMatchSettlementTeamPlayersExtDataCompletede = true
          // })
      })
      .catch((err) => {
        console.log(err)
        this.setupMatchSettlementTeamPlayersExtDataCompletede = true
      })
      .finally(() => {
        this.loadingStatusBegyndkamp = false
      })
  }

  public addPlayerToTeam () : void {
    if ((this.settingAwayTeam ? this.awayTeamArray.length : this.homeTeamArray.length) === Math.max(this.matchRules.minPlayers, this.matchRules.maxPlayers)) {
      return this.$Message.warning({ text: 'Alle hold medlemerne valgt' })
    }

    if (this.settingAwayTeam && this.playerValue.id !== 0) {
      const index = this.awayTeamArray.findIndex(el => el.id === this.playerValue.id)

      if (index === -1) {
        this.awayTeamArray.push({ name: this.playerValue.name, id: this.playerValue.id })
      } else {
        this.$Message.warning({ text: 'Spiller allerede tilføjet' })
      }
    }

    if (!this.settingAwayTeam && this.playerValue.id !== 0) {
      const index = this.homeTeamArray.findIndex(el => el.id === this.playerValue.id)

      if (index === -1) {
        this.homeTeamArray.push({ name: this.playerValue.name, id: this.playerValue.id })
      } else {
        this.$Message.warning({ text: 'Spiller allerede tilføjet' })
      }
    }

    this.playerValue = { name: 'Vælg spiller', id: 0 }
  }

  public movePlayerPosition (awayTeam: boolean, oldIndex: number, moveBy: number) : void {
    const newIndex = oldIndex + moveBy

    if (awayTeam) {
      const element = this.awayTeamArray[oldIndex]
      this.awayTeamArray.splice(oldIndex, 1)
      this.awayTeamArray.splice(newIndex, 0, element)
    } else {
      const element = this.homeTeamArray[oldIndex]
      this.homeTeamArray.splice(oldIndex, 1)
      this.homeTeamArray.splice(newIndex, 0, element)
    }
  }

  public removePlayer (awayTeam: boolean, playerIndex: number) : void {
    if (awayTeam) {
      this.awayTeamArray.splice(playerIndex, 1)
    } else {
      this.homeTeamArray.splice(playerIndex, 1)
    }
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const matchId = route.params.matchId

    if (Number(matchId) !== undefined) {
      retVal = Number(matchId)
    }
    return retVal
  }

  public async retrieveMatch () : Promise<void> {
    try {
      const matchResponse = await Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('', null, `id=${this.matchId}`)
      this.match = matchResponse.data[0] as SysTournamentMatchSchedule
      console.log('MATCH: ' + JSON.stringify(this.match))

      if (this.match !== undefined) {
        this.homeTeamClubId = this.match.kampprogram_hjemmehold.klubber_id
        this.awayTeamClubId = this.match.kampprogram_udehold.klubber_id
        this.oldBoyFlag = (this.match.raekke_id.raekke_navn.toLowerCase().includes('old') && this.match.raekke_id.raekke_navn.toLowerCase().includes('boy'))
        this.matchPlayed = (this.match.kampgodkendt_id !== null && this.match.kampgodkendt_id.kampgodkendt_godkendt)
        this.matchRules = { minPlayers: this.match.raekke_id.raekke_antalspillere, maxPlayers: this.match.raekke_id.raekke_maxspillere, sets: this.match.raekke_id.raekke_antalsaet }
        console.log('Match played: ' + this.matchPlayed)
        let ageParameter = ''
        let genderParameter = ''
        let paradartParameter = ''
        let isMatchFromParadart = false

        // Check for Paradart tournament type.
        if (this.match.raekke_id.raekke_navn.toLowerCase().includes('paradart')) {
          paradartParameter = '&medlem_paradart=true'
          isMatchFromParadart = true
        }
        if (this.match.raekke_id.raekke_navn.toLowerCase().includes('damepairs')) {
          genderParameter = '&koen_id.id=1'
        }
        if (this.match.raekke_id.raekke_navn.toLowerCase().includes('oldboys')) {
          const ageLimit = this.match.raekke_id.raekke_navn.replaceAll(/[^0-9]/g, '')
          console.log('OldboysAgeLimit =' + ageLimit)

          if (ageLimit.trim().startsWith('35')) {
            ageParameter = '&medlem_foedselsdag_lte=' + this.subtrackYears(35)
          } else {
            ageParameter = '&medlem_foedselsdag_lte=' + this.subtrackYears(50)
          }
        }
        if (this.match.raekke_id.raekke_navn.toLowerCase().includes('junior')) {
          ageParameter = '&medlem_foedselsdag_gt=' + this.subtrackYears(19)
        }

        // Initialize the variable for the number of matches in this match execution.
        if (this.matchRules.minPlayers === 1) {
          this.numberOfMatches = 1
        } else if (this.matchRules.minPlayers === 2) {
          this.numberOfMatches = 5
        } else if (this.oldBoyFlag) {
          if (this.matchRules.minPlayers >= 3 && this.matchRules.maxPlayers <= 8) {
            console.log('Old Boys')
            this.numberOfMatches = 4 + 2 // Four singles matches plus 2 double matches
          }
        } else {
          if (this.matchRules.minPlayers === 3) {
            this.numberOfMatches = 9
          }
          if (this.matchRules.minPlayers === 4) {
            this.numberOfMatches = 16
          } else if (this.matchRules.minPlayers >= 5 && this.matchRules.maxPlayers <= 8) {
            this.numberOfMatches = 4 + 2 // Four single matches plus 2 double matches.
          }
        }
        // if (this.match.raekke_id.raekke_navn.toLowerCase().includes('junior')) {
        //   this.numberOfMatches = (this.match.raekke_id.raekke_antalsaet === null ? 1 : this.match.raekke_id.raekke_antalsaet)
        // }

        const todaysDatePromise = BaseFunctions.getDatetimeFromServer()
        let memberPromise
        if (isMatchFromParadart) {
          memberPromise = MembersDataService.asyncGetAll('', null, `medlem_licens_slut_gte=${this.todaysDate}${ageParameter}${paradartParameter}`)
        } else {
          memberPromise = MembersDataService.asyncGetAll('', null, `klubber_id.id=${this.homeTeamClubId}&klubber_id.id=${this.awayTeamClubId}&medlem_licens_slut_gte=${this.todaysDate}${ageParameter}${genderParameter}`)
        }
        // const memberPromise = MembersDataService.asyncGetAll('', null, `klubber_id.id=${this.homeTeamClubId}&klubber_id.id=${this.awayTeamClubId}`)
        const matchSettlementPromise = Tournaments.TournamentMatchSettlementDataService.asyncGetAll('', null, `kampprogram_id.id=${this.match.id}`)
        await Promise.all([todaysDatePromise, memberPromise, matchSettlementPromise])
          .then(async (response) => {
            this.todaysDate = new Date(response[0].data).toISOString().split('T')[0]
            this.homeAwayMembers = response[1].data as SysMember[]
            const tempMatchSettlement = response[2].data as SysTournamentMatchSettlement[]
            this.homeTeamOptions = []
            this.awayTeamOptions = []
            // Filter away any member who quarantine
            // this.homeAwayMembers = this.homeAwayMembers.filter(el => el.medlem_karantaene_slutdato === null || el.medlem_karantaene_slutdato < this.todaysDate)
            // console.log(this.homeAwayMembers)

            for (const item of this.homeAwayMembers) {
              if ((item.medlem_karantaene_slutdato === null || item.medlem_karantaene_slutdato < this.todaysDate) && isMatchFromParadart) {
                this.homeTeamOptions.push({
                  id: Number(item.id),
                  name: item.user_id.firstname + ' ' + item.user_id.lastname + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
                })

                this.awayTeamOptions.push({
                  id: Number(item.id),
                  name: item.user_id.firstname + ' ' + item.user_id.lastname + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
                })
              }
              if (item.klubber_id !== null && (Number(item.klubber_id.id) === this.homeTeamClubId) && (item.medlem_karantaene_slutdato === null || item.medlem_karantaene_slutdato < this.todaysDate) && !isMatchFromParadart) {
                this.homeTeamOptions.push({
                  id: Number(item.id),
                  name: item.user_id.firstname + ' ' + item.user_id.lastname + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
                })
              }
              if (item.klubber_id !== null && (Number(item.klubber_id.id) === this.awayTeamClubId) && (item.medlem_karantaene_slutdato === null || item.medlem_karantaene_slutdato < this.todaysDate) && !isMatchFromParadart) {
                this.awayTeamOptions.push({
                  id: Number(item.id),
                  name: item.user_id.firstname + ' ' + item.user_id.lastname + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
                })
              }
            }

            if (this.spectatorMode === false && (tempMatchSettlement === undefined || tempMatchSettlement.length === 0)) {
              const createMatchSettlement = {
                kampprogram_id: Number(this.match.id).toString(),
                kampafvikling_resultat_hjem: 0,
                kampafvikling_resultat_ude: 0
              }

              Tournaments.TournamentMatchSettlementDataService.create(createMatchSettlement)
                .then((response) => {
                  this.matchExecution = response.data
                })
                .catch((err) => {
                  console.error(err)
                })
            } else {
              if (tempMatchSettlement !== undefined && tempMatchSettlement.length >= 1 && tempMatchSettlement[0] !== undefined) {
                this.matchExecution = tempMatchSettlement[0]
              }

              if (this.matchExecution !== undefined && this.matchExecution.kampafvikling_ekstras_id !== undefined && this.matchExecution.kampafvikling_ekstras_id !== null && this.matchExecution.kampafvikling_ekstras_id.length > 0) {
                let memberParam = ''

                for (const matchExtra of this.matchExecution.kampafvikling_ekstras_id) {
                  console.log(matchExtra)
                  const homeIndex = this.homeAwayMembers.findIndex(el => Number(el.id) === matchExtra.kampafvikling_spiller_hj)
                  const awayIndex = this.homeAwayMembers.findIndex(el => Number(el.id) === matchExtra.kampafvikling_spiller_ud)

                  if (homeIndex === -1) {
                    memberParam += '&id=' + matchExtra.kampafvikling_spiller_hj.toString()
                    this.homeTeamArray.push({
                      id: matchExtra.kampafvikling_spiller_hj,
                      name: ''
                    })
                  } else {
                    this.homeTeamArray.push({
                      id: matchExtra.kampafvikling_spiller_hj,
                      name: this.getPlayerName(matchExtra.kampafvikling_spiller_hj)
                    })
                  }

                  if (awayIndex === -1) {
                    memberParam += '&id=' + matchExtra.kampafvikling_spiller_ud.toString()
                    this.awayTeamArray.push({
                      id: matchExtra.kampafvikling_spiller_ud,
                      name: ''
                    })
                  } else {
                    this.awayTeamArray.push({
                      id: matchExtra.kampafvikling_spiller_ud,
                      name: this.getPlayerName(matchExtra.kampafvikling_spiller_ud)
                    })
                  }
                }
                if (memberParam.length > 0) {
                  memberParam = memberParam.slice(1)

                  try {
                    const memberResponse = await MembersDataService.asyncGetAll('', null, memberParam)
                    const tempMembers = memberResponse.data as SysMember[]
                    Array.prototype.push.apply(this.homeAwayMembers, tempMembers)

                    for (const item of this.homeTeamArray) {
                      if (item.name === '') {
                        const homeIndex = this.homeAwayMembers.findIndex(el => Number(el.id) === item.id)
                        if (homeIndex !== -1) {
                          console.log(this.homeAwayMembers[homeIndex].user_id.firstname + ' ' + this.homeAwayMembers[homeIndex].user_id.lastname)
                          item.name = this.getPlayerName(item.id)
                        }
                      }
                    }
                    for (const item of this.awayTeamArray) {
                      if (item.name === '') {
                        const awayIndex = this.homeAwayMembers.findIndex(el => Number(el.id) === item.id)
                        if (awayIndex !== -1) {
                          item.name = this.getPlayerName(item.id)
                        }
                      }
                    }
                  } catch (err) {
                    console.log(err)
                  }
                }

                this.finishedSettingAwayTeam = true
                this.finishedSettingHomeTeam = true
                // this.reMapPlayers()
                this.startMatch()
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    } catch (err) {
      console.log(err)
    }
  }

  public subtrackYears (numOfYears: number) : string {
    const dateObj = new Date(this.todaysDate)
    dateObj.setMonth(11)
    dateObj.setDate(31)
    const dateMinusXYears = dateObj.setFullYear(dateObj.getFullYear() - numOfYears)
    console.log(new Date(dateMinusXYears).toISOString().split('T')[0])
    return new Date(dateMinusXYears).toISOString().split('T')[0]
  }

  public getPlayerName (playerId: number) : string {
    let retVal = '???'
    const index = this.homeAwayMembers.findIndex(el => Number(el.id) === playerId)
    if (index !== -1) {
      const tempPlayer = this.homeAwayMembers[index]
      retVal = tempPlayer.user_id.firstname + ' ' + tempPlayer.user_id.lastname + ' [' + (tempPlayer.medlem_licens === null ? '????' : tempPlayer.medlem_licens) + ']'
    }
    return retVal
  }

  // public retrieveMembers () : void {
  //   MembersDataService.getAll('', null, `klubber_id.id=${this.homeTeamClubId}&klubber_id.id=${this.awayTeamClubId}&medlem_licens_slut_gte=${this.todaysDate}`)
  //     .then((response) => {
  //       this.homeAwayMembers = response.data
  //       console.log(this.homeAwayMembers)
  //       for (const item of this.homeAwayMembers) {
  //         if (Number(item.klubber_id.id) === this.homeTeamClubId) {
  //           this.homeTeamOptions.push({
  //             id: Number(item.id),
  //             name: item.user_id.bruger_fornavn + ' ' + item.user_id.bruger_efternavn + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
  //           })
  //         } else {
  //           this.awayTeamOptions.push({
  //             id: Number(item.id),
  //             name: item.user_id.bruger_fornavn + ' ' + item.user_id.bruger_efternavn + ' [' + (item.medlem_licens === null ? '????' : item.medlem_licens) + ']'
  //           })
  //         }
  //       }
  //       if (this.matchExecution.kampafvikling_ekstras_id !== undefined && this.matchExecution.kampafvikling_ekstras_id.length > 0) {
  //         for (const item of this.matchExecution.kampafvikling_ekstras_id) {
  //           this.homeTeamArray.push({
  //             name: this.getPlayerName(item.kampafvikling_spiller_hj),
  //             id: item.kampafvikling_spiller_hj
  //           })
  //           this.awayTeamArray.push({
  //             name: this.getPlayerName(item.kampafvikling_spiller_ud),
  //             id: item.kampafvikling_spiller_ud
  //           })
  //         }
  //         this.finishedSettingAwayTeam = true
  //         this.finishedSettingHomeTeam = true
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //     })
  // }

  public getDanishDate (dateString: string) : string {
    let tempString = CommonFunctions.convertFromUTCtoLocalDateTime(dateString, 0)
    const strPos = tempString.indexOf(' ')
    tempString = (tempString.substring(0, strPos) + 'T' + tempString.substring(strPos + 1)).substring(0, 19)

    return CommonFunctions.toDanishDateString(tempString)
  }

  public getTime (dateString: string) : string {
    let tempString = CommonFunctions.convertFromUTCtoLocalDateTime(dateString, 0)
    const strPos = tempString.indexOf(' ')
    tempString = (tempString.substring(0, strPos) + 'T' + tempString.substring(strPos + 1)).substring(0, 19)

    return CommonFunctions.getHoursAndMinutes(tempString, false)
  }

  public setTeam (settingAwayTeam: boolean) : void {
    this.selectTeamModal = true
    this.settingAwayTeam = settingAwayTeam
  }

  public async finishSettingTeam () : Promise<void> {
    this.selectTeamModal = false
    this.checkUserPrivilege()
    await this.retrieveMatch()
  }

  public resetTeam () : void {
    if (!this.spectatorMode && !this.matchPlayed) {
      // Attempt to reset the players of each team.
      const matchId = Number(this.matchExecution.id).toString()

      Tournaments.TournamentMatchSettlementDataService.get(matchId)
        .then((response) => {
          const tempMatchSettlement = response.data as SysTournamentMatchSettlement
          console.log('[resetTeam()] tempMatchSettlement = ' + JSON.stringify(tempMatchSettlement))

          if (tempMatchSettlement.kampafvikling_ekstras_id !== null) {
            const updateMatchData = {
              kampafvikling_ekstras_id: null
            }

            console.log('[resetTeam()] Removing all references the extra tournament match settlement elements for the match with Id: ' + matchId)

            Tournaments.TournamentMatchSettlementDataService.update(matchId, updateMatchData)
              .then((response2) => {
                this.homeTeamArray = []
                this.awayTeamArray = []
                this.finishedSettingAwayTeam = false
                this.finishedSettingHomeTeam = false
                this.matchInitRefreshDelayFlag = false // Indicate that a special delay and refresh should be used.

                for (let arrIndex = 0; arrIndex < tempMatchSettlement.kampafvikling_ekstras_id.length; arrIndex++) {
                  console.log('[resetTeam()] Deleting the extra tournament match settlement element with Id: ' + Number(tempMatchSettlement.kampafvikling_ekstras_id[arrIndex].id).toString())

                  if (tempMatchSettlement.kampafvikling_ekstras_id[arrIndex].id !== null && Number(tempMatchSettlement.kampafvikling_ekstras_id[arrIndex].id) >= 1) {
                    Tournaments.TournamentMatchSettlementExtDataService.delete(Number(tempMatchSettlement.kampafvikling_ekstras_id[arrIndex].id).toString())
                      .then((response3) => {
                        console.log('[resetTeam()] Delete response: ' + JSON.stringify(response3))
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                      .finally(() => {
                        if (arrIndex >= (tempMatchSettlement.kampafvikling_ekstras_id.length - 1)) {
                          setTimeout(() => { this.reloadPage() }, 10000)
                        }
                      })

                    // if (arrIndex >= (tempMatchSettlement.kampafvikling_ekstras_id.length - 1)) {
                    //   setTimeout(() => { this.reloadPage() }, 10000)
                    // }
                  }
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  public calcScore (teamArray: { matchesWon: number }[]) : number {
    let retVal = 0

    for (const item of teamArray) {
      retVal += item.matchesWon
    }

    return retVal
  }

  public finishMatchCheckupValuesOfFields () {
    let matchSetRowsFilledOutArr = [0, 0]
    let matchIndex = 0
    let juniorFlag = false
    this.matchSetCheckupMsg = []
    this.ignoreErrorsInMatchSetsFlag = false

    if (this.matchOrder.length > 0) {
      const matchData = this.getMatchData()

      if (matchData !== null) {
        for (const matchDataItem of matchData) {
          const matchSetData = this.getSetResultsData((matchIndex + 1))
          let matchSetIndex = 0
          let lastMatchNumberProcessed = 0
          matchSetRowsFilledOutArr = [0, 0]
          // console.log('[finishMatchCheckupValuesOfFields()] matchSetData[' + matchIndex.toString() + '] = ' + JSON.stringify(matchSetData))

          for (const matchSetItem of matchSetData) {
            const currentMatchSetFieldLocationText = 'kamp-nr. ' + matchSetItem.matchIndexNumber.toString() + ' ved sæt-rækken ' + (Math.floor(matchSetIndex / 2) + 1).toString() + ' for ' + (matchSetItem.matchHomeOrAwayTeam ? 'hjemmeholdet' : 'udeholdet')
            // console.log('[finishMatchCheckupValuesOfFields()] matchSetItem[' + matchSetIndex.toString() + '] = ' + JSON.stringify(matchSetItem))

            if (Number(matchSetItem.matchSetRemainScore) !== 0 || Number(matchSetItem.matchSetDart) !== 0 || Number(matchSetItem.matchSetClosed) !== 0) {
              if (Number(matchSetItem.matchSetRemainScore) !== 0 && Number(matchSetItem.matchSetDart) !== 0 && Number(matchSetItem.matchSetClosed) !== 0) {
                matchSetRowsFilledOutArr[(matchSetItem.matchHomeOrAwayTeam ? 1 : 0)]++
              }

              // Check for fields with a invalid value.
              if (Number(matchSetItem.matchSetRemainScore) < 0 || Number(matchSetItem.matchSetRemainScore) === 1 || Number(matchSetItem.matchSetRemainScore) > 501) {
                this.matchSetCheckupMsg.push('Værdien for restscore-feltet er ugyldigt i ' + currentMatchSetFieldLocationText + '.')
              }
              if (Number(matchSetItem.matchSetDart) < 0 || (Number(matchSetItem.matchSetDart) > 0 && Number(matchSetItem.matchSetDart) < 9) || Number(matchSetItem.matchSetDart) > 180) {
                this.matchSetCheckupMsg.push('Værdien for dart-feltet er ugyldigt i ' + currentMatchSetFieldLocationText + '.')
              }
              if (Number(matchSetItem.matchSetClosed) < 0 || Number(matchSetItem.matchSetClosed) === 1 || Number(matchSetItem.matchSetClosed) > 170) {
                this.matchSetCheckupMsg.push('Værdien for lukket-feltet er ugyldigt i ' + currentMatchSetFieldLocationText + '.')
              }

              // Check for fields with the value of zero.
              if (Number(matchSetItem.matchSetRemainScore) === 0 && (Number(matchSetItem.matchSetDart) !== 0 || Number(matchSetItem.matchSetClosed) !== 0)) {
                this.matchSetCheckupMsg.push('Værdien for restscore-feltet er lig nul i ' + currentMatchSetFieldLocationText + '.')
              }
              if (Number(matchSetItem.matchSetDart) === 0 && (Number(matchSetItem.matchSetRemainScore) !== 0 || Number(matchSetItem.matchSetClosed) !== 0)) {
                this.matchSetCheckupMsg.push('Værdien for dart-feltet er lig nul i ' + currentMatchSetFieldLocationText + '.')
              }
              if (Number(matchSetItem.matchSetClosed) === 0 && (Number(matchSetItem.matchSetDart) !== 0 || Number(matchSetItem.matchSetRemainScore) !== 0)) {
                this.matchSetCheckupMsg.push('Værdien for lukket-feltet er lig nul i ' + currentMatchSetFieldLocationText + '.')
              }
            }

            // Catch situation where none of the teams have filled out all match set necessary to win a match.
            // if ((matchSetIndex + 1) === this.numberOfSets && matchSetRowsFilledOutArr[0] < this.numberOfSets && matchSetRowsFilledOutArr[1] < this.numberOfSets) {
            //   this.matchSetCheckupMsg.push('Der er ingen vinder af kampen med kamp-nr. ' + matchSetItem.matchIndexNumber.toString() + ' .')
            // }

            lastMatchNumberProcessed = matchSetItem.matchIndexNumber
            matchSetIndex++
          }

          // Set a flag variable if this current match is in a junior match row.
          if (this.match !== undefined && this.match !== null && this.match.raekke_id !== undefined && this.match.raekke_id.raekke_navn !== undefined && this.match.raekke_id.raekke_navn !== null && this.match.raekke_id.raekke_navn.toLowerCase().includes('junior')) {
            juniorFlag = true
          }

          // Catch situation where none of the teams have filled out all match set necessary to win a match. Also skip this check, when there is only one player per team and only one match.
          // Check first if the match row is for junior, and if it is then skip the messages if the number of match sets is equal (because in that situation it can be permissible).
          if (juniorFlag === false || this.numberOfSets !== (Math.floor(this.numberOfSets / 2) * 2)) {
            if (matchSetRowsFilledOutArr[0] < this.numberOfSets && matchSetRowsFilledOutArr[1] < this.numberOfSets && this.numberOfMatches >= 2 && this.matchRules.minPlayers >= 2) {
              this.matchSetCheckupMsg.push('Der er ingen vinder af kampen med kamp-nr. ' + lastMatchNumberProcessed.toString() + ' .')
            }

            // Catch situation where both teams have filled out the same amount of match sets.
            if (matchSetRowsFilledOutArr[0] > 0 && matchSetRowsFilledOutArr[1] > 0 && matchSetRowsFilledOutArr[0] === matchSetRowsFilledOutArr[1]) {
              if (matchSetRowsFilledOutArr[0] < this.numberOfSets && matchSetRowsFilledOutArr[1] < this.numberOfSets) {
                this.matchSetCheckupMsg.push('Begge hold har samme antal kampsæt helt eller delvis udfyldt i kampen med kamp-nr. ' + lastMatchNumberProcessed.toString() + ' .')
              } else {
                this.matchSetCheckupMsg.push('Begge hold er vinder af samme kamp i kampen med kamp-nr. ' + lastMatchNumberProcessed.toString() + ' .')
              }
            }
          }

          matchIndex++
        }
      }
    }

    this.finishMatchModal = true
  }

  public async finishMatch () : Promise<void> {
    if (!this.havePermission()) {
      this.$Message.danger({ text: 'Fejl: Du har ikke nok rettigheder til at afslutte kampen' })
    }

    let homeScore = this.cachedTotalResultHome
    let guestScore = this.cachedTotalResultGuest
    let maxScoreValue = this.numberOfMatches
    let members: SysMember[] = []

    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      this.$Message.danger({ text: 'Fejl: Noget gik galt' })
      return
    }

    try {
      members = (await MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)).data
    } catch (err) {
      console.log(err)
    }

    if (members.length !== 1) {
      this.$Message.danger({ text: 'Fejl: Noget gik galt' })
      return
    }

    if (members[0].user_id.usrgroup !== 2 && Number(members[0].klubber_id.id) !== this.homeTeamClubId && Number(members[0].klubber_id.id) !== this.awayTeamClubId) {
      this.$Message.danger({ text: 'Fejl Du tilhøre ikke den rigtige klub' })
      return
    }

    // let homeScore = 0
    // let guestScore = 0
    // for (const item of this.homePlayersInfoData) {
    //   homeScore += item.matchesWon
    // }
    // for (const item of this.awayPlayersInfoData) {
    //   guestScore += item.matchesWon
    // }

    // Handle max score value in the case of Junior match rows.
    if (this.match !== undefined && this.match !== null && this.match.raekke_id !== undefined && this.match.raekke_id.raekke_navn !== undefined && this.match.raekke_id.raekke_navn !== null && this.match.raekke_id.raekke_navn.toLowerCase().includes('junior')) {
      maxScoreValue = (this.match.raekke_id.raekke_antalsaet === null ? 1 : this.match.raekke_id.raekke_antalsaet)
    }

    // Check to see if the match have been played to completion.
    if (((maxScoreValue >= 2 && this.matchRules.minPlayers >= 2 && (homeScore + guestScore) !== maxScoreValue) || (maxScoreValue === 1 && this.matchRules.minPlayers === 1 && (homeScore + guestScore) !== this.numberOfSets)) && (this.protestValue === 'Ingen' || this.protestValue === 'Protest fra hjemmeholdet' || this.protestValue === 'Protest fra udeholdet')) {
      this.$Notification.warning({ title: 'Kampen er ikke spillet færdig' })
      return
    }

    // TESTING ONLY
    // if ((homeScore + guestScore) === maxScoreValue) {
    //   return
    // }

    // // Uses only username for testing, include password in final implementation
    // if (this.matchFinishUsername.length === 0) {
    //   this.$Notification.warning({ title: 'Brugernavn mangler' })
    //   return
    // }
    const matchDatePromise = BaseFunctions.getDatetimeFromServer()
    // const usernamePromise = MembersDataService.asyncGetAll('', null, `user_id.username=${this.matchFinishUsername}`)
    await Promise.all([matchDatePromise])
      .then(async (response) => {
        const matchDate = new Date(response[0].data).toISOString().split('T')[0]
        // const tempMember = response[1].data as SysMember[]
        // console.log(tempMember)
        if (members === undefined) {
          this.$Notification({ title: 'Intet medlem med det brugernavn' })
          return
        }
        // Remember Remove Ret123
        if (members[0].user_id.usrgroup === defaultSuperAdminGroupId || ((members[0].user_id.usrgroup === defaultClubCaptainGroupId || members[0].user_id.usrgroup === defaultClubManagerGroupId) && (Number(members[0].klubber_id.id) === this.homeTeamClubId || Number(members[0].klubber_id.id) === this.awayTeamClubId))) {
          // Check that the member is either a superUser, or Captaion or ClubManager from either the home or guest team
          let protestEnumValue: tournamentMatchScheduleTeamStatusFriendlyType | null = null
          switch (this.protestValue) {
            case 'Protest fra hjemmeholdet':
              protestEnumValue = tournamentMatchScheduleTeamStatusFriendlyType.Protesthjem
              break
            case 'Protest fra udeholdet':
              protestEnumValue = tournamentMatchScheduleTeamStatusFriendlyType.Protestud
              break
            case 'Afbud fra hjemmeholdet':
              protestEnumValue = tournamentMatchScheduleTeamStatusFriendlyType.Afbudhjem
              guestScore = maxScoreValue
              break
            case 'Afbud fra udeholdet':
              protestEnumValue = tournamentMatchScheduleTeamStatusFriendlyType.Afbudud
              homeScore = maxScoreValue
              break
            case 'Udeblivelse af hjemmeholdet':
              protestEnumValue = tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem
              guestScore = maxScoreValue
              break
            case 'Udeblivelse af udeholdet':
              protestEnumValue = tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud
              homeScore = maxScoreValue
          }
          if (this.protestValue !== 'Ingen') {
            const matchScheduleResponse = await Tournaments.TournamentMatchSchedulesDataService.get(Number(this.match.id).toString())
            const tempMatchSchedule = matchScheduleResponse.data as SysTournamentMatchSchedule

            const updateMatchSchedule = {
              kampprogram_afb_udb: protestEnumValue,
              kampprogram_kommentar: (tempMatchSchedule.kampprogram_kommentar === null || tempMatchSchedule.kampprogram_kommentar.length === 0 ? '' : tempMatchSchedule.kampprogram_kommentar + ', ') + this.protestComment
            }
            // Updates the match schedule with the value of the protest setting.
            Tournaments.TournamentMatchSchedulesDataService.update(Number(this.match.id).toString(), updateMatchSchedule)
              .then((response) => {
                console.log('Protest received: ' + response.statusText)
                this.match = response.data as SysTournamentMatchSchedule
              })
              .catch((err) => {
                console.log(err)
              })
          }

          if (protestEnumValue === tournamentMatchScheduleTeamStatusFriendlyType.Afbudhjem || protestEnumValue === tournamentMatchScheduleTeamStatusFriendlyType.Afbudud || protestEnumValue === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelsehjem || protestEnumValue === tournamentMatchScheduleTeamStatusFriendlyType.Udeblivelseud) {
            // Updates the results if either team, did not appear, or had cancled.
            const updateMatchExecution = {
              kampafvikling_resultat_hjem: homeScore,
              kampafvikling_resultat_ude: guestScore
            }

            Tournaments.TournamentMatchSettlementDataService.update(Number(this.matchExecution.id).toString(), updateMatchExecution)
              .then((response) => {
                console.log('Match results updated: ' + response.statusText)
                this.matchExecution = response.data as SysTournamentMatchSettlement
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            // Just updates the match with its final results
            const updateMatchExecution = {
              kampafvikling_resultat_hjem: homeScore,
              kampafvikling_resultat_ude: guestScore
            }

            Tournaments.TournamentMatchSettlementDataService.update(Number(this.matchExecution.id).toString(), updateMatchExecution)
              .then((response) => {
                console.log('Match results updated: ' + response.statusText)
                this.matchExecution = response.data as SysTournamentMatchSettlement
              })
              .catch((err) => {
                console.log(err)
              })
          }

          // Loop through both team players, and create a match history entry i db
          if (this.protestValue === 'Ingen' || protestEnumValue === tournamentMatchScheduleTeamStatusFriendlyType.Protesthjem || protestEnumValue === tournamentMatchScheduleTeamStatusFriendlyType.Protestud) {
            console.log(this.homePlayersInfoData)
            for (const homePlayer of this.homePlayersInfoData) {
              if (!homePlayer.hide && homePlayer.playerSelected) {
                const createPlayerMatchHistory = {
                  medlem_id: homePlayer.memberId,
                  hold_id: this.match.kampprogram_hjemmehold.id,
                  raekke_id: Number(this.match.raekke_id.id),
                  antal_kampe: homePlayer.singleMatchesPlayed,
                  antal_v_kampe: homePlayer.matchesWon,
                  antal_v_saet: homePlayer.matchSetsWon,
                  sum_v_dart: homePlayer.setWonScore,
                  hoejste_lukker: homePlayer.matchSetHighestClosing,
                  hurtigste_saet: homePlayer.matchSetFastestDarting,
                  antal_180: homePlayer.match180,
                  kampafvikling_id: this.matchExecution.id === null ? 0 : this.matchExecution.id
                }

                MatchHistoryDataService.create(createPlayerMatchHistory)
                  .then((response) => {
                    console.log('History Entry Created: ' + response.statusText)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }
            }

            for (const awayPlayer of this.awayPlayersInfoData) {
              if (!awayPlayer.hide && awayPlayer.playerSelected) {
                const createPlayerMatchHistory = {
                  medlem_id: awayPlayer.memberId,
                  hold_id: this.match.kampprogram_udehold.id,
                  raekke_id: Number(this.match.raekke_id.id),
                  antal_kampe: awayPlayer.singleMatchesPlayed,
                  antal_v_kampe: awayPlayer.matchesWon,
                  antal_v_saet: awayPlayer.matchSetsWon,
                  sum_v_dart: awayPlayer.setWonScore,
                  hoejste_lukker: awayPlayer.matchSetHighestClosing,
                  hurtigste_saet: awayPlayer.matchSetFastestDarting,
                  antal_180: awayPlayer.match180,
                  kampafvikling_id: this.matchExecution.id === null ? 0 : this.matchExecution.id
                }

                MatchHistoryDataService.create(createPlayerMatchHistory)
                  .then((response) => {
                    console.log('History Entry Created: ' + response.statusText)
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              }
            }
          }

          if (this.match.kampgodkendt_id === null) {
            const createFinishMatch = {
              kampprogram_id: Number(this.match.id).toString(),
              kampgodkendt_godkendt: true
            }

            Tournaments.TournamentMatchFinalApproval.create(createFinishMatch)
              .then((response) => {
                console.log('Match finished: ' + response.statusText)
                Tournaments.TournamentMatchSchedulesDataService.getAll('', null, `id=${this.matchId}`)
                  .then((response) => {
                    this.match = response.data[0] as SysTournamentMatchSchedule
                    this.finishMatchModal = false
                    this.$Notification.success({ title: 'Kampen er godkendt og afsluttet' })
                    this.matchPlayed = true
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            const updateFinishMatch = {
              kampgodkendt_godkendt: true
            }
            Tournaments.TournamentMatchFinalApproval.update(Number(this.match.kampgodkendt_id.id).toString(), updateFinishMatch)
              .then((response) => {
                console.log('Match finished: ' + response.statusText)
                this.finishMatchModal = false
                Tournaments.TournamentMatchSchedulesDataService.getAll('', null, `id=${this.matchId}`)
                  .then((response) => {
                    this.match = response.data[0] as SysTournamentMatchSchedule
                    this.finishMatchModal = false
                    this.$Notification.success({ title: 'Kampen er godkendt og afsluttet' })
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              })
              .catch((err) => {
                console.log(err)
              })
          }
        }
      })
      .catch((err) => {
        this.$Notification({ title: 'Der er sket en fejl, prøv igen' })
        console.log(err)
      })
  }

  public downloadPdf () : void {
    this.matchCardOutputMode = this.matchCardPrintoutValue === '0' ? 0 : 1
    this.matchCardPrintoutOptionsModal = false

    if (this.matchCardOutputMode === 0) {
      // console.log('[downloadPdf()] Starting empty match printout.')
      this.startEmptyMatch()
    }

    const pdfApi = this.$refs.matchCard as any
    console.log(pdfApi)
    pdfApi.generatePdf()
  }

  public onHtml2PDFProgress (event: any) : void {
    console.log('[onHtml2PDFProgress()] Progress = ' + JSON.stringify(event))

    if (this.matchCardOutputMode === 0 && Number(event) === 100) {
      // Reset some stuff.
      console.log('[onHtml2PDFProgress()] Resetting some stuff.')
      this.emptyMatchesCreated = 0
      this.emptySetsCreated = 0
      this.homePlayersEmptyInfoData = []
      this.awayPlayersEmptyInfoData = []
      // this.matchOrder = []
      this.emptySetResults = []
      this.dummyMatchExecution = {} as SysTournamentMatchSettlement
    }
  }

  async checkUserPrivilege () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.usrgroup !== undefined && userDataObject.usrgroup !== null) {
        if (Number(userDataObject.usrgroup.id) === 2) {
          this.spectatorMode = false
        } else if (Number(userDataObject.usrgroup.id) === 3 || Number(userDataObject.usrgroup.id) === 4) {
          try {
            const members = (await MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)).data as SysMember[]

            if (members.length !== 1) {
              this.spectatorMode = true
              return
            }

            try {
              const matchResponse = await Tournaments.TournamentMatchSchedulesDataService.asyncGetAll('', null, `id=${this.matchId}`)
              this.match = matchResponse.data[0] as SysTournamentMatchSchedule
              // console.log('MATCH: ' + JSON.stringify(this.match))

              if (this.match !== undefined) {
                if (members[0].klubber_id !== null && (Number(members[0].klubber_id.id) === Number(this.match.kampprogram_hjemmehold.klubber_id) || Number(members[0].klubber_id.id) === Number(this.match.kampprogram_udehold.klubber_id))) {
                  console.log('CHECK')
                  this.spectatorMode = false
                  return
                } else if (members[0].user_id.id === (this as any).match.homeTeamCaptain || members[0].user_id.id === (this as any).match.awayTeamCaptain) {
                  console.log('CAPTAIN_CONDITIONS...')
                  this.spectatorMode = false
                  return
                } else {
                  this.spectatorMode = true
                  return
                }
              } else {
                this.spectatorMode = true
                return
              }
            } catch (err) {
              console.log(err)
            }

            // if (members[0].klubber_id !== null && (Number(members[0].klubber_id.id) === Number(this.match.kampprogram_hjemmehold.klubber_id) || Number(members[0].klubber_id.id) === Number(this.match.kampprogram_udehold.klubber_id))) {
            //   console.log('CHECK')
            //   this.spectatorMode = false
            //   return
            // }
          } catch (err) {
            console.log(err)
            this.spectatorMode = true
          }
        } else {
          this.spectatorMode = true
        }
        // this.spectatorMode = false
      } else {
        this.spectatorMode = true
      }
    } else {
      this.spectatorMode = true
    }
  }

  public checkForLiveMatch (matchSubId: any) : boolean {
    const isLive = (this as any).liveMatches.some((match: any) => match?.matchSetId === matchSubId)
    return isLive
  }

  public showLiveMatchPage (matchSubId: any) : void {
    if (this.liveMatches.length > 0) {
      (this as any).liveMatches.map((match: any) => {
        if (match.matchSetId === matchSubId) {
          this.$router.push(`/turnering/turneringslivekampe/${match.id}`)
        }
      })
    }
  }

  public async getLiveMatch () : Promise<void> {
    await LiveMatch.LiveMatchDataService.getLiveMatchForMatchPage(this.match.id)
      .then((response) => {
        this.liveMatches = response.data
      })
      .catch((err) => {
        console.log('ERROR...', err)
      })
  }

  public checkForCompletedMatch (matchIndex: any) : boolean {
    let isComplete = false
    for (const item of this.setResults) {
      if (item.matchIndexNumber === matchIndex) {
        if (item.matchSetDart !== 0 && item.matchSetRemainScore !== 0 && item.matchSetClosed !== 0) {
          isComplete = true
          break
        }
      }
    }
    return isComplete
  }

  public showTheScore (data: any) : void {
    this.$router.push({ name: 'CompletedMatchScore', params: { matchSetId: data } })
  }

  async created () : Promise<void> {
    // const channel = ably.channels.get('ScoreBoardPage')
    // channel.subscribe('refreshMatchPage', async (data: any) => {
    //   const loggedinUser = JSON.parse((localStorage.getItem('user') as any))
    //   if (data.data.matchId === this.matchId && data.data.userId !== loggedinUser.id) {
    //     // this.retrieveMatch()
    //     this.setResults[data.data.setIndex] = data.data.setResultsData
    //     this.updateMatchResults()
    //   }
    // })

    // const channelName = ably.channels.get('dart-ddu')
    // channelName.subscribe('liveMatchList', async (data: any) => {
    //   await this.getLiveMatch()
    // })
  }

  // Remove “afbud” options if difference is less than 24 hours
  get protestOptionsList () {
    const currentDate: any = new Date()
    const matchDate: any = this.match?.kampprogram_nydato ? new Date(this.match.kampprogram_nydato) : new Date(this.match.kampprogram_dato)
    const hoursDiff = (matchDate - currentDate) / 36e5
    let optionList = this.protestOptions
    if (hoursDiff <= 24) {
      optionList = ['Ingen', 'Protest fra hjemmeholdet', 'Protest fra udeholdet', 'Udeblivelse af hjemmeholdet', 'Udeblivelse af udeholdet']
    }
    return optionList
  }

  async mounted () : Promise<void> {
    this.checkUserPrivilege()
    this.matchInitRefreshDelayFlag = false
    await this.retrieveMatch()
    await this.getLiveMatch()
  }

  // async beforeDestroy () : Promise<void> {
  //   const channelName1 = ably.channels.get('dart-ddu')
  //   channelName1.unsubscribe()
  //   const channelName2 = ably.channels.get('ScoreBoardPage')
  //   channelName2.unsubscribe()
  //   ably.close()
  // }
}
