
import { Options, Vue } from 'vue-class-component'
import statistics from '@/components/Frontend/Statistics/index.vue'

@Options({
  components: {
    statistics
  }
})

export default class Statistics extends Vue {}
