import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Tournaments extends APIBaseFunctions {
  static TournamentRowsDataService = class {
    private static cachedTournamentRowsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/raekkes?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/raekkes?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/raekkes?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/raekkes?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/raekkes/${id}`)
    }

    static getCount () {
      return http.get('/raekkes/count')
    }

    static create (data: any) {
      return http.post('/raekkes', data)
    }

    static update (id: string, data: any) {
      return http.put(`/raekkes/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/raekkes/${id}`)
    }

    static deleteAll () {
      return http.delete('/raekkes')
    }

    static findByTournamentRowStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/raekkes?raekke_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      let priceSearchParameter = ''
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentRowsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        priceSearchParameter = `&_where[_or][2][turneringskategori_id.turneringskategori_pris_contains]=${searchTerm.trim()}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/raekkes?_where[_or][0][raekke_navn_contains]=${searchTerm.trim()}&_where[_or][1][turneringskategori_id.turneringskategori_navn_contains]=${searchTerm.trim()}${priceSearchParameter}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/raekkes?raekke_status=${status}&_where[_or][0][raekke_navn_contains]=${searchTerm.trim()}&_where[_or][1][turneringskategori_id.turneringskategori_navn_contains]=${searchTerm.trim()}${priceSearchParameter}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static TournamentCategoriesDataService = class {
    private static cachedTournamentCategoriesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentCategoriesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/turneringskategoris?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/turneringskategoris?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentCategoriesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/turneringskategoris?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/turneringskategoris?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/turneringskategoris/${id}`)
    }

    static getCount () {
      return http.get('/turneringskategoris/count')
    }

    static create (data: any) {
      return http.post('/turneringskategoris', data)
    }

    static update (id: string, data: any) {
      return http.put(`/turneringskategoris/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/turneringskategoris/${id}`)
    }

    static deleteAll () {
      return http.delete('/turneringskategoris')
    }

    static findByTournamentCategoryStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentCategoriesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/turneringskategoris?turneringskategori_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      let priceSearchParameter = ''
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentCategoriesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        priceSearchParameter = `&_where[_or][1][turneringskategori_pris_contains]=${searchTerm.trim()}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/turneringskategoris?_where[_or][0][turneringskategori_navn_contains]=${searchTerm.trim()}${priceSearchParameter}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/turneringskategoris?turneringskategori_status=${status}&_where[_or][0][turneringskategori_navn_contains]=${searchTerm.trim()}${priceSearchParameter}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static TournamentMatchSchedulesDataService = class {
    private static cachedTournamentMatchSchedulesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSchedulesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampprograms?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampprograms?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSchedulesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampprograms?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampprograms?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kampprograms/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/kampprograms/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/kampprograms', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kampprograms/${id}`, data)
    }

    static async asyncUpdate (id: string, data: any) {
      return http.put(`/kampprograms/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kampprograms/${id}`)
    }

    static async asyncDelete (id: string) {
      return http.delete(`/kampprograms/${id}`)
    }

    static deleteAll () {
      return http.delete('/kampprograms')
    }

    static findBySearchTerm (searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSchedulesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      const retVal = http.get(`/kampprograms?_where[_or][0][kampprogram_kampnr_contains]=${searchTerm.trim()}&_where[_or][1][kampprogram_hjemmehold.hold_holdnavn_contains]=${searchTerm.trim()}&_where[_or][2][kampprogram_udehold.hold_holdnavn_contains]=${searchTerm.trim()}&_where[_or][3][raekke_id.raekke_navn_contains]=${searchTerm.trim()}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))

      return retVal
    }
  }

  static TournamentMatchSettlementDataService = class {
    private static cachedTournamentMatchSettlementDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSettlementDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampafviklings?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampafviklings?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSettlementDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampafviklings?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampafviklings?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kampafviklings/${id}`)
    }

    static getCount () {
      return http.get('/kampafviklings/count')
    }

    static create (data: any) {
      return http.post('/kampafviklings', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kampafviklings/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kampafviklings/${id}`)
    }

    static deleteAll () {
      return http.delete('/kampafviklings')
    }
  }

  static TournamentMatchSettlementExtDataService = class {
    private static cachedTournamentMatchSettlementExtDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSettlementExtDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampafvikling-ekstras?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampafvikling-ekstras?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSettlementExtDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampafvikling-ekstras?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampafvikling-ekstras?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kampafvikling-ekstras/${id}`)
    }

    static getCount () {
      return http.get('/kampafvikling-ekstras/count')
    }

    static create (data: any) {
      return http.post('/kampafvikling-ekstras', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kampafvikling-ekstras/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kampafvikling-ekstras/${id}`)
    }

    static deleteAll () {
      return http.delete('/kampafvikling-ekstras')
    }
  }

  static TournamentMatchDataService = class {
    private static cachedTournamentMatchDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kamps?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kamps?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kamps/${id}`)
    }

    static getCount () {
      return http.get('/kamps/count')
    }

    static create (data: any) {
      return http.post('/kamps', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kamps/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kamps/${id}`)
    }

    static deleteAll () {
      return http.delete('/kamps')
    }
  }

  static TournamentMatchSetDataService = class {
    private static cachedTournamentMatchSetDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchSetDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kamp-saets?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kamp-saets?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kamp-saets/${id}`)
    }

    static getCount () {
      return http.get('/kamp-saets/count')
    }

    static create (data: any) {
      console.log('[TournamentMatchSetDataService.create] data = ' + JSON.stringify(data))
      return http.post('/kamp-saets', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kamp-saets/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kamp-saets/${id}`)
    }

    static deleteAll () {
      return http.delete('/kamp-saets')
    }
  }

  static TournamentMatch180DataService = class {
    private static cachedTournamentMatch180DataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatch180DataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kamp-180-s?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kamp-180-s?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kamp-180-s/${id}`)
    }

    static getCount () {
      return http.get('/kamp-180-s/count')
    }

    static create (data: any) {
      return http.post('/kamp-180-s', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kamp-180-s/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kamp-180-s/${id}`)
    }

    static deleteAll () {
      return http.delete('/kamp-180-s')
    }
  }

  static TournamentRoundsDataService = class {
    private static cachedTournamentRoundsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentRoundsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/turneringsrunders?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/turneringsrunders?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/turneringsrunders/${id}`)
    }

    static getCount () {
      return http.get('/turneringsrunders/count')
    }

    static create (data: any) {
      return http.post('/turneringsrunders', data)
    }

    static update (id: string, data: any) {
      return http.put(`/turneringsrunders/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/turneringsrunders/${id}`)
    }

    static deleteAll () {
      return http.delete('/turneringsrunders')
    }
  }

  static TournamentRulesDataService = class {
    private static cachedTournamentRulesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentRulesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/turneringsreglers?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/turneringsreglers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/turneringsreglers/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/turneringsreglers/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/turneringsreglers', data)
    }

    static update (id: string, data: any) {
      return http.put(`/turneringsreglers/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/turneringsreglers/${id}`)
    }

    static deleteAll () {
      return http.delete('/turneringsreglers')
    }
  }

  static TournamentMatchFinalApproval = class {
    private static cachedTournamentMatchFinishedSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchFinishedSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/kampgodkendts?_sort${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/kampgodkendts?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/kampgodkendts/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/kampgodkendts/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/kampgodkendts', data)
    }

    static update (id: string, data: any) {
      return http.put(`/kampgodkendts/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/kampgodkendts/${id}`)
    }

    static deleteAll () {
      return http.delete('/kampgodkendts')
    }
  }

  static TournamentMatchesTeamRankingsDataService = class {
    private static cachedTournamentMatchesTeamRankingsSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchesTeamRankingsSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/stillingers?_sort${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/stillingers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static async asyncGetAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null, extraParameter = '') {
      const currentDataSliceObj = Tournaments.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedTournamentMatchesTeamRankingsSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/stillingers?_sort${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/stillingers?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/stillingers/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/stillingers/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/stillingers', data)
    }

    static async asyncCreate (data: any) {
      return http.post('/stillingers', data)
    }

    static update (id: string, data: any) {
      return http.put(`/stillingers/${id}`, data)
    }
  }
}

export default new Tournaments()
